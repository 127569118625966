import React from 'react';
import styled from 'styled-components';

import SuccessIcon from '@/assets/svg/success-rate-car.svg';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import { ButtonStyles } from '@/components/CarCard/components/common.styles';
import { StringField } from '@/typings/model';

interface Props {
  title: StringField;
  text: StringField;
}

function RateCarSuccessPage({ title, text }: Props) {
  return (
    <Container>
      <Content>
        <ContainerIcon>
          <SuccessIcon />
        </ContainerIcon>
        <Title>{title}</Title>
        <Information>{text}</Information>
        <StyledLink to="/cars/amp">Выбрать другой автомобиль</StyledLink>
      </Content>
    </Container>
  );
}

export default RateCarSuccessPage;

const Container = styled.div`
  max-width: 1280px;
  padding: 0 145px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const Content = styled.div`
  padding: 54px 20px 76px;
  text-align: center;
  margin: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.blackShadow100};

  @media (max-width: 768px) {
    margin: 25px 0 60px;
  }
`;

const ContainerIcon = styled.div`
  width: 96px;
  height: 96px;
`;

const Title = styled.h2`
  text-transform: uppercase;
  font-size: 26px;
  color: ${colors.main};
  margin-top: 33px;
`;

const Information = styled.span`
  display: block;
  font-size: 20px;
  line-height: 32px;
  margin-top: 20px;
  color: ${(props) => props.theme.blue100};
`;

const StyledLink = styled(Link)`
  ${ButtonStyles};
  width: 384px;
  padding: 16px 0;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 8px;
  font-size: 16px;
  margin: 34px auto 0;
  display: block;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
  } ;
`;
