import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import {
  ContentContainer,
  Title,
} from '@/modules/ElectrocarsLanding/common.styles';
import { media } from '@/utils/mixin';
import { useElectrocarsLandingData } from '@/modules/ElectrocarsLanding/hooks';
import { colors } from '@/constants/theme';

import BenefitCard from './components/BenefitCard';

function Benefits() {
  const { benefitsTitle, benefitsItems } = useElectrocarsLandingData();
  const isMobile = useMedia('(max-width: 767px)');

  if (!benefitsItems || benefitsItems.length === 0) {
    return null;
  }

  return (
    <Component id="benefits">
      <ContentContainer>
        <Title>{benefitsTitle}</Title>
        <List>
          {benefitsItems.map(({ title, list, image }, index) => (
            <BenefitCard
              key={index}
              title={title ?? ''}
              list={list?.split('\n') ?? []}
              image={image?.url ?? ''}
              isMobile={isMobile}
            />
          ))}
        </List>
      </ContentContainer>
    </Component>
  );
}

export default Benefits;

const Component = styled.section`
  padding: 60px 0 30px;
  background: ${colors.purple};

  ${media.tablet(css`
    padding: 40px 0 20px;
  `)}
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 55px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
    margin-top: 30px;
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  `)}
`;
