import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import {
  convertRequestErrorToMap,
  useMedia,
  validators,
} from '@tager/web-core';

import SuccessForm from '@/components/Forms/SuccessForm';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import TextInput from '@/components/TextInput';
import { CheckboxAgreement } from '@/components/Checkbox';
import { ButtonPrimary } from '@/components/CarCard/components/Button';
import Select from '@/components/Select';
import { useServiceContext } from '@/modules/Services/Services.hooks';
import Loader from '@/components/Loader';
import { OptionType } from '@/typings/common';
import { googleEvent } from '@/utils/events';
import { submitServiceKo } from '@/services/leads/leads-service';

interface Props {
  title: string;
  description: string;
  options: OptionType[];
  event?: string;
}

interface IConsultation {
  name: string;
  phone: string;
  service: string;
}

const requiredMessage = 'required';

function ServiceConsultationForm({
  title,
  description,
  options,
  event,
}: Props) {
  const isMobile = useMedia('(max-width: 768px)');
  const [isRequestAccept, setIsRequestAccept] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);
  const [loading, setLoading] = useState(false);
  const { selectedValue, setSelectedValue } = useServiceContext();

  function handleValidateConsultationForm(
    values: IConsultation
  ): FormikErrors<IConsultation> {
    const errors: FormikErrors<IConsultation> = {};
    if (validators.required(values.name)) {
      errors.name = requiredMessage;
    }

    if (!values.phone) {
      errors.phone = requiredMessage;
    }

    return errors;
  }

  async function onSubmit(
    values: IConsultation,
    formikHelpers: FormikHelpers<IConsultation>
  ): Promise<void> {
    setIsRequestAccept(false);
    setLoading(true);

    const newValues = {
      ...values,
      service: selectedValue.label,
    };

    try {
      await submitServiceKo(newValues).then(() => {
        if (event) {
          googleEvent(event);
        }
      });
      setIsRequestAccept(true);
    } catch (error: any) {
      console.log(error);
      const errorMap = convertRequestErrorToMap(
        error
      ) as FormikErrors<IConsultation>;
      formikHelpers.setErrors(errorMap);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Component isRequestAccept={isRequestAccept}>
      {isRequestAccept ? (
        <SuccessForm />
      ) : (
        <Content isMobile={isMobile}>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Formik<IConsultation>
            onSubmit={onSubmit}
            validateOnChange
            initialValues={{ name: '', phone: '', service: '' }}
            validate={handleValidateConsultationForm}
          >
            {({
              handleSubmit,
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
            }) => {
              return (
                <StyledForm onSubmit={handleSubmit} isMobile={isMobile}>
                  <FormContainer isMobile={isMobile}>
                    <FormContainerName isMobile={isMobile}>
                      <TextInput
                        id="name"
                        name="name"
                        label="Имя"
                        placeholder="Имя"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        requiredField
                        errorMessage={
                          touched.name && errors.name ? ' ' : undefined
                        }
                      />
                    </FormContainerName>
                    <FormContainerPhone isMobile={isMobile}>
                      <TextInput
                        id="phone"
                        name="phone"
                        label="Телефон"
                        placeholder="+375 (__) ___-__-__"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        requiredField
                        errorMessage={
                          touched.phone && errors.phone ? ' ' : undefined
                        }
                      />
                    </FormContainerPhone>
                    <FormContainerService isMobile={isMobile}>
                      <SelectService
                        value={selectedValue}
                        options={options}
                        onChange={(item) => setSelectedValue(item)}
                        isRequired={selectedValue.label === 'Выберите услугу'}
                      />
                    </FormContainerService>
                  </FormContainer>
                  <CheckboxAgreementWrapper isMobile={isMobile}>
                    <CheckboxAgreement
                      checked={userAgreement}
                      onChange={() => setUserAgreement(!userAgreement)}
                    />
                  </CheckboxAgreementWrapper>
                  <ButtonWrapper>
                    <Button
                      type="submit"
                      isMobile={isMobile}
                      disabled={
                        !values.name ||
                        Boolean(errors.phone) ||
                        !userAgreement ||
                        selectedValue.label === 'Выберите услугу'
                      }
                    >
                      {loading ? <Loader color={'#fff'} /> : 'заказать звонок'}
                    </Button>
                  </ButtonWrapper>
                </StyledForm>
              );
            }}
          </Formik>
        </Content>
      )}
    </Component>
  );
}

export default ServiceConsultationForm;

const Component = styled.div<{ isRequestAccept: boolean }>`
  position: relative;
  border-radius: 17px;

  display: ${(props): string => (props.isRequestAccept ? 'flex' : '')};
  align-items: ${(props): string => (props.isRequestAccept ? 'center' : '')};
  justify-content: ${(props): string =>
    props.isRequestAccept ? 'center' : ''};

  background: ${(props) => props.theme.white_1};
`;

const Content = styled.div<{ isMobile: boolean }>`
  padding: ${(props): string => (props.isMobile ? '30px 20px' : '0 20px')};
  margin: 0 auto;
  box-shadow: ${(props) => props.theme.blackShadow100};
  height: fit-content;
  border-radius: 17px;
`;

const Title = styled.h2`
  font-size: 36px;
  padding: 48px 0 0;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 30px;
    padding: 0;
  `)}
`;

const Description = styled.span`
  display: block;
  color: ${colors.gray};
  margin-top: 24px;
  font-size: 16px;
  text-align: center;
`;

const StyledForm = styled.form<{ isMobile: boolean }>`
  padding-bottom: ${(props): string => (props.isMobile ? '0' : '55px')};
`;

const FormContainer = styled.div<{ isMobile: boolean }>`
  padding: ${(props): string => (props.isMobile ? '0' : '0 28px')};
  margin: 40px auto 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: normal;
    flex-direction: column;
    align-items: center;
  }
`;

const FormContainerName = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: ${(props): string => (props.isMobile ? '100%' : '384px')};
  margin-right: ${(props): string => (props.isMobile ? '0' : '24px')};
`;

const FormContainerPhone = styled(FormContainerName)`
  @media (max-width: 1170px) {
    margin-top: 30px;
  }
`;

const FormContainerService = styled(FormContainerPhone)``;

const SelectService = styled(Select)<{ isRequired: boolean }>`
  button {
    border: 1px solid #dcdddf;
    height: 50px;

    span {
      font-size: 14px;
      font-weight: 300;
    }
  }

  & > div {
    border: 1px solid #dcdddf;

    li {
      font-size: 14px;
      font-weight: 300;
    }
  }

  ${({ isRequired }) =>
    isRequired &&
    css`
      button {
        span {
          color: ${(props) => props.theme.black100_alpha50};
        }
      }
    `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled(ButtonPrimary)<{
  isMobile: boolean;
}>`
  position: relative;
  height: 50px;
  padding: 11px 0;
  width: ${(props): string => (props.isMobile ? '100%' : '384px')};
  margin-top: 35px;
  font-size: 16px;
  border-radius: 8px;
`;

const CheckboxAgreementWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  margin: 14px 30px 0;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 14px 0 0;
    `}
`;
