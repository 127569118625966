import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/TopLevelFunnelLanding/common.styles';
import { useTopLevelFunnelLandingData } from '@/modules/TopLevelFunnelLanding/hooks';
import { GuaranteeBanner } from '@/components/LandingWidgets';
import { media } from '@/utils/mixin';
import Button from '@/components/Button';
import { scrollToDomElement } from '@/utils/scroll';

function Guarantee() {
  const {
    guaranteeImage,
    guaranteeMobileImage,
    guaranteeText,
    guaranteeButtonTitle,
  } = useTopLevelFunnelLandingData();

  if (!guaranteeImage) {
    return null;
  }

  const handleButtonClick = () => {
    const element = document.getElementById('consultation');

    if (!element) {
      return;
    }

    scrollToDomElement(element);
  };

  return (
    <Component>
      <ContentContainer>
        {guaranteeButtonTitle && (
          <ButtonWrapper>
            <StyledButton variant="outlined" onClick={handleButtonClick}>
              {guaranteeButtonTitle}
            </StyledButton>
          </ButtonWrapper>
        )}

        <GuaranteeBanner
          image={guaranteeImage}
          imageMobile={guaranteeMobileImage}
          text={guaranteeText ?? ''}
        />
      </ContentContainer>
    </Component>
  );
}

export default Guarantee;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  max-width: 300px;
`;
