import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/TopLevelFunnelLanding/common.styles';
import { useTopLevelFunnelLandingData } from '@/modules/TopLevelFunnelLanding/hooks';
import { ServiceFormSecondary } from '@/components/LandingWidgets';
import { media } from '@/utils/mixin';
import { ServiceFormSecondaryFields } from '@/components/LandingWidgets/components/ServiceFormSecondary/types';
import { submitTopLevelFunnelLandingConsultation } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';

function ConsultationForm() {
  const {
    formTitle,
    formSubtitle,
    formImage,
    formMobileImage,
    formButtonTitle,
    formGoogleEvent,
  } = useTopLevelFunnelLandingData();

  if (!formTitle || !formImage) {
    return null;
  }

  const sendConsultationForm = async (
    values: ServiceFormSecondaryFields
  ): Promise<void> => {
    try {
      await submitTopLevelFunnelLandingConsultation(values).then(() => {
        if (formGoogleEvent) {
          googleEvent(formGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <Component id="consultation">
      <ContentContainer>
        <ServiceFormSecondary
          title={formTitle}
          subtitle={formSubtitle ?? ''}
          image={formImage}
          buttonTitle={formButtonTitle ?? ''}
          imageMobile={formMobileImage}
          onSubmit={sendConsultationForm}
        />
      </ContentContainer>
    </Component>
  );
}

export default ConsultationForm;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
