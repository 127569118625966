import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { StylesHoverLink } from '@/modules/Home/components/common.styles';
import Link from '@/components/Link';
import Picture from '@/components/Picture';
import Loader from '@/components/Loader';
import {
  BaseAmpItemProps,
  LinksProps,
  TabButtonsBlogProps,
  TabButtonsProps,
  TabContentBlogProps,
  TabContentProps,
} from '@/modules/Home/components/common.types';
import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { CatalogTabItem } from '@/modules/Home/Home.types';
import { BlogPost } from '@/typings/model';

/** Links **/

export function Links({ itemList }: LinksProps) {
  if (!itemList || itemList.length === 0) {
    return null;
  }

  return (
    <LinkList>
      {itemList.map((link, index) => (
        <LinkItem key={index}>
          <Link to={link.url}>{link.text}</Link>
        </LinkItem>
      ))}
    </LinkList>
  );
}

/**Tab Content (dynamic content)**/

export function TabContent({
  data,
  activeIndex,
  tabAdditionalComponent,
  tabItemComponent,
}: TabContentProps) {
  const isMobile = useMedia(`(max-width: ${breakpoints.tabletSmall - 1}px)`);

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <>
      {data.map(({ items }, index) => (
        <CatalogList key={index} isActive={activeIndex === index}>
          {items.map(({ image, text, url }, index) =>
            React.createElement(tabItemComponent, {
              key: index,
              image,
              text,
              url,
            })
          )}
          {tabAdditionalComponent && !isMobile && tabAdditionalComponent()}
        </CatalogList>
      ))}
      {tabAdditionalComponent && isMobile && tabAdditionalComponent()}
    </>
  );
}

export function TabBlogContent({
  data,
  activeIndex,
  tabItemComponent,
}: TabContentBlogProps) {
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <>
      {data.map(({ items }, index) => (
        <CatalogList
          key={index}
          isActive={activeIndex === index}
          style={{ margin: '-15px -10px' }}
        >
          {items?.map(({ image, title, url }, index) =>
            React.createElement(tabItemComponent, {
              key: image?.url ?? index,
              image,
              title,
              url,
            })
          )}
        </CatalogList>
      ))}
    </>
  );
}

/** additional item (last element in content)**/

export function CatalogPreviewAmpItem({
  total,
  text,
  url,
  image,
}: BaseAmpItemProps) {
  return (
    <CatalogPreviewItem>
      <CatalogPreviewInfoBlock>
        <CatalogPreviewItemNumber>
          {total === 0 ? <Loader /> : total}
        </CatalogPreviewItemNumber>
        <CatalogPreviewItemText>{text}</CatalogPreviewItemText>
      </CatalogPreviewInfoBlock>
      <CatalogItemAmpPicture src={image} loading="lazy" />
      <StyledLink to={url} />
    </CatalogPreviewItem>
  );
}

/** base preview catalog item **/

export function PreviewCardItem({ image, text, url }: CatalogTabItem) {
  return (
    <CatalogItem>
      {image ? (
        <CatalogItemPicture
          loading="lazy"
          alt={text ?? ''}
          {...convertThumbnailToPictureProps(image)}
        />
      ) : null}
      <CatalogItemTitle>{text}</CatalogItemTitle>
      <StyledLink to={url} />
    </CatalogItem>
  );
}

/** base preview news  item **/

export function PreviewNewsCardItem({ title, url, image }: BlogPost) {
  return (
    <NewsItem>
      {image ? (
        <NewsPictureWrapper>
          <NewsPicture
            loading="lazy"
            {...convertThumbnailToPictureProps(image)}
          />
        </NewsPictureWrapper>
      ) : null}
      <NewsText>{title}</NewsText>
      <StyledLink to={url} />
    </NewsItem>
  );
}

/** tab buttons (monitors content changes) **/

export function TabButtons({ data, onClick, activeIndex }: TabButtonsProps) {
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <TabButtonsWrapper>
      {data.map((tab, index) => (
        <TabButton
          key={index}
          onClick={() => onClick(index)}
          isActive={index === activeIndex}
        >
          {tab.text}
        </TabButton>
      ))}
    </TabButtonsWrapper>
  );
}

export function TabBlogButtons({
  data,
  onClick,
  activeIndex,
}: TabButtonsBlogProps) {
  if (!data || data.length === 0) {
    return null;
  }

  return (
    <TabButtonsWrapper>
      {data.map((tab, index) => (
        <TabButton
          key={index}
          onClick={() => onClick(index)}
          isActive={index === activeIndex}
        >
          {tab.text}
        </TabButton>
      ))}
    </TabButtonsWrapper>
  );
}

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const NewsText = styled.span`
  display: -webkit-box;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-top: 20px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: color 0.4s;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const NewsItem = styled.li`
  width: 50%;
  padding: 15px 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    ${NewsText} {
      color: ${colors.main};
      text-decoration: underline;
    }
  }

  ${media.mobile(css`
    width: 100%;
    background: ${(props) => props.theme.white};
  `)}
`;

const NewsPictureWrapper = styled.div`
  position: relative;
  padding-top: 66.66%;
`;
const NewsPicture = styled(Picture)`
  position: initial !important;

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const CatalogItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(25% - 1px);
  padding: 30px;
  background: ${(props) => props.theme.white_1};
  min-height: 170px;

  cursor: pointer;
  transition: all 0.4s;
  margin: 0.5px;

  &:hover {
    box-shadow: ${(props) => props.theme.blackShadow100};
    z-index: 10;

    ${media.mobile(css`
      box-shadow: none;
      z-index: auto;
    `)}
  }

  ${media.mobile(css`
    width: calc(50% - 1px);
  `)}

  ${media.mobileMedium(css`
    padding: 10px;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const CatalogItemPicture = styled(Picture)`
  height: 90px;

  picture {
    padding: 5px;
    border-radius: 10px;
    background: ${colors.white};
  }

  img {
    max-width: 100px;
    max-height: 50px;
  }
`;

const CatalogItemAmpPicture = styled(CatalogItemPicture)`
  margin-left: 30px;

  picture {
    padding: 0 !important;
    border-radius: 0 !important;
    background: none !important;
  }
`;

const CatalogPreviewItemNumber = styled.span`
  font-weight: 700;
  font-size: 52px;
  line-height: 52px;
  margin-bottom: 8px;
  color: ${(props) => props.theme.black200};

  ${media.mobile(css`
    font-size: 40px;
    line-height: 52px;
  `)}
`;

const CatalogPreviewItem = styled(CatalogItem)`
  position: relative;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between !important;
  width: 50% !important;
  background: none !important;
  justify-self: flex-start !important;

  ${CatalogItemPicture} {
    flex-shrink: 0;
  }

  &:hover {
    background: ${(props) => props.theme.white_1} !important;
    box-shadow: none !important;

    ${media.mobile(css`
      background: initial !important;
    `)}
  }

  ${media.mobile(css`
    width: 100% !important;
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.black100_alpha30};
    padding-left: 0;
    padding-right: 0;
    min-height: auto;
  `)}
`;

const CatalogPreviewItemText = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.gray500};

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const CatalogPreviewInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const CatalogItemTitle = styled.span`
  margin-top: 5px;
  display: block;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  color: ${(props) => props.theme.blue100};
  font-size: 14px;
  line-height: 20px;
`;

const LinkList = styled.ul`
  color: ${colors.main};

  ${media.tabletSmall(css`
    display: flex;
    flex-wrap: wrap;
  `)}
`;

const LinkItem = styled.li`
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;

  &:first-child {
    margin-top: 0;
  }

  a {
    ${StylesHoverLink}
  }

  ${media.tabletSmall(css`
    width: calc(50% - 10px);

    &:first-child {
      margin-top: 16px;
    }
  `)}

  ${media.mobile(css`
    width: 100%;
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const CatalogList = styled.ul<{ isActive?: boolean }>`
  display: ${(props) => (props.isActive ? 'flex' : 'none')};
  flex-wrap: wrap;

  ${media.mobile(css`
    background: ${(props) => props.theme.gray};
  `)}
`;

const TabButton = styled.button<{ isActive: boolean }>`
  display: inline-flex;
  padding: 0 30px 4px;
  border: none;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: ${(props) => (props.isActive ? colors.main : props.theme.blue100)};
  border-bottom: 1px solid
    ${(props) => (props.isActive ? colors.main : props.theme.black100_alpha30)};

  ${media.tabletSmall(css`
    display: block;
    width: 100%;
  `)}

  ${media.mobile(css`
    padding: 0 15px 4px;
  `)}
`;

const TabButtonsWrapper = styled.div`
  display: inline-flex;

  ${media.tabletSmall(css`
    display: flex;
    width: 100%;
  `)}
`;
