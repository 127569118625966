import React from 'react';
import styled, { css } from 'styled-components';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { media } from '@/utils/mixin';
import NavArrow from '@/assets/svg/nav-arrow.svg';
import Link from '@/components/Link';
import Picture from '@/components/Picture';

import { ReviewsSliderProps } from './types';

SwiperCore.use([Navigation]);

export function ReviewsSlider({ reviews }: ReviewsSliderProps) {
  if (!reviews || reviews.length === 0) {
    return null;
  }

  return (
    <Component>
      <Swiper
        slidesPerView={1}
        loop={true}
        speed={600}
        navigation={{
          prevEl: `.swiper-prev2`,
          nextEl: `.swiper-next2`,
        }}
        pagination={{
          el: '.swiper-pagination-videos',
          type: 'bullets',
          clickable: true,
        }}
      >
        {reviews.map(({ videoLink, image, imageLink }, index) => (
          <SwiperSlide key={index}>
            {videoLink ? (
              <Video>
                <VideoFrame
                  src={videoLink.replace('youtu.be', 'www.youtube.com/embed')}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Video>
            ) : (
              <ImageWrapper>
                {imageLink ? (
                  <ImageLink to={imageLink}>
                    <Image
                      loading="lazy"
                      src={image?.url}
                      src2x={image?.url_2x}
                      srcWebp={image?.url_webp}
                      srcWebp2x={image?.url_webp_2x}
                      alt={`reviews image ${index + 1}`}
                    />
                  </ImageLink>
                ) : (
                  <Image
                    loading="lazy"
                    src={image?.url}
                    src2x={image?.url_2x}
                    srcWebp={image?.url_webp}
                    srcWebp2x={image?.url_webp_2x}
                    alt={`reviews image ${index + 1}`}
                  />
                )}
              </ImageWrapper>
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      {reviews.length > 1 && (
        <NavButtonsWrapper>
          <NavButton className="swiper-prev2" prev>
            <StyledNavArrow />
          </NavButton>

          <NavButton className="swiper-next2" next>
            <StyledNavArrow />
          </NavButton>
        </NavButtonsWrapper>
      )}

      <Pagination className="swiper-pagination-videos" />
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  padding: 0 70px;

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const Video = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
`;

const VideoFrame = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const ImageLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
`;

const Image = styled(Picture)`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const NavButtonsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 40px);
`;

const NavButton = styled.button<{
  next?: boolean;
  prev?: boolean;
}>`
  position: absolute;
  padding: 30px 20px;
  background-color: rgba(255, 255, 255, 0.15);
  top: 50%;
  z-index: 2;

  ${media.mobile(css`
    padding: 0;
  `)}

  ${({ next }) =>
    next &&
    css`
      right: 0;
      transform: translateY(-50%);

      ${media.mobile(css`
        right: -10px;
      `)}
    `};

  ${({ prev }) =>
    prev &&
    css`
      left: 0;
      transform: translateY(-50%) rotate(-180deg);

      ${media.mobile(css`
        left: -10px;
      `)}
    `};
`;

const StyledNavArrow = styled(NavArrow)`
  width: 23px;
  height: 45px;

  path {
    fill: #c7c8cb;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100% - 140px);
  margin: 40px auto 0;

  ${media.mobile(css`
    width: calc(100% - 40px);
  `)}

  .swiper-pagination-bullet {
    cursor: pointer;
    width: 100%;
    max-width: 80px;
    height: 2px;
    border-radius: 0;
    background: ${(props) => props.theme.black};

    ${media.mobile(css`
      max-width: 40px;
    `)}
  }
`;
