import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';
import { useModal } from '@tager/web-components';

import { useTopLevelFunnelLandingData } from '@/modules/TopLevelFunnelLanding/hooks';
import { ContentContainer } from '@/modules/TopLevelFunnelLanding/common.styles';
import { media } from '@/utils/mixin';
import { getCatalogCarsByIds } from '@/utils/common';
import { useTypedSelector } from '@/store/store';
import { getCatalogItems } from '@/store/reducers/catalogNew';
import useSettingItem from '@/hooks/useSettingItem';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { CatalogCarCardProps } from '@/components/CatalogCarCard/CatalogCarCard.types';
import {
  getCatalogCarButton,
  getCatalogCarImages,
  getCatalogCarLink,
} from '@/modules/CatalogNew/CatalogNew.helper';
import { CarsCatalogList, Title } from '@/components/LandingWidgets';
import { SelectionCarCardProps } from '@/components/LandingWidgets/components/CarsCatalogList/components/SelectionCarCard/types';
import { Component as TitleComponent } from '@/components/LandingWidgets/components/Title';
import { submitTopLevelFunnelLandingSelectionCar } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';

function CatalogCars() {
  const openModal = useModal();
  const {
    popularModelsTitle,
    popularModelsItems,
    popularModelsCardImage,
    popularModelsCardTitle,
    popularModelsCardDescription,
    popularModelsCardButtonTitle,
    popularModelsCardModalTitle,
    popularModelsCardGoogleEvent,
    popularModelsButtonTitle,
    popularModelsButtonLink,
  } = useTopLevelFunnelLandingData();
  const catalogCars = getCatalogCarsByIds(
    useTypedSelector(getCatalogItems).catalogItemsData,
    popularModelsItems ?? []
  );
  const carPriceInfo = useSettingItem('CATALOG_CAR_PRICE_INFO');
  const catalogMainGoogleEvent = useSettingItem('CATALOG_MAIN_GOOGLE_EVENT');
  const isTablet = useMedia('(max-width: 1259px)');
  const isTabletSmall = useMedia('(max-width: 1023px)');
  const isMobile = useMedia('(max-width: 767px)');

  const sendApplicationForm = async (
    values: ApplicationFormFields
  ): Promise<void> => {
    try {
      await submitTopLevelFunnelLandingSelectionCar(values).then(() => {
        if (popularModelsCardGoogleEvent) {
          googleEvent(popularModelsCardGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenModal = () => {
    openModal(
      ApplicationFormModal,
      {
        title: popularModelsCardModalTitle ?? '',
        onSubmit: sendApplicationForm,
        fieldsConfig: { withoutEmail: true },
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  if (!catalogCars || catalogCars.length === 0) {
    return null;
  }

  const catalogCarsData: CatalogCarCardProps[] = catalogCars
    .slice(0, 7)
    .map((car) => ({
      view: 'portrait',
      images: getCatalogCarImages(car, isTablet),
      name: car.name,
      button: getCatalogCarButton(
        openModal,
        car,
        `/cars/new/${car.brand.alias}/${car.model.alias}`,
        catalogMainGoogleEvent ?? ''
      ),
      link: getCatalogCarLink(car),
      price: car.stock.minPrice,
      priceInfo: carPriceInfo ?? '',
    }));

  const selectionCarCardData: SelectionCarCardProps = {
    image: popularModelsCardImage,
    title: popularModelsCardTitle ?? '',
    description: popularModelsCardDescription ?? '',
    buttonTitle: popularModelsCardButtonTitle ?? '',
    onClick: handleOpenModal,
  };

  const slidesPerView = !isTablet ? 4 : !isTabletSmall ? 3 : !isMobile ? 2 : 1;
  const isWithoutNavs = isMobile
    ? false
    : isTabletSmall
    ? catalogCars.length <= 1
    : isTablet
    ? catalogCars.length <= 2
    : catalogCars.length <= 3;
  const isNoSwiping = isMobile
    ? false
    : isTabletSmall
    ? catalogCars.length <= 1
    : isTablet
    ? catalogCars.length <= 2
    : catalogCars.length <= 3;

  return (
    <Component>
      <ContentContainer>
        <TitleWrapper>
          <Title title={popularModelsTitle ?? ''} />
        </TitleWrapper>

        <CarsCatalogList
          id={3}
          cars={catalogCarsData}
          selectionCarCard={selectionCarCardData}
          options={{
            slidesPerView,
            spaceBetween: 12,
            noSwipingClass: 'swiper-no-swiping',
            withoutNavs: isWithoutNavs,
            withoutPagination: true,
            isNoSwiping,
          }}
          buttonTitle={popularModelsButtonTitle ?? ''}
          buttonLink={popularModelsButtonLink ?? ''}
        />
      </ContentContainer>
    </Component>
  );
}

export default CatalogCars;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;

    ${ContentContainer} {
      padding: 0 20px;
    }
  `)}
`;

const TitleWrapper = styled.div`
  margin-bottom: 20px;

  ${media.tablet(css`
    ${TitleComponent} {
      text-align: left;
    }
  `)}

  ${media.mobile(css`
    ${TitleComponent} {
      font-size: 18px;
    }
  `)}
`;
