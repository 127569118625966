import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { useBrandsData } from '@/modules/Brands/Brands.hooks';
import { colors } from '@/constants/theme';
import DealersCard from '@/modules/Brands/components/Dealers/components/DealersCard';
import { ScrollStyles } from '@/components/styles';

function Dealers() {
  const { dealersTitle, dealersItems } = useBrandsData();
  const [activeIndexTab, setActiveIndexTab] = useState(0);

  const handleChangeActiveIndexTab = (index: number) => {
    setActiveIndexTab(index);
  };

  return (
    <Component>
      <ContentContainerSmall>
        <Title>{dealersTitle}</Title>

        <Tabs>
          {dealersItems &&
            dealersItems.map(({ city, dealersCardsItems }, index) => (
              <Tab
                key={index}
                activeTab={activeIndexTab === index}
                onClick={() => handleChangeActiveIndexTab(index)}
              >
                {city} ・ {dealersCardsItems ? dealersCardsItems.length : '0'}
              </Tab>
            ))}
        </Tabs>

        <Cards>
          {dealersItems &&
            dealersItems[activeIndexTab].dealersCardsItems.map(
              ({ imageItems, title, logoImage, link }, index) => (
                <DealersCard
                  key={index}
                  imageItems={imageItems}
                  title={title}
                  logoImage={logoImage}
                  link={link}
                />
              )
            )}
        </Cards>
      </ContentContainerSmall>
    </Component>
  );
}

export default Dealers;

const Component = styled.section`
  padding-bottom: 90px;

  ${media.tablet(css`
    padding-bottom: 60px;
  `)}

  ${media.mobile(css`
    padding-bottom: 40px;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Tabs = styled.div`
  display: flex;

  ${media.mobile(css`
    max-width: 767px;
    overflow-x: auto;
    overflow-y: hidden;

    ${ScrollStyles}
  `)}
`;

const Tab = styled.div<{ activeTab: boolean }>`
  flex: 1;
  padding: 30px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: 0.3s;

  ${media.tablet(css`
    padding: 20px;
    font-size: 14px;
    line-height: 20px;
  `)}

  &:hover {
    border-bottom-color: 1px solid ${colors.main};
  }

  ${({ activeTab }) =>
    activeTab &&
    css`
      color: ${colors.main};
      border-bottom: 1px solid ${colors.main};
    `};
`;

const Cards = styled.div`
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;

  ${media.tablet(css`
    margin-top: 20px;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  `)}

  ${media.mobile(css`
    gap: 10px;
  `)}
`;
