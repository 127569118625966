import React from 'react';
import styled, { css } from 'styled-components';

import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';
import { media } from '@/utils/mixin';
import { scrollToDomElement } from '@/utils/scroll';

import Banner from './components/Banner';

function Hero() {
  const {
    heroImage,
    heroImageMobile,
    heroTitle,
    heroBenefits,
    heroButtonText,
  } = useHelpOnTheRoadData();

  if (!heroImage) {
    return null;
  }

  const handleButtonClick = () => {
    const element = document.getElementById('prices');

    if (!element) {
      return;
    }

    scrollToDomElement(element);
  };

  return (
    <Component>
      <Banner
        image={heroImage}
        imageMobile={heroImageMobile}
        title={heroTitle ?? ''}
        benefits={
          heroBenefits && heroBenefits.length !== 0
            ? heroBenefits.map(({ image, text }) => ({
                image,
                text: text ?? '',
              }))
            : []
        }
        buttonText={heroButtonText ?? ''}
        onClick={handleButtonClick}
      />
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  padding-bottom: 30px;

  ${media.tablet(css`
    padding-bottom: 20px;
  `)}
`;
