import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainerSmall } from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import { useAboutData } from '@/modules/About/About.hooks';

import HistoryCard from './components/HistoryCard';

function History() {
  const { historyItems } = useAboutData();

  return (
    <Component>
      <ContentContainerSmall>
        <Cards>
          {historyItems &&
            historyItems.map(
              ({ backgroundImage, image, title, text }, index) => (
                <HistoryCard
                  key={index}
                  backgroundImage={backgroundImage}
                  image={image}
                  title={title}
                  text={text}
                  reverse={index % 2 === 1}
                />
              )
            )}
        </Cards>
      </ContentContainerSmall>
    </Component>
  );
}

export default History;

const Component = styled.section`
  padding-top: 60px;

  ${media.tablet(css`
    padding: 60px 0 40px;
  `)}

  ${media.mobile(css`
    padding: 0;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}

    ${media.mobile(css`
      padding: 0;
    `)}
  }
`;

const Cards = styled.div``;
