import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FormikErrors, useFormik } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Button from '@/components/Button';
import Loader from '@/components/Loader';
import { CheckboxAgreement } from '@/components/Checkbox';
import { handleValidateField, TypesOfValidate } from '@/utils/textInput';
import CloseButton from '@/components/modals/shared/CloseButton';
import SuccessForm from '@/components/Forms/SuccessForm';
import TextInput from '@/components/TextInput';
import TextArea from '@/components/TextArea';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';

import {
  initialApplicationFormValues,
  validationApplicationSchema,
} from './ApplicationFormModal.helpers';
import {
  ApplicationFormFields,
  ApplicationFormModalProps,
} from './ApplicationFormModal.types';

function ApplicationFormModal({
  innerProps,
  closeModal,
}: ApplicationFormModalProps) {
  const [isFormSend, setFormSend] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);
  const { title, onSubmit, fieldsConfig } = innerProps;

  useLockBodyScroll();

  const {
    values,
    errors,
    setErrors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = useFormik<ApplicationFormFields>({
    initialValues: initialApplicationFormValues,
    validationSchema: validationApplicationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const sentValues = !fieldsConfig?.withoutEmail
          ? { ...values }
          : {
              name: values.name,
              phone: values.phone,
              message: values.message,
            };

        if (onSubmit) {
          await onSubmit(sentValues);
        }

        setFormSend(true);
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<ApplicationFormFields>;
        setErrors(errorMap);
        console.log(error);
      }

      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (fieldsConfig?.withoutEmail) {
      setFieldValue('email', 'test@mail.ru');
    }
  }, [fieldsConfig?.withoutEmail]);

  return (
    <Component
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      isFormSend={isFormSend}
    >
      <CloseButton onClick={closeModal} />

      {!isFormSend ? (
        <React.Fragment>
          <Title>{title}</Title>
          <Subtitle>Контактные данные</Subtitle>

          <FieldsWrapper withoutEmail={fieldsConfig?.withoutEmail}>
            <FieldsBlock withoutEmail={fieldsConfig?.withoutEmail}>
              <FieldsRow withoutEmail={fieldsConfig?.withoutEmail}>
                <TextInput
                  id="name"
                  name="name"
                  label="Имя"
                  value={values.name}
                  onChange={(event) =>
                    handleValidateField(
                      event,
                      handleChange,
                      TypesOfValidate.TEXT
                    )
                  }
                  placeholder="Имя"
                  errorMessage={
                    errors.name && touched.name ? errors.name : undefined
                  }
                  autoComplete="off"
                />
              </FieldsRow>

              <FieldsRow withoutEmail={fieldsConfig?.withoutEmail}>
                <TextInput
                  id="phone"
                  name="phone"
                  label="Телефон"
                  value={values.phone}
                  onChange={handleChange}
                  placeholder="+375 (__) ___-__-__"
                  errorMessage={
                    errors.phone && touched.phone ? errors.phone : undefined
                  }
                  autoComplete="off"
                />
              </FieldsRow>

              {!fieldsConfig?.withoutEmail && (
                <FieldsRow>
                  <TextInput
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={(event) =>
                      handleValidateField(
                        event,
                        handleChange,
                        TypesOfValidate.TEXT
                      )
                    }
                    placeholder="Email"
                    errorMessage={
                      errors.email && touched.email ? errors.email : undefined
                    }
                    autoComplete="off"
                  />
                </FieldsRow>
              )}
            </FieldsBlock>

            <FieldsContainer withoutEmail={fieldsConfig?.withoutEmail}>
              <TextArea
                id="message"
                name="message"
                label="Комментарий"
                value={values.message}
                onChange={handleChange}
                placeholder="Комментарий"
                errorMessage={
                  errors.message && touched.message ? errors.message : undefined
                }
                autoComplete="off"
              />
            </FieldsContainer>
          </FieldsWrapper>

          <CheckBoxWrapper>
            <CheckboxAgreement
              checked={userAgreement}
              onChange={() => setUserAgreement(!userAgreement)}
            />
          </CheckBoxWrapper>

          <ButtonWrapper>
            <SubmitCreditApplication
              type="submit"
              disabled={!isSubmitting ? !userAgreement : true}
            >
              {!isSubmitting ? 'Отправить' : <Loader />}
            </SubmitCreditApplication>
          </ButtonWrapper>
        </React.Fragment>
      ) : (
        <StyledSuccess />
      )}
    </Component>
  );
}

export default ApplicationFormModal;

const Component = styled.form<{ isFormSend: boolean }>`
  position: relative;
  padding: 25px 40px;
  max-width: 674px;
  background: ${(props) => props.theme.white};
  border-radius: 10px;

  ${media.mobile(css`
    max-width: none;
    padding: 20px;
  `)}

  ${({ isFormSend }) =>
    isFormSend &&
    css`
      min-width: 674px;
      padding: 100px 40px;

      ${media.laptop(css`
        min-width: 528px;
        padding: 32px 40px 48px;
      `)}

      ${media.mobile(css`
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100vw;
        min-height: 100vh;
      `)}
    `}
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 25px;
  line-height: 28px;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.laptop(css`
    font-size: 20px;
  `)}
`;

const Subtitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-top: 25px;
  color: ${(props) => props.theme.black100};
`;

const FieldsWrapper = styled.div<{ withoutEmail?: boolean }>`
  display: flex;
  margin-top: 24px;

  ${media.mobile(css`
    flex-direction: column;
  `)}

  ${({ withoutEmail }) =>
    withoutEmail &&
    css`
      flex-direction: column;
    `}
`;

const FieldsBlock = styled.div<{ withoutEmail?: boolean }>`
  flex: 0 0 285px;

  ${media.mobile(css`
    flex: auto;
  `)}

  ${({ withoutEmail }) =>
    withoutEmail &&
    css`
      display: flex;
      flex-direction: row;
      flex: auto;

      ${media.mobile(css`
        flex-direction: column;
      `)}
    `}
`;

const FieldsRow = styled.div<{ withoutEmail?: boolean }>`
  &:not(:first-child) {
    margin-top: 30px;

    ${media.mobile(css`
      margin-top: 35px;
    `)}
  }

  ${({ withoutEmail }) =>
    withoutEmail &&
    css`
      width: 100%;

      &:not(:first-child) {
        margin-top: 0;
        margin-left: 24px;

        ${media.mobile(css`
          margin-top: 35px;
          margin-left: 0;
        `)}
      }
    `}
`;

const FieldsContainer = styled.div<{ withoutEmail?: boolean }>`
  flex: 0 0 285px;
  margin-left: 24px;

  ${media.mobile(css`
    flex: auto;
    margin-left: 0;
    margin-top: 34px;
  `)}

  ${({ withoutEmail }) =>
    withoutEmail &&
    css`
      flex: auto;
      margin-left: 0;
      margin-top: 34px;
    `}
`;

const CheckBoxWrapper = styled.div`
  display: block;
  margin-top: 20px;

  ${media.mobile(css`
    margin-top: 25px;
  `)}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  ${media.mobile(css`
    margin-top: 25px;
  `)}
`;

const SubmitCreditApplication = styled(Button)`
  position: relative;
  font-weight: bold;
  padding: 15px 0;
  width: 160px;
  background: ${colors.main};
  color: ${colors.white};
  border-radius: 14px;
  transition: 0.3s;
  letter-spacing: normal;
  height: 48px;

  ${media.mobile(css`
    width: 100%;
  `)}

  &:hover {
    background: ${colors.main100};
  }

  &:disabled {
    color: ${(props) => props.theme.black100};
    background: ${(props) => props.theme.gray400_1};
    border: 1px solid ${(props) => props.theme.gray400_2};
    opacity: 0.35;

    &:hover {
      background: ${(props) => props.theme.gray400_1};
    }
  }
`;

const StyledSuccess = styled(SuccessForm)`
  & > div > svg {
    width: 130px;
    height: 130px;

    ${media.laptop(css`
      width: 64px;
      height: 64px;
    `)}
  }

  & > span {
    font-size: 25px;
    margin-top: 10px;

    ${media.laptop(css`
      font-size: 20px;
      margin-top: 16px;
    `)}
  }

  & > p {
    font-size: 22px;
    margin-top: 15px;

    ${media.laptop(css`
      font-size: 14px;
      margin-top: 8px;
    `)}
  }
`;
