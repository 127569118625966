import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainerSmall } from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import { useInsuranceData } from '@/modules/Insurance/Insurance.hooks';
import QuestionAndAnswerItem from '@/modules/Insurance/components/Services/components/QuestionAndAnswerItem';

import CarInsurance from './components/CarInsurance';

function Services() {
  const {
    insuranceServicesTitle,
    insuranceServicesDescription,
    insuranceServicesItems,
  } = useInsuranceData();

  return (
    <Component>
      <ContentContainerSmall>
        <Content>
          <Main>
            <Title>{insuranceServicesTitle}</Title>
            <Description
              dangerouslySetInnerHTML={{
                __html: insuranceServicesDescription ?? '',
              }}
            />
            <List>
              {insuranceServicesItems &&
                insuranceServicesItems.map(({ question, answer }, index) => (
                  <QuestionAndAnswerItem
                    key={index}
                    question={question}
                    answer={answer}
                  />
                ))}
            </List>
          </Main>
          <CarInsurance />
        </Content>
      </ContentContainerSmall>
    </Component>
  );
}

export default Services;

const Component = styled.section`
  padding: 90px 0;

  ${media.tablet(css`
    padding: 60px 0;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    flex-direction: column;
  `)}
`;

const Main = styled.div`
  flex: 1 1 auto;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  margin: 0;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Description = styled.div`
  margin-top: 35px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    margin-top: 20px;
  `)}

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.blue100};

    ${media.tablet(css`
      font-size: 14px;
      line-height: 20px;
    `)}

    &:not(:last-child) {
      margin-bottom: 1.5vw;

      ${media.tablet(css`
        margin-bottom: 2vw;
      `)}
    }
  }

  ul,
  ol {
    margin: 20px 0 20px 2vw;

    li {
      list-style-type: disc;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-left: 0.6vw;
      color: ${(props) => props.theme.blue100};

      ${media.tablet(css`
        font-size: 14px;
        line-height: 20px;
      `)}

      &:not(:first-child) {
        margin-top: 5px;

        ${media.tablet(css`
          margin-top: 0;
        `)}
      }
    }
  }
`;

const List = styled.div`
  margin-top: 35px;
`;
