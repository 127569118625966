import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useMedia, useUpdateEffect } from '@tager/web-core';

import { ButtonPrimary } from '@/components/CarCard/components/Button';
import { useTypedDispatch } from '@/store/store';
import { ButtonStyles } from '@/components/CarCard/components/common.styles';
import { setOnlineUserChoice } from '@/store/reducers/online-valuation-user-data';
import {
  RateCarDataModel,
  OnlineValuationUserChoiceModel,
} from '@/modules/ValueCar/ValueCar.types';
import {
  setRateCarBodyTypes,
  setRateCarBodyTypesPending,
  setRateCarGenerations,
  setRateCarModels,
  setRateCarModelsPending,
  setRateCarParameters,
  setRateCarYears,
  setRateCarYearsPending,
} from '@/store/reducers/rate-car-data';
import { setOfflineUserChoice } from '@/store/reducers/offline-valuation-user-data';
import { getOfflineDataWithValues } from '@/modules/ValueCar/components/OfflineValuation/components/ContactFormOfflineValuation/helpers';
import { getOnlineDataWithValues } from '@/modules/ValueCar/components/OnlineValuation/helpers';
import { setSellCarView } from '@/store/reducers/sell-car';
import {
  getZoomosBodyTypes,
  getZoomosModels,
  getZoomosYears,
} from '@/services/requests';

import { FormRateCarModel } from '../../FormRateCar.models';

import VerticalCollapsedList from './components/VerticalCollapsed/VerticalCollapsedList';
import VerticalCollapsedItem from './components/VerticalCollapsed/VerticalCollapsedItem';
import HorizontalCollapsedItem from './components/HorizontalCollapsed/HorizontalCollapsedItem';
import HorizontalCollapsedList from './components/HorizontalCollapsed/HorizontalCollapsedList';

interface Props {
  rateCarData: RateCarDataModel;
  values: FormRateCarModel;
  userChoice: OnlineValuationUserChoiceModel;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  isHorizontalForm?: boolean;
  isValuationPage?: boolean;
  isChecked?: boolean;
  submitLabel?: string;
}

function FormComponent({
  values,
  rateCarData,
  setFieldValue,
  isHorizontalForm,
  isValuationPage,
  isChecked,
  submitLabel = 'Оценить автомобиль',
}: Props) {
  const isMobile = useMedia('(max-width: 768px)');
  const [loading, setLoading] = useState(false);
  const [isExpandedBrands, setIsExpandedBrands] = useState(false);
  const [isExpandedModels, setIsExpandedModels] = useState(false);
  const [isExpandedYears, setIsExpandedYears] = useState(false);
  const [brandsList, setBrandsList] = useState(rateCarData.brands);
  const [modelsList, setModelsList] = useState(rateCarData.models);
  const [yearsList, setYearsList] = useState<string[]>(
    rateCarData.years.map((item) => item.toString())
  );

  const dispatch = useTypedDispatch();

  useUpdateEffect(() => {
    (async () => {
      if (values && values.brand && setLoading) {
        setLoading(true);
        setIsExpandedModels(false);
        setIsExpandedYears(false);
        dispatch(setRateCarYears([]));
        dispatch(setRateCarBodyTypes([]));
        dispatch(setRateCarGenerations([]));
        dispatch(setRateCarParameters([]));
        setFieldValue('model', '');
        setFieldValue('year', '');
        dispatch(setOfflineUserChoice(getOfflineDataWithValues(values.brand)));
        dispatch(setOnlineUserChoice(getOnlineDataWithValues(values.brand)));
        dispatch(setRateCarModelsPending);

        const response = await getZoomosModels(values.brand);

        setFieldValue('models', response);
        dispatch(setRateCarModels(response));
        setModelsList(response);
        setLoading(false);
        setIsExpandedBrands(false);
      }
    })();
  }, [values?.brand]);

  useUpdateEffect(() => {
    (async () => {
      if (values && values.brand && values.model && setLoading) {
        setLoading(true);
        setIsExpandedBrands(false);
        setIsExpandedYears(false);
        setFieldValue('bodyType', '');
        setFieldValue('year', '');
        dispatch(setRateCarYears([]));
        dispatch(setRateCarBodyTypes([]));
        dispatch(setRateCarGenerations([]));
        dispatch(setRateCarParameters([]));
        dispatch(
          setOfflineUserChoice(
            getOfflineDataWithValues(values.brand, values.model)
          )
        );
        dispatch(
          setOnlineUserChoice(
            getOnlineDataWithValues(values.brand, values.model)
          )
        );
        dispatch(setRateCarYearsPending);

        const response = await getZoomosYears(values.brand, values.model);

        const years = [];
        for (
          let i = response.min;
          i <= (response.max ? response.max : new Date().getFullYear());
          i++
        ) {
          years.push(i);
        }

        setFieldValue('years', years);
        dispatch(setRateCarYears(years));
        setYearsList(years.map((item) => item.toString()));
        setLoading(false);
        setIsExpandedModels(false);
      }
    })();
  }, [values?.model]);

  useUpdateEffect(() => {
    (async () => {
      if (values && values.brand && values.model && values.year && setLoading) {
        setLoading(true);
        dispatch(setRateCarBodyTypes([]));
        dispatch(setRateCarGenerations([]));
        dispatch(setRateCarParameters([]));
        dispatch(
          setOfflineUserChoice(
            getOfflineDataWithValues(values.brand, values.model, values.year)
          )
        );
        dispatch(
          setOnlineUserChoice(
            getOnlineDataWithValues(values.brand, values.model, values.year)
          )
        );
        dispatch(setRateCarBodyTypesPending);

        const response = await getZoomosBodyTypes(
          values.brand,
          values.model,
          values.year
        );

        dispatch(setRateCarBodyTypes(response));
        setLoading(false);
        setIsExpandedYears(false);
      }
    })();
  }, [values?.year]);

  if (!values) return null;

  return (
    <>
      {isHorizontalForm ? (
        <>
          <HorizontalCollapsedInputContainer>
            <HorizontalCollapsedList
              label={values.brand ? values.brand : 'Марка'}
              isExpandedList={isExpandedBrands}
              handleExpandedInner={setIsExpandedBrands}
              isClickable={loading}
            >
              {rateCarData.brands.map((brand, index) => {
                return (
                  <HorizontalCollapsedItem
                    value={brand}
                    key={index}
                    setFieldValue={setFieldValue}
                    selectedValue={values.brand}
                    onClose={setIsExpandedBrands}
                    isBrand={true}
                  />
                );
              })}
            </HorizontalCollapsedList>
            <HorizontalCollapsedList
              label={values.model ? values.model : 'Модель'}
              isExpandedList={isExpandedModels}
              handleExpandedInner={setIsExpandedModels}
              disabled={Boolean(!values.brand)}
              hintText={'Выберите марку'}
              isClickable={loading}
            >
              {rateCarData.models.map((model, index) => {
                return (
                  <HorizontalCollapsedItem
                    value={model}
                    key={index}
                    setFieldValue={setFieldValue}
                    selectedValue={values.model}
                    onClose={setIsExpandedModels}
                    isModel={true}
                  />
                );
              })}
            </HorizontalCollapsedList>
            <HorizontalCollapsedList
              label={values.year ? values.year : 'Год выпуска'}
              isExpandedList={isExpandedYears}
              handleExpandedInner={setIsExpandedYears}
              disabled={Boolean(!values.model) || loading}
              hintText={'Выберите модель'}
              isClickable={loading}
            >
              {rateCarData.years.map((year, index) => {
                return (
                  <HorizontalCollapsedItem
                    selectedValue={String(values.year)}
                    value={year.toString()}
                    key={index}
                    setFieldValue={setFieldValue}
                    onClose={setIsExpandedYears}
                    isYear={true}
                  />
                );
              })}
            </HorizontalCollapsedList>
          </HorizontalCollapsedInputContainer>
          <HorizontalFormButton
            onClick={() => dispatch(setSellCarView('OFFLINE'))}
            isMobile={isMobile}
            disabled={!values.brand || !values.model || !values.year || loading}
          >
            {submitLabel}
          </HorizontalFormButton>
        </>
      ) : (
        <>
          <VerticalCollapsedInputContainer isValuationPage={isValuationPage}>
            <VerticalCollapsedList
              rateCarListData={rateCarData.brands}
              setValues={setBrandsList}
              label={values.brand ? values.brand : 'Марка'}
              handleExpanded={isExpandedBrands}
              handleExpandedInner={setIsExpandedBrands}
              isChecked={isChecked}
              isClickable={loading}
            >
              {brandsList.map((brand, index) => {
                return (
                  <VerticalCollapsedItem
                    value={brand}
                    key={index}
                    setFieldValue={setFieldValue}
                    selectedValue={values.brand}
                    onClose={setIsExpandedBrands}
                    isBrand={true}
                  />
                );
              })}
            </VerticalCollapsedList>
            <VerticalCollapsedList
              rateCarListData={rateCarData.models}
              setValues={setModelsList}
              label={values.model ? values.model : 'Модель'}
              handleExpanded={isExpandedModels}
              handleExpandedInner={setIsExpandedModels}
              disabled={Boolean(!values.brand)}
              hintText={'Выберите марку'}
              isChecked={isChecked}
              isClickable={loading}
            >
              {modelsList.map((model, index) => {
                return (
                  <VerticalCollapsedItem
                    value={model}
                    key={index}
                    setFieldValue={setFieldValue}
                    selectedValue={values.model}
                    onClose={setIsExpandedModels}
                    isModel={true}
                  />
                );
              })}
            </VerticalCollapsedList>
            <VerticalCollapsedList
              rateCarListData={rateCarData.years.map((item) => item.toString())}
              setValues={setYearsList}
              label={values.year ? String(values.year) : 'Год выпуска'}
              handleExpanded={isExpandedYears}
              handleExpandedInner={setIsExpandedYears}
              disabled={Boolean(!values.model)}
              hintText={'Выберите модель'}
              isChecked={isChecked}
              isClickable={loading}
            >
              {yearsList.map((year, index) => {
                return (
                  <VerticalCollapsedItem
                    selectedValue={String(values.year)}
                    value={year.toString()}
                    key={index}
                    setFieldValue={setFieldValue}
                    onClose={setIsExpandedYears}
                    isYear={true}
                  />
                );
              })}
            </VerticalCollapsedList>
          </VerticalCollapsedInputContainer>
          {!isValuationPage && (
            <VerticalFormButton
              onClick={() => dispatch(setSellCarView('OFFLINE'))}
              disabled={
                !values.brand || !values.model || !values.year || loading
              }
            >
              {submitLabel}
            </VerticalFormButton>
          )}
        </>
      )}
    </>
  );
}

export default FormComponent;

const HorizontalCollapsedInputContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 100;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const VerticalCollapsedInputContainer = styled.div<{
  isValuationPage?: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 8px;
  z-index: 100;

  ${({ isValuationPage }) =>
    isValuationPage &&
    css`
      width: 640px;

      @media (max-width: 900px) {
        width: 100%;
      }
    `};
`;

const HorizontalFormButton = styled(ButtonPrimary)<{ isMobile: boolean }>`
  height: 53px;
  padding: 13px 0;
  width: ${(props): string => (props.isMobile ? '100%' : '384px')};
  margin-top: 26px;
  font-size: 16px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
`;

const VerticalFormButton = styled(ButtonPrimary)<{ disabled?: boolean }>`
  ${ButtonStyles};
  height: 53px;
  padding: 18px 0;
  width: 100%;
  margin-top: 26px;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : '')};
`;
