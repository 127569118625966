import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { generateNumberArray, Nullable } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { useHistoryData } from '@/modules/History/History.hooks';

import Sidebar from './components/Sidebar';
import Stories from './components/Stories';

function HistoryBlock() {
  const { historyItems } = useHistoryData();

  const [activeYear, setActiveYear] = useState<Nullable<string>>(null);
  const historyItemsIndices = historyItems
    ? generateNumberArray(historyItems.length)
    : [1];

  const storiesBlockItemsRefs = useRef(
    historyItemsIndices.map(() => React.createRef<HTMLDivElement>())
  );

  useEffect(() => {
    const onScroll = () => {
      storiesBlockItemsRefs.current.forEach((el) => {
        if (el.current && el.current.offsetTop - window.scrollY < 83) {
          setActiveYear(el.current.id);
        }
      });
    };

    onScroll();

    window.addEventListener('scroll', onScroll);

    return () => {
      window.addEventListener('scroll', onScroll);
    };
  }, []);

  const years = useMemo(
    () => (historyItems ?? []).map(({ year }) => year),
    [historyItems]
  );

  return (
    <Component>
      <ContentContainerSmall>
        <Content>
          <Sidebar years={years} activeYear={activeYear} />
          <Stories
            stories={historyItems}
            storiesBlockItemsRefs={storiesBlockItemsRefs}
          />
        </Content>
      </ContentContainerSmall>
    </Component>
  );
}

export default HistoryBlock;

const Component = styled.section`
  padding: 90px 0;

  ${media.tablet(css`
    padding: 60px 0;
  `)}

  ${media.mobile(css`
    padding: 40px 0;
  `)}
  
  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}

    ${media.mobile(css`
      padding: 0;
    `)}
  }
`;

const Content = styled.div`
  display: flex;
`;
