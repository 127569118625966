import React from 'react';
import styled, { css } from 'styled-components';

import ListIcon from '@/assets/svg/bonus-program/list-icon.svg';
import { ProgramLoyaltyItem } from '@/modules/BonusProgram/BonusProgram.types';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ScrollStyles } from '@/components/styles';

function ListItem({ text }: ProgramLoyaltyItem) {
  return (
    <Component>
      <ListIcon />
      <Content dangerouslySetInnerHTML={{ __html: text ?? '' }} />
    </Component>
  );
}

export default ListItem;

const Component = styled.div`
  display: flex;

  &:not(:first-child) {
    margin-top: 40px;

    ${media.tablet(css`
      margin-top: 10px;
    `)}
  }

  svg {
    flex: 0 0 42px;

    polyline {
      stroke: ${(props) => props.theme.black200};
    }
  }
`;

const Content = styled.div`
  margin-left: 15px;
  color: ${(props) => props.theme.black};
  overflow: hidden;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;

    ${media.tablet(css`
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 15px;
    `)}
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 1.5vw;
    color: ${(props) => props.theme.black200};
  }

  ul,
  ol {
    margin-bottom: 20px;

    li {
      position: relative;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-indent: 15px;
      color: ${(props) => props.theme.black200};

      &:before {
        position: absolute;
        content: '';
        top: 9px;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: ${(props) => props.theme.black};
      }
    }
  }

  blockquote {
    padding: 20px 30px 20px 27px;
    margin: 20px 0;
    border-left: 3px solid ${colors.main};
    background: ${(props) => props.theme.gray};

    ${media.tablet(css`
      margin: 25px 0;
    `)}

    p,
    ul,
    ol {
      margin: 0;
    }
  }

  figure {
    overflow: auto;
    ${ScrollStyles}

    table {
      width: 100%;
      min-width: 585px;
      border-collapse: collapse;
      border: 1px solid ${colors.gray400} !important;

      tbody {
        tr {
          th,
          td {
            border: 1px solid ${colors.gray400} !important;
          }
        }
      }
    }
  }
`;
