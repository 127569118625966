import React from 'react';
import styled, { css } from 'styled-components';

import { useHowToData } from '@/modules/HowTo/HowTo.hooks';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import QuestionAndAnswer from './QuestionAndAnswer';

function Faq() {
  const { howToTitle, howToQuestionsAndAnswers } = useHowToData();

  return (
    <Component>
      <ContentContainerSmall>
        <Title>{howToTitle}</Title>
        <QuestionsList>
          {howToQuestionsAndAnswers &&
            howToQuestionsAndAnswers.map(({ question }, index: number) => (
              <QuestionWrapper key={index}>
                <Question href={`#question${index + 1}`}>{question}</Question>
              </QuestionWrapper>
            ))}
        </QuestionsList>

        <List>
          {howToQuestionsAndAnswers &&
            howToQuestionsAndAnswers.map(
              ({ id, question, answer }, index: number) => (
                <QuestionAndAnswer
                  key={index}
                  id={`question${index + 1}`}
                  question={question}
                  answer={answer}
                />
              )
            )}
        </List>
      </ContentContainerSmall>
    </Component>
  );
}

export default Faq;

const Component = styled.section`
  padding: 60px 0;

  ${media.tablet(css`
    padding: 20px 0 40px;
  `)}

  ${ContentContainerSmall} {
    max-width: 870px;
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  margin: 0;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const QuestionsList = styled.div`
  margin-top: 80px;

  ${media.tablet(css`
    margin-top: 60px;
  `)}
`;

const QuestionWrapper = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 10px 0;

  ${media.tablet(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const Question = styled.a`
  color: ${colors.main};
  border-bottom: 1px solid transparent;

  &:hover {
    border-color: ${colors.main};
  }
`;

const List = styled.div`
  margin-top: 20px;
`;
