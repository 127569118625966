import React from 'react';
import styled from 'styled-components';

import { useMedia } from '@tager/web-core';

import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { ButtonStyles } from '@/components/CarCard/components/common.styles';
import { useAmpLandingData } from '@/modules/AmpLanding/AmpLanding.hooks';
import { colors } from '@/constants/theme';

function EstimateCar() {
  const {
    estimateTitle,
    estimateText,
    estimateButtonTitle,
    estimateButtonLink,
    estimateLeftImage,
    estimateRightImage,
  } = useAmpLandingData();
  const isMobile = useMedia('(max-width: 768px)');

  return (
    <Component>
      <Container>
        <StyledPictureLeft
          loading="lazy"
          src={estimateRightImage?.url}
          src2x={estimateRightImage?.url_2x}
          srcWebp={estimateRightImage?.url_webp}
          srcWebp2x={estimateRightImage?.url_webp_2x}
        />
        <StyledPictureRight
          loading="lazy"
          src={estimateLeftImage?.url}
          src2x={estimateLeftImage?.url_2x}
          srcWebp={estimateLeftImage?.url_webp}
          srcWebp2x={estimateLeftImage?.url_webp_2x}
        />
        <InformationContentContainer>
          <Title>{estimateTitle}</Title>
          <Information
            dangerouslySetInnerHTML={{ __html: estimateText ?? '' }}
          />

          <StyledLink to={estimateButtonLink} isMobile={isMobile}>
            {estimateButtonTitle}
          </StyledLink>
        </InformationContentContainer>
      </Container>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  height: fit-content;
  margin-top: 65px;

  @media (max-width: 1100px) {
    padding-top: 320px;
    height: fit-content;
    margin: 0;
  }

  @media (max-width: 450px) {
    padding-top: 240px;
  }
`;

const Container = styled.div`
  max-width: 1280px;
  margin: 65px auto 0;
  padding: 0 20px;
`;

const StyledPictureLeft = styled(Picture)`
  position: static !important;

  img {
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 30px;

    @media (max-width: 1350px) {
      height: 90%;
    }

    @media (max-width: 1100px) {
      height: 46.9%;
      top: 670px;
    }

    @media (max-width: 450px) {
      height: 29.6%;
      top: 650px;
    }

    @media (max-width: 380px) {
      height: 29.8%;
      top: 670px;
    }

    @media (max-width: 330px) {
      height: 30%;
      top: 700px;
    }
  }
`;

const StyledPictureRight = styled(StyledPictureLeft)`
  img {
    left: 0;
    top: 30px;

    @media (max-width: 1100px) {
      height: 46.8%;
      top: 70px;
    }
    @media (max-width: 450px) {
      height: 29.6%;
    }
  }
`;

const InformationContentContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h3`
  max-width: 737px;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0;
  text-align: center;
  color: ${(props) => props.theme.blue200};
`;

const Information = styled.div`
  display: block;
  text-align: center;
  margin-top: 40px;
  color: ${(props) => props.theme.black};

  p {
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.blue100};

    strong {
      font-weight: normal;
      color: ${colors.main};
    }
  }
`;

const StyledLink = styled(Link)<{ isMobile: boolean }>`
  ${ButtonStyles};
  width: ${(props): string => (props.isMobile ? '100%' : '384px')};
  padding: 16px 0;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 8px;
  font-size: 16px;
  margin: 34px auto 0;
  display: block;
  text-align: center;
`;

export default EstimateCar;
