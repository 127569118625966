import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';

import DateIcon from '@/assets/svg/date-icon.svg';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  selectOfflineValuationUserChoice,
  setOfflineChosenDate,
  setOfflineChosenTime,
} from '@/store/reducers/offline-valuation-user-data';
import { OfflineCheckedValuesModel } from '@/modules/ValueCar/components/OfflineValuation/components/ContactFormOfflineValuation/interfaces';
import { DatePickerStyles } from '@/components/styles';
import TextInput from '@/components/TextInput';
import { getTomorrowDate } from '@/utils/date';

interface Props {
  dateRef: MutableRefObject<HTMLDivElement | null>;
  checkedValues?: OfflineCheckedValuesModel;
  setCheckedValues?: Dispatch<SetStateAction<OfflineCheckedValuesModel>>;
  isChecked?: boolean;
  setIsShowTimePicker: Dispatch<SetStateAction<boolean>>;
}

function DateComponent({
  dateRef,
  setCheckedValues,
  checkedValues,
  isChecked,
  setIsShowTimePicker,
}: Props) {
  const chosenData = useTypedSelector(selectOfflineValuationUserChoice);
  const dispatch = useTypedDispatch();
  const [selectedDate, setSelectedDate] = useState<Date>();

  function handleChangeDate(data: Date | [Date | null, Date | null] | null) {
    if (data && setCheckedValues && checkedValues) {
      setSelectedDate(data as Date);
      setIsShowTimePicker(false);
      dispatch(setOfflineChosenDate(moment(data as Date).format('YYYY-MM-DD')));
      dispatch(setOfflineChosenTime(''));
      setCheckedValues({ ...checkedValues, dateCheck: true });
    }
  }

  return (
    <Component ref={dateRef} isChecked={isChecked}>
      {
        <DatePicker
          value={chosenData.date}
          locale="ru"
          selected={selectedDate}
          placeholderText="Дата"
          dateFormat={['dd.MM.yyyy']}
          minDate={getTomorrowDate()}
          fixedHeight
          popperClassName="some-custom-class"
          popperPlacement="top-start"
          customInput={
            <TextInput
              label="Дата"
              value={chosenData.date}
              badge={<DateIcon />}
            />
          }
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, -3],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                rootBoundary: 'viewport',
                tether: false,
                altAxis: true,
              },
            },
          ]}
          onChange={(data) => {
            handleChangeDate(data);
          }}
        />
      }
    </Component>
  );
}

export default DateComponent;

const Component = styled.div<{ isChecked?: boolean }>`
  position: relative;
  z-index: 5;
  width: 384px;
  margin-right: 24px;

  ${DatePickerStyles};

  ${({ isChecked }) =>
    !isChecked &&
    css`
      input {
        border: 1px solid #ee8f8f;
        background: #fff4f4;
      }
    `};

  @media (max-width: 768px) {
    width: 100%;
  }
`;
