import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/CarModelCompareLanding/common.styles';
import { useCarModelCompareLandingData } from '@/modules/CarModelCompareLanding/hooks';
import { media } from '@/utils/mixin';
import { useCarSelectionHelper } from '@/hooks/useCarSelectionHelper';
import CarSelectionHelperDie from '@/components/CarSelectionHelperDie';

function Header() {
  const { heroTitle, heroSubtitle } = useCarModelCompareLandingData();
  const { die } = useCarSelectionHelper(true);

  return (
    <Component>
      <ContentContainer>
        <Title>{heroTitle}</Title>
        <Subtitle>{heroSubtitle}</Subtitle>

        {die.text && (
          <CarSelectionHelperDieWrapper>
            <StyledCarSelectionHelperDie {...die} />
          </CarSelectionHelperDieWrapper>
        )}
      </ContentContainer>
    </Component>
  );
}

export default Header;

const Component = styled.div`
  margin-top: 60px;

  ${media.tablet(
    css`
      margin-top: 20px;
    `
  )}
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  color: ${(props) => props.theme.black};

  ${media.tabletSmall(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Subtitle = styled.p`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-top: 10px;
  color: ${(props) => props.theme.black};

  ${media.tabletSmall(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const StyledCarSelectionHelperDie = styled(CarSelectionHelperDie)`
  max-width: 335px;
`;

const CarSelectionHelperDieWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.tablet(css`
    margin-top: 30px;
    justify-content: flex-start;
  `)}
`;
