import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import ArrowIcon from '@/assets/svg/arrow-down.svg';
import { colors } from '@/constants/theme';

import { CollapseInfoProps } from './types';

export function CollapseInfo({
  title,
  rows,
  currentSlideIndex,
}: CollapseInfoProps) {
  const [isOpened, setOpened] = useState(true);

  const handleChangeOpenedState = () => {
    setOpened(!isOpened);
  };

  if (!rows || rows.length === 0) {
    return null;
  }

  return (
    <Component>
      <Header onClick={handleChangeOpenedState}>
        <Title>
          {title} <StyledArrowIcon isOpened={isOpened} />
        </Title>
      </Header>
      {isOpened && (
        <Table>
          {rows.map(({ name, cells }, index) => (
            <TableRow key={index}>
              <Aside>{name}</Aside>
              <MainWrapper>
                <Main currentSlideIndex={currentSlideIndex}>
                  <Cells>
                    {cells.map(({ cell }, index) => (
                      <TableCell key={index}>
                        <CellText notText={!cell}>
                          {cell === '' ? '+' : cell === null ? '-' : cell}
                        </CellText>
                      </TableCell>
                    ))}
                  </Cells>
                </Main>
              </MainWrapper>
            </TableRow>
          ))}
        </Table>
      )}
    </Component>
  );
}

const Component = styled.div`
  overflow: hidden;
`;

const Header = styled.div`
  cursor: pointer;
  padding: 18.5px 20px;
  display: block;
  background: ${(props) => props.theme.gray_1};
  border-radius: 10px;
`;

const Title = styled.p`
  display: flex;
  align-items: center;
  position: sticky;
  left: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${(props) => props.theme.blue100};
`;

const StyledArrowIcon = styled(ArrowIcon)<{ isOpened: boolean }>`
  width: 12px;
  height: 12px;
  margin-left: 6px;
  transition: 0.2s;

  path {
    fill: ${(props) => props.theme.blue100};
  }

  ${({ isOpened }) =>
    isOpened &&
    css`
      transform: rotate(-180deg);
    `}
`;

const Table = styled.div`
  margin-top: 20px;
`;

const Aside = styled.div`
  z-index: 2;
  position: sticky;
  left: 0;
  flex: 0 0 360px;
  width: 360px;
  padding: 10px;
  transition: 0.3s;
  color: ${(props) => props.theme.blue100};
`;

const MainWrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

const TableRow = styled.div`
  display: flex;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  transition: 0.3s;
  border-bottom: 1px solid rgba(165, 173, 186, 0.5);

  &:hover {
    background: ${(props) => props.theme.gray_1};
  }
`;

const Main = styled.div<{ currentSlideIndex?: number }>`
  display: flex;
  flex: 1 1 auto;
  transition: 0.3s;

  ${({ currentSlideIndex }) =>
    currentSlideIndex &&
    css`
      transform: translateX(
        calc(
          (${currentSlideIndex}) * (((100vw - 490px - 17px - 40px) / 5) + 10px) *
            -1
        )
      );
    `}
`;

const Cells = styled.div`
  display: flex;
  width: 100%;
`;

const TableCell = styled.div`
  display: flex;
  width: calc(((100vw - 490px - 17px - 40px) / 5) + 10px);
  padding: 10px 10px;
`;

const CellText = styled.p<{ notText?: boolean }>`
  word-break: break-word;
  color: ${(props) => props.theme.black};

  ${({ notText }) =>
    notText &&
    css`
      color: ${colors.gray500};
    `}
`;
