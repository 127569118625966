import React from 'react';
import styled from 'styled-components';

import { ContentContainer } from '@/modules/CarModelLanding/common.styles';
import { ServiceFormSecondary } from '@/components/LandingWidgets';
import { ServiceFormSecondaryFields } from '@/components/LandingWidgets/components/ServiceFormSecondary/types';
import { submitCarModelTestDrive } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import { getRequestSource } from '@/utils/common';

function TestDriveForm() {
  const {
    formTitle,
    formSubtitle,
    formImage,
    formMobileImage,
    formButtonTitle,
    formGoogleEvent,
  } = useCarModelLandingData();

  if (!formTitle || !formImage) {
    return null;
  }

  const sendTestDriveForm = async (
    values: ServiceFormSecondaryFields
  ): Promise<void> => {
    try {
      await submitCarModelTestDrive({
        ...values,
        source: getRequestSource(),
      }).then(() => {
        if (formGoogleEvent) {
          googleEvent(formGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  return (
    <Component id="test-drive">
      <ContentContainer>
        <ServiceFormSecondary
          title={formTitle}
          subtitle={formSubtitle ?? ''}
          image={formImage}
          buttonTitle={formButtonTitle ?? ''}
          imageMobile={formMobileImage}
          onSubmit={sendTestDriveForm}
        />
      </ContentContainer>
    </Component>
  );
}

export default TestDriveForm;

const Component = styled.section`
  padding-bottom: 60px;
`;
