import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

import { PackagesTabsProps } from './types';

function PackagesTabs({ activeTab, tabs, onClick }: PackagesTabsProps) {
  if (!tabs || tabs.length === 0) {
    return null;
  }

  return (
    <Component>
      {tabs.map((tab, index) => (
        <Tab
          key={index}
          isActive={activeTab === tab.value}
          onClick={() => onClick?.(tab)}
        >
          {tab.label}
          {tab.isHit && <Hit>ХИТ</Hit>}
        </Tab>
      ))}
    </Component>
  );
}

export default PackagesTabs;

const Component = styled.div`
  display: flex;
`;

const Tab = styled.button<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 10px 35px;
  font-size: 18px;
  line-height: 21px;
  background: ${colors.gray500};
  color: ${colors.white};

  &:first-child {
    border-top-left-radius: 15px;
  }

  &:last-child {
    border-top-right-radius: 15px;
  }

  &:not(:first-child) {
    margin-left: 5px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      pointer-events: none;
      font-weight: 600;
      background: ${colors.main};
    `}
`;

const Hit = styled.div`
  padding: 1px 7px;
  font-size: 10px;
  line-height: 12px;
  border-radius: 25px;
  margin-left: 5px;
  color: ${colors.white};
  background: ${colors.orange};
`;
