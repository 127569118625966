import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { scrollToDomElement } from '@/utils/scroll';

import { MenuPanelProps } from './types';

const handleAnchorClick = (anchor: string) => {
  const element = document.getElementById(anchor);

  if (!element) {
    return;
  }

  scrollToDomElement(element);
};

export function MenuPanel({ menu }: MenuPanelProps) {
  if (!menu || menu.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        {menu.map(({ text, anchor }, index) => (
          <Anchor key={index} onClick={() => handleAnchorClick(anchor)}>
            {text}
          </Anchor>
        ))}
      </ContentContainer>
    </Component>
  );
}

const Component = styled.div`
  padding: 20px 0;
  background: ${(props) => props.theme.gray_1};
  box-shadow: ${(props) => props.theme.blackShadow100};
  overflow: hidden;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 40px;
  overflow: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Anchor = styled.button`
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  color: ${(props) => props.theme.black100_alpha50};
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }

  &:not(:first-child) {
    margin-left: 30px;
  }
`;
