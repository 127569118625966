import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/B2bHome/common.styles';
import { media } from '@/utils/mixin';
import { DealerMap } from '@/components/LandingWidgets';
import { useB2bHomeData } from '@/modules/B2bHome/hooks';

function Map() {
  const { mapImage, mapMobileImage, mapItems } = useB2bHomeData();

  if (!mapItems || mapItems.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <DealerMap
          dealers={mapItems.map(
            ({
              mapName,
              mapLegalName,
              mapAddress,
              mapServices,
              mapPhones,
              mapFacebook,
              mapVk,
              mapInstagram,
              mapTelegram,
              mapViber,
              mapSkype,
              mapOk,
              mapYoutube,
              mapLatitude,
              mapLongitude,
            }) => ({
              name: mapName ?? '',
              legalName: mapLegalName ?? '',
              address: mapAddress ?? '',
              services:
                mapServices && mapServices.length !== 0
                  ? mapServices.map(({ name }) => name ?? '')
                  : [],
              phones:
                mapPhones && mapPhones.length !== 0
                  ? mapPhones.map(({ phone }) => phone ?? '')
                  : [],
              socials: {
                facebook: mapFacebook ?? '',
                vk: mapVk ?? '',
                instagram: mapInstagram ?? '',
                telegram: mapTelegram ?? '',
                viber: mapViber ?? '',
                skype: mapSkype ?? '',
                ok: mapOk ?? '',
                youtube: mapYoutube ?? '',
              },
              latitude: Number(mapLatitude),
              longitude: Number(mapLongitude),
            })
          )}
          mapImages={{ desktop: mapImage, mobile: mapMobileImage }}
        />
      </ContentContainer>
    </Component>
  );
}

export default Map;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
