import React from 'react';
import styled, { css } from 'styled-components';

import { HowItWorksItem } from '@/modules/OnlineShopping/OnlineShopping.types';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function HowItWorksCard({ text, image }: HowItWorksItem) {
  return (
    <Component>
      <Content dangerouslySetInnerHTML={{ __html: text ?? '' }} />
      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
      />
    </Component>
  );
}

export default HowItWorksCard;

const Component = styled.div`
  display: flex;
  min-height: 300px;

  ${media.tablet(css`
    min-height: auto;
  `)}

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Content = styled.div`
  flex: 1 1 512px;
  max-width: 512px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    flex: auto;
    max-width: none;
  `)}

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    ${media.tablet(css`
      font-size: 20px;
      line-height: 30px;
    `)}
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    color: ${(props) => props.theme.blue100};

    ${media.tablet(css`
      font-size: 14px;
      line-height: 20px;
    `)}

    a {
      z-index: 10;
      color: ${colors.main};
      border-bottom: 1px solid transparent;

      &:hover {
        border-color: ${colors.main};
      }
    }
  }
`;

const Image = styled(Picture)`
  flex: 1 1 512px;
  max-width: 512px;
  margin-left: auto;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    margin: 20px auto auto;
  `)}

  img,
  picture {
    width: 100%;

    ${media.mobile(css`
      max-height: 240px;
    `)}
  }
`;
