import React, { useEffect, useState } from 'react';

export function useFixedElement(elementRef: React.RefObject<HTMLElement>) {
  const [isFixed, setFixed] = useState<boolean>(false);
  const [scrollY, setScrollY] = useState<number>(0);

  const handleSetScroll = () => {
    setScrollY(window.scrollY);
  };

  const handleChangeFixedState = () => {
    if (!elementRef.current) {
      return;
    }

    if (scrollY > elementRef.current.offsetTop) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleSetScroll);
    handleChangeFixedState();

    return () => window.addEventListener('scroll', handleSetScroll);
  }, [scrollY]);

  return isFixed;
}
