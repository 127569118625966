import React from 'react';
import styled, { css } from 'styled-components';

import { useContactsData } from '@/modules/Contacts/hooks';
import { AboutCardPrimary } from '@/components/LandingWidgets';
import {
  Image as AboutCardPrimaryImage,
  Content as AboutCardPrimaryContent,
} from '@/components/LandingWidgets/components/AboutCardPrimary';
import { ContentContainer } from '@/modules/Contacts/common.styles';
import { media } from '@/utils/mixin';

function About() {
  const { aboutImage, aboutMobileImage, aboutTitle, aboutText } =
    useContactsData();

  if (!aboutImage || !aboutTitle || !aboutText) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <AboutCardPrimary
          id={1}
          image={aboutImage}
          imageMobile={aboutMobileImage}
          title={aboutTitle}
          topText={aboutText}
        />
      </ContentContainer>
    </Component>
  );
}

export default About;

const Component = styled.section`
  padding: 60px 0 30px;

  ${media.tablet(css`
    padding: 40px 0 20px;
  `)}

  ${AboutCardPrimaryImage} {
    flex: 1 1 50%;
    max-width: 50%;
    padding-top: 37.821%;
    height: auto;

    ${media.tablet(css`
      flex: auto;
      max-width: none;
      padding-top: 55.557%;
    `)}
  }

  ${AboutCardPrimaryContent} {
    flex: 1 1 50%;
    max-width: 50%;
    padding-left: 62px;
    margin-left: 0;

    ${media.tablet(css`
      flex: auto;
      max-width: none;
      padding: 20px;
    `)}
  }
`;
