import { Nullish } from '@tager/web-core';

import { PageModuleType } from '@/typings/common';
import DefaultTemplate from '@/modules/DefaultTemplate';
import ValueCar from '@/modules/ValueCar';
import Home from '@/modules/Home';
import SpecialOffer from '@/modules/SpecialOffer';
import BonusProgram from '@/modules/BonusProgram';
import AmpLanding from '@/modules/AmpLanding/AmpLanding';
import HowTo from '@/modules/HowTo';
import PersonalCabinet from '@/modules/PersonalCabinet';
import MobileAppLanding from '@/modules/MobileAppLanding';
import OnlineShopping from '@/modules/OnlineShopping';
import Insurance from '@/modules/Insurance';
import Brands from '@/modules/Brands';
import About from '@/modules/About';
import History from '@/modules/History';
import Leadership from '@/modules/Leadership';
import Dealers from '@/modules/Dealers';
import Subscription from '@/modules/Subscription';
import Atlantm30 from '@/modules/Atlantm30';
import Eco from '@/modules/Eco';
import Services from '@/modules/Services';
import HelpOnTheRoad from '@/modules/HelpOnTheRoad';
import CarModelLanding from '@/modules/CarModelLanding';
import CarModelCompareLanding from '@/modules/CarModelCompareLanding';
import TopLevelFunnelLanding from '@/modules/TopLevelFunnelLanding';
import HisunHome from '@/modules/HisunHome';
import JacHome from '@/modules/JacHome';
import JetourHome from '@/modules/JetourHome';
import B2bHome from '@/modules/B2bHome';
import ElectrocarsLanding from '@/modules/ElectrocarsLanding';
import SubdomainHome from '@/modules/SubdomainHome';
import Contacts from '@/modules/Contacts';
import TheLineup from '@/modules/TheLineup';
import { ServiceMain } from '@/modules/Service';
import { CreditLeasing, CreditLeasingSingle } from '@/modules/CreditLeasing';
import {
  getDealersThunk,
  getRateCarDataThunk,
} from '@/store/reducers/rate-car-data';
import {
  fetchHomeBigBannersThunk,
  fetchHomeSmallBannersThunk,
} from '@/store/reducers/banners';
import {
  fetchPostsListPostsThunk,
  fetchPostsListTagsThunk,
} from '@/store/reducers/postsList';
import {
  getStockCarThunk,
  setStockCarsSuccess,
} from '@/store/reducers/special-offer';
import { fetchCatalogItemsThunk } from '@/store/reducers/catalogNew';
import {
  getServiceBrandsDataThunk,
  getServiceDealersDataThunk,
  getServicePostsDataThunk,
} from '@/store/reducers/service';
import { fetchAmpLandingDealersThunk } from '@/store/reducers/amp-landing';
import {
  getStockBrandsAndModelsThunk,
  getStockCarListThunk,
  resetAllFields,
} from '@/store/reducers/cars-stock';
import { CarsStockFlag } from '@/services/stock/enums';
import { getMenuItemListThunk } from '@/store/reducers/tager/menus';
import { BlogCategory } from '@/typings/model';
import { SelectedFiltersPayload } from '@/services/stock/typings';

const DEFAULT_PAGE_MODULE: PageModuleType = {
  template: '',
  component: DefaultTemplate,
};

const PAGE_MODULE_LIST: Array<PageModuleType> = [
  {
    template: 'home',
    component: Home,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(fetchHomeBigBannersThunk()),
        store.dispatch(fetchHomeSmallBannersThunk()),
      ]);
    },
  },
  {
    template: 'value-car',
    component: ValueCar,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(getDealersThunk()),
        store.dispatch(getRateCarDataThunk()),
      ]);
    },
  },
  {
    template: 'amp-landing',
    component: AmpLanding,
    getInitialProps({ store }) {
      return store.dispatch(fetchAmpLandingDealersThunk());
    },
  },
  {
    template: 'special-offer',
    component: SpecialOffer,
    getInitialProps({ store }, templateData) {
      const getCars = async () => {
        const stockCarsIds = templateData.templateFields?.mayLikeDataStockNew;

        store.dispatch(setStockCarsSuccess([]));

        try {
          if (stockCarsIds) {
            for (let i = 0; i < stockCarsIds.length; i++) {
              await store.dispatch(getStockCarThunk(stockCarsIds[i]));
            }
          }

          await store.dispatch(fetchCatalogItemsThunk());
        } catch (error) {
          console.log(error);
        }
      };

      return getCars();
    },
  },
  { template: 'bonus-program', component: BonusProgram },
  { template: 'how-to', component: HowTo },
  { template: 'personal-cabinet', component: PersonalCabinet },
  { template: 'mobile-app', component: MobileAppLanding },
  { template: 'online-shopping', component: OnlineShopping },
  { template: 'insurance', component: Insurance },
  { template: 'brands', component: Brands },
  { template: 'about', component: About },
  { template: 'history', component: History },
  { template: 'leadership', component: Leadership },
  {
    template: 'dealers',
    component: Dealers,
  },
  {
    template: 'subscription',
    component: Subscription,
    getInitialProps({ store }) {
      return store.dispatch(getStockBrandsAndModelsThunk('all'));
    },
  },
  { template: 'atlantm30', component: Atlantm30 },
  { template: 'eco', component: Eco },
  {
    template: 'services',
    component: Services,
    getInitialProps({ store, query }, templateData) {
      const filteredTags = async () => {
        let tags: string[] = [];
        const allTagsArray = await store
          .dispatch(fetchPostsListTagsThunk(BlogCategory.Offers))
          .then((response) => response?.map((item) => item.tag));
        tags = [
          ...templateData.templateFields?.sharesItems
            .map((item: { tag: string }) => item.tag)
            .filter((item: string) => allTagsArray?.includes(item)),
        ];
        if (tags.length) {
          return store.dispatch(
            fetchPostsListPostsThunk(BlogCategory.Offers, tags)
          );
        }
      };

      return filteredTags();
    },
  },
  { template: 'help-on-the-road', component: HelpOnTheRoad },
  {
    template: 'service-main',
    component: ServiceMain,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(getServiceDealersDataThunk()),
        store.dispatch(getServiceBrandsDataThunk()),
        store.dispatch(getServicePostsDataThunk()),
      ]);
    },
  },
  { template: 'car-model-landing', component: CarModelLanding },
  { template: 'car-model-compare-landing', component: CarModelCompareLanding },
  { template: 'credit-leasing', component: CreditLeasing },
  { template: 'credit-leasing-single', component: CreditLeasingSingle },
  {
    template: 'electrocars-landing',
    component: ElectrocarsLanding,
    getInitialProps({ store }) {
      return Promise.all([
        store.dispatch(fetchCatalogItemsThunk()),
        store.dispatch(
          getStockCarListThunk(
            'electric',
            { offset: 0, limit: 6 },
            {
              filter: {
                flags: [CarsStockFlag.PartnerCar],
              },
            }
          )
        ),
      ]);
    },
  },
  {
    template: 'subdomain-home',
    component: SubdomainHome,
    getInitialProps({ store }) {
      return store.dispatch(getMenuItemListThunk('footer_purchase'));
    },
  },
  {
    template: 'top-level-funnel-landing',
    component: TopLevelFunnelLanding,
    getInitialProps({ store }) {
      return store.dispatch(fetchCatalogItemsThunk());
    },
  },
  {
    template: 'contacts',
    component: Contacts,
  },
  { template: 'the-lineup', component: TheLineup },
  { template: 'hisun-home', component: HisunHome },
  {
    template: 'jac-home',
    component: JacHome,
    getInitialProps({ store, query }, templateData) {
      const carsLimit = templateData.templateFields
        ?.stockItemsQuantity as number;
      const stockCarsBrandIds =
        (templateData.templateFields?.stockBrands as string[]) ?? [];

      const filters: SelectedFiltersPayload = {
        filter: {
          catalog: stockCarsBrandIds.map((brand) => ({
            brand: Number(brand),
          })),
        },
      };

      store.dispatch(resetAllFields());

      if (!carsLimit || stockCarsBrandIds.length === 0) {
        return Promise.all([]);
      }

      return Promise.all([
        store.dispatch(
          getStockCarListThunk('new', { offset: 0, limit: carsLimit }, filters)
        ),
      ]);
    },
  },
  {
    template: 'jetour-home',
    component: JetourHome,
  },
  {
    template: 'b2b-home',
    component: B2bHome,
    getInitialProps({ store, query }, templateData) {
      const carsLimit = templateData.templateFields
        ?.stockItemsQuantity as number;
      const stockCarsBrandIds =
        (templateData.templateFields?.stockBrands as string[]) ?? [];
      const stockCarsDealersIds =
        (templateData.templateFields?.stockDealers as string[]) ?? [];

      const filters: SelectedFiltersPayload = {
        filter: {
          catalog: stockCarsBrandIds.map((brand) => ({
            brand: Number(brand),
          })),
          dealers: stockCarsDealersIds.map((dealer) => Number(dealer)),
        },
      };

      store.dispatch(resetAllFields());

      if (!carsLimit || stockCarsBrandIds.length === 0) {
        return Promise.all([]);
      }

      return Promise.all([
        store.dispatch(
          getStockCarListThunk('new', { offset: 0, limit: carsLimit }, filters)
        ),
      ]);
    },
  },
];

export function getPageModuleByTemplate(
  template: Nullish<string>
): PageModuleType {
  return (
    PAGE_MODULE_LIST.find((pageModule) => pageModule.template === template) ??
    DEFAULT_PAGE_MODULE
  );
}
