import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';
import useSettingItem from '@/hooks/useSettingItem';
import Picture from '@/components/Picture';

function ServicesFooter() {
  const title = useSettingItem('MOBILE_APP_BLOCK_TITLE');
  const text = useSettingItem('MOBILE_APP_BLOCK_CONTENT');
  const image = useSettingItem('MOBILE_APP_BLOCK_IMAGE');
  const apps = useSettingItem('MOBILE_APP_BLOCK_STORES');

  return (
    <Component>
      <Container>
        <MainImage>
          <Picture
            loading="lazy"
            src={image?.url}
            src2x={image?.url_2x}
            srcWebp={image?.url_webp}
            srcWebp2x={image?.url_webp_2x}
          />
        </MainImage>
        <Content>
          <Title>{title ?? ''}</Title>
          <ContentText dangerouslySetInnerHTML={{ __html: text ?? '' }} />
          <ButtonWrapper>
            {apps &&
              apps.length !== 0 &&
              apps.map((item, index) => {
                return (
                  <ImageWrapper key={index} to={item.url}>
                    <StyledPicture
                      loading="lazy"
                      src={item.icon?.url}
                      src2x={item.icon?.url_2x}
                      srcWebp={item.icon?.url_webp}
                      srcWebp2x={item.icon?.url_webp_2x}
                    />
                  </ImageWrapper>
                );
              })}
          </ButtonWrapper>
        </Content>
      </Container>
    </Component>
  );
}

export default ServicesFooter;

const Component = styled.div`
  margin-top: 50px;
  background: ${colors.black100};
`;

const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;

  ${media.mobile(css`
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  `)}
`;

const MainImage = styled.div`
  flex: 0 0 40%;
  margin-top: -50px;

  img {
    width: 350px;
    margin-left: auto;
  }

  ${media.mobile(css`
    order: 2;
    width: 80%;
    padding: 0;
    margin-top: -40px;

    img {
      width: 100%;
      margin: 0;
    }
  `)}
`;

const Content = styled.div`
  flex: 0 0 60%;
  padding-left: 50px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.mobile(css`
    order: 1;
    padding: 20px 0 0;
  `)}
`;

const Title = styled.div`
  color: ${colors.white};
  font-size: 28px;
  font-weight: 500;
`;

const ContentText = styled.div`
  padding-left: 15px;
  margin-top: 10px;

  li {
    color: ${colors.white};
    list-style-type: disc;
    font-size: 16px;
    line-height: 24px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;

  ${media.mobile(css`
    flex-direction: column;
    align-items: center;
  `)}
`;

const ImageWrapper = styled(Link)`
  &:not(:last-child) {
    margin: 0 10px 0 0;
  }

  ${media.mobile(css`
    &:not(:last-child) {
      margin: 0 0 10px 0;
    }
  `)}
`;

const StyledPicture = styled(Picture)`
  img,
  picture {
    height: 47px;
    width: 150px;
  }
`;
