import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { useLeadershipData } from '@/modules/Leadership/Leadership.hooks';

import FirstPersonsCard from './components/FirstPersonsCard';

function FirstPersons() {
  const { firstPersonsTitle, firstPersonsItems } = useLeadershipData();

  return (
    <Component>
      <ContentContainerSmall>
        <Title>{firstPersonsTitle}</Title>
        <Cards>
          {firstPersonsItems &&
            firstPersonsItems.map(
              ({ avatar, name, position, quote, quoteAuthor }, index) => (
                <FirstPersonsCard
                  key={index}
                  avatar={avatar}
                  name={name}
                  position={position}
                  quote={quote}
                  quoteAuthor={quoteAuthor}
                />
              )
            )}
        </Cards>
      </ContentContainerSmall>
    </Component>
  );
}

export default FirstPersons;

const Component = styled.section`
  padding: 90px 0;

  ${media.tablet(css`
    padding: 60px 0;
  `)}

  ${media.mobile(css`
    padding: 40px 0;
  `)}
  
  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}

    ${media.mobile(css`
      padding: 0;
    `)}
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const Cards = styled.div`
  margin-top: 50px;

  ${media.tablet(css`
    margin-top: 30px;
  `)}
`;
