import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

import { useMedia } from '@tager/web-core';

import { useTypedSelector } from '@/store/store';
import { selectStockCars } from '@/store/reducers/special-offer';
import ArrowIcon from '@/assets/svg/arrow-slider.svg';
import LandingCarCard from '@/components/LandingCarCard';
import { switchNamesItems } from '@/modules/DetailedCarModule/DetailedCar.helper';
import { getCatalogItems } from '@/store/reducers/catalogNew';
import { useSpecialOfferData } from '@/modules/SpecialOffer/SpecialOffer.hooks';
import defaultStockCar from '@/assets/images/defaultStockCar.png';
import { getCatalogCarsByIds } from '@/utils/common';

SwiperCore.use([Navigation]);

function CarsSlider() {
  const { mayLikeDataCatalog, mayLikeDataStockNew } = useSpecialOfferData();
  const stockCars = useTypedSelector(selectStockCars);
  const catalogCars = getCatalogCarsByIds(
    useTypedSelector(getCatalogItems).catalogItemsData,
    mayLikeDataCatalog ?? []
  );
  const isMaxWidth500px = useMedia(`(max-width: 500px)`);
  const isMaxWidth650px = useMedia(`(max-width: 650px)`);
  const isMaxWidth1200px = useMedia(`(max-width: 1200px)`);
  const isTablet = useMedia('(max-width: 1259px)');

  if (
    (!stockCars || stockCars.length === 0) &&
    (!catalogCars || catalogCars.length === 0)
  ) {
    return null;
  }

  return (
    <Component>
      <SliderContent isMaxWidth1200px={isMaxWidth1200px}>
        <SliderWrapper>
          <StyledSwiper
            slidesPerView={
              (isMaxWidth500px && 1) || (isMaxWidth650px && 2) || 3
            }
            loop={true}
            spaceBetween={20}
            isMaxWidth500px={isMaxWidth500px}
            navigation={{
              prevEl: `.swiper-prev`,
              nextEl: `.swiper-next`,
            }}
          >
            {stockCars.map((car, index) => {
              const title = `${car.brand.name} ${car.model.name}`;
              const link = `/cars/new/${[mayLikeDataStockNew ?? []][index]}`;
              const images =
                car.images.length !== 0
                  ? car.images
                      .slice(0, 5)
                      .map(
                        (image) =>
                          image.gallery?.url_webp ?? image.gallery?.url ?? ''
                      )
                  : [defaultStockCar];

              return (
                <SwiperSlide key={index}>
                  <LandingCarCard
                    images={images}
                    title={title}
                    subtitle={
                      car.params.bodyType
                        ? switchNamesItems(car.params.bodyType)
                        : ''
                    }
                    price={car.price ?? 0}
                    link={link}
                    isMobile={isTablet}
                  />
                </SwiperSlide>
              );
            })}

            {catalogCars.map((car, index) => {
              const title = `${car.brand.name} ${car.model.name}`;
              const link = `/cars/new/${car.brand.alias}/${car.model.alias}`;
              const images =
                car.colors && car.colors.length > 0
                  ? [
                      ...car.colors
                        .slice(0, 5)
                        .map(({ images }) =>
                          images && images.length > 0
                            ? images
                                .slice(0, 5)
                                .map(
                                  (image) =>
                                    image.desktop?.url_webp ??
                                    image.desktop?.url ??
                                    defaultStockCar
                                )
                            : [defaultStockCar]
                        )
                        .flat(1),
                    ].slice(0, 5)
                  : car.images && car.images.length > 0
                  ? car.images
                      .slice(0, 5)
                      .map(
                        (image) =>
                          image.desktop?.url_webp ??
                          image.desktop?.url ??
                          defaultStockCar
                      )
                  : [defaultStockCar];

              return (
                <SwiperSlide key={index}>
                  <LandingCarCard
                    images={images}
                    title={title}
                    subtitle={car.body?.name ?? ''}
                    price={car.stock.minPrice ?? 0}
                    pricePrefix="от"
                    link={link}
                    isMobile={isTablet}
                  />
                </SwiperSlide>
              );
            })}
          </StyledSwiper>
        </SliderWrapper>

        <StyledNavButtonWrapper
          className="swiper-prev"
          isMaxWidth1200px={isMaxWidth1200px}
          onClick={(event) => event.stopPropagation()}
        >
          <PrevNavButton>
            <ArrowIcon />
          </PrevNavButton>
        </StyledNavButtonWrapper>
        <StyledNavButtonWrapper
          className="swiper-next"
          isMaxWidth1200px={isMaxWidth1200px}
          onClick={(event) => event.stopPropagation()}
        >
          <NextNavButton>
            <ArrowIcon />
          </NextNavButton>
        </StyledNavButtonWrapper>
      </SliderContent>
    </Component>
  );
}

export default CarsSlider;

const Component = styled.div`
  margin-top: 40px;
`;

const StyledSwiper = styled(Swiper)<{ isMaxWidth500px: boolean }>`
  height: 100%;
  padding: 2px;
  overflow: ${(props) => (props.isMaxWidth500px ? 'visible' : '')};
`;

const SliderContent = styled.div<{ isMaxWidth1200px: boolean }>`
  position: relative;
  margin: 40px -20px 0;
  overflow: ${(props) => (props.isMaxWidth1200px ? 'hidden' : '')};
`;

const StyledNavButtonWrapper = styled.div<{ isMaxWidth1200px: boolean }>`
  background: none !important;
  display: ${(props) => (props.isMaxWidth1200px ? 'none' : '')};
`;

const PrevNavButton = styled.button`
  position: absolute;
  right: -70px;
  top: calc(100% - 50%);

  svg {
    path {
      fill: ${(props) => props.theme.black};
    }
  }

  @media (max-width: 1420px) {
    right: 40px;
  }
`;

const NextNavButton = styled(PrevNavButton)`
  transform: rotate(180deg);
  right: initial;
  left: -70px;

  @media (max-width: 1420px) {
    left: 40px;
  }
`;

const SliderWrapper = styled.div`
  width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;

  .swiper-slide {
    height: auto;
  }

  @media (max-width: 1420px) {
    max-width: 1000px;
  }

  @media (max-width: 1000px) {
    max-width: 100%;
  }

  @media (max-width: 780px) {
    max-width: 100%;
  }

  @media (max-width: 750px) {
    max-width: 100%;
  }

  @media (max-width: 650px) {
    max-width: 100%;
  }

  @media (max-width: 500px) {
    max-width: 80vw;
    overflow: visible;
  }
`;
