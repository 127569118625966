import React from 'react';
import styled from 'styled-components';

import Banners from '@/modules/Services/components/Banners';
import ServicesList from '@/modules/Services/components/ServicesList';
import Consultation from '@/modules/Services/components/Consultation';
import SharesSlider from '@/modules/Services/components/SharesSlider';
import Bonus from '@/modules/Services/components/Bonus';
import Dealers from '@/modules/Services/components/Dealers';
import ServicesFooter from '@/modules/Services/components/ServicesFooter/ServicesFooter';
import Advantages from '@/modules/Services/components/Advantages';
import { useServicesData } from '@/modules/Services/Services.hooks';
import { ServiceProvider } from '@/modules/Services/ServiceProvider';
import Layout from '@/layout';

function Services() {
  const data = useServicesData();

  if (!data) {
    return null;
  }

  return (
    <Layout>
      <ServiceProvider>
        <Component>
          <Banners />
          <Consultation />
          <ServicesList />
          <SharesSlider />
          <Advantages />
          <Bonus />
          <Dealers />
          <ServicesFooter />
        </Component>
      </ServiceProvider>
    </Layout>
  );
}

export default Services;

const Component = styled.div`
  background: ${(props) => props.theme.white};
`;
