import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { BenefitProps } from './types';

function Benefit({ image, text }: BenefitProps) {
  return (
    <Component>
      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
      />
      <Text>{text}</Text>
    </Component>
  );
}

export default Benefit;

const Component = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled(Picture)`
  flex: 0 0 40px;
  width: 40px;
  height: 40px;

  picture,
  img {
    width: 100%;
    height: 100%;
  }
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-left: 10px;
  color: ${colors.blue600};

  ${media.tablet(css`
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.theme.blue100};
  `)}
`;
