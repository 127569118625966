import React from 'react';
import styled, { css } from 'styled-components';

import { useTypedSelector } from '@/store/store';
import {
  selectHomeBigBanners,
  selectHomeSmallBanners,
} from '@/store/reducers/banners';
import { media } from '@/utils/mixin';
import BannersCarousel from '@/components/BannersCarousel';

function Banners() {
  const homeSliderBig = useTypedSelector(selectHomeBigBanners);
  const homeSliderSmall = useTypedSelector(selectHomeSmallBanners);

  if (!homeSliderBig || !homeSliderSmall || homeSliderBig.data.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Content>
          <Big>
            <BannersCarousel
              banners={homeSliderBig.data.map(
                ({ link, openNewTab, image, fields }) => ({
                  link: link ?? '',
                  openNewTab,
                  image: {
                    desktop: image?.desktop,
                    laptop: image?.laptop,
                    mobile: fields[0]?.value,
                  },
                })
              )}
              index={1}
            />
          </Big>

          {homeSliderSmall.data.length > 0 && (
            <Small>
              <BannersCarousel
                banners={homeSliderSmall.data.map(
                  ({ link, openNewTab, image }) => ({
                    link: link ?? '',
                    openNewTab,
                    image: {
                      desktop: image?.desktop,
                      laptop: image?.laptop,
                    },
                  })
                )}
                small
                index={2}
              />
            </Small>
          )}
        </Content>
      </ContentContainer>
    </Component>
  );
}

export default Banners;

const Component = styled.section`
  background: ${(props) => props.theme.gray};

  ${media.tablet(css`
    background: ${(props) => props.theme.white};
  `)}
`;

const ContentContainer = styled.div`
  max-width: 1900px;
  width: 100%;
  margin: 0 auto;
  padding: 30px;

  ${media.tablet(css`
    padding: 30px 20px;
  `)}

  ${media.mobile(css`
    padding: 20px;
  `)}
`;

const Content = styled.div`
  display: flex;
`;

const Big = styled.div`
  width: calc(100% - 17.389% - 20px);

  ${media.tablet(css`
    width: 100%;
  `)}
`;

const Small = styled.div`
  width: calc(17.392%);
  margin-left: 20px;

  ${media.tablet(css`
    display: none;
  `)}

  & > div {
    width: 100%;
    height: 100%;
    padding-top: 0;
  }
`;
