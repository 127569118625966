import React from 'react';
import styled, { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { useMedia } from '@tager/web-core';
import { useModal } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import {
  ContentContainer,
  Title,
} from '@/modules/ElectrocarsLanding/common.styles';
import { useElectrocarsLandingData } from '@/modules/ElectrocarsLanding/hooks';
import { useTypedSelector } from '@/store/store';
import StockCarCard from '@/components/StockCarCard';
import {
  SliderWrapper,
  Component as SliderWrapperComponent,
} from '@/components/LandingWidgets/components/SliderWrapper';
import {
  getCarImageFit,
  getCarImages,
  getCarLink,
  getCarPrice,
  getCarStatus,
  getCarTags,
  handleOpenApplicationModal,
} from '@/modules/CarsStock/components/common.helpers';
import useSettingItem from '@/hooks/useSettingItem';
import { ButtonNextLink } from '@/components/Button';
import { getStockCarList } from '@/store/reducers/cars-stock';
import { usePhoneNumber } from '@/hooks/usePhoneNumber';

function StockCars() {
  const openModal = useModal();
  const {
    availableOrderTitle,
    availableOrderButtonTitle,
    availableOrderButtonLink,
  } = useElectrocarsLandingData();
  const stockCars = useTypedSelector(getStockCarList);
  const stockNewApplicationGoogleEvent = useSettingItem(
    'STOCK_NEW_APPLICATION_GOOGLE_EVENT'
  );
  const stockAmpApplicationGoogleEvent = useSettingItem(
    'STOCK_AMP_APPLICATION_GOOGLE_EVENT'
  );
  const isMobile = useMedia('(max-width: 767.9px)');
  const phone = usePhoneNumber();

  if (!stockCars || !stockCars.items || stockCars.items.length === 0) {
    return null;
  }

  const isNoSwiping = stockCars.items.length === 1;

  return (
    <Component id="availableOrder">
      <ContentContainer>
        <Title>{availableOrderTitle}</Title>

        <List>
          {!isMobile ? (
            stockCars.items.map((car, index) => (
              <StockCarCard
                key={index}
                view="portrait"
                images={getCarImages(car)}
                imageFit={getCarImageFit(car)}
                name={car.name}
                subName={car.complectation ?? ''}
                price={getCarPrice(car, phone)}
                tags={getCarTags(car)}
                carStatus={getCarStatus(car)}
                stockStatus={car.stockStatus ?? undefined}
                totalImagesCount={car.imagesCount}
                buttonHandle={() =>
                  handleOpenApplicationModal(
                    car,
                    openModal,
                    undefined,
                    (car.type === 'new'
                      ? stockNewApplicationGoogleEvent
                      : stockAmpApplicationGoogleEvent) ?? ''
                  )
                }
                link={getCarLink(car.type, car.id)}
                isLaptop
              />
            ))
          ) : (
            <SliderWrapper
              id={3}
              options={{
                spaceBetween: 20,
                noSwipingClass: 'swiper-no-swiping',
                withoutNavs: true,
                withoutPagination: true,
              }}
            >
              {stockCars.items.map((car, index) => (
                <SwiperSlide
                  key={index}
                  className={isNoSwiping ? 'swiper-no-swiping' : ''}
                >
                  <StockCarCard
                    view="portrait"
                    images={getCarImages(car)}
                    imageFit={getCarImageFit(car)}
                    name={car.name}
                    subName={car.complectation ?? ''}
                    price={getCarPrice(car, phone)}
                    tags={getCarTags(car)}
                    carStatus={getCarStatus(car)}
                    stockStatus={car.stockStatus ?? undefined}
                    totalImagesCount={car.imagesCount}
                    buttonHandle={() =>
                      handleOpenApplicationModal(
                        car,
                        openModal,
                        undefined,
                        (car.type === 'new'
                          ? stockNewApplicationGoogleEvent
                          : stockAmpApplicationGoogleEvent) ?? ''
                      )
                    }
                    link={getCarLink(car.type, car.id)}
                    isMobile
                  />
                </SwiperSlide>
              ))}
            </SliderWrapper>
          )}
        </List>

        <Action>
          <StyledButtonLink variant="outlined" to={availableOrderButtonLink}>
            {availableOrderButtonTitle}
          </StyledButtonLink>
        </Action>
      </ContentContainer>
    </Component>
  );
}

export default StockCars;

const Component = styled.section`
  padding: 30px 0;
  background: ${colors.purpleGradient100};

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 58px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
    margin-top: 30px;
  `)}

  ${media.mobile(css`
    display: block;
    margin: 30px -20px 0 0;

    ${SliderWrapperComponent} {
      & > .swiper-container {
        & > .swiper-wrapper {
          & > .swiper-slide {
            min-width: calc(100% - 40px);
            flex-shrink: initial;
          }
        }
      }
    }
  `)}
  
  .swiper-slide {
    height: auto;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 58px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}
`;

const StyledButtonLink = styled(ButtonNextLink)`
  max-width: 226px;

  ${media.tablet(css`
    max-width: 280px;
  `)}
`;
