import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

import TimeIcon from '@/assets/svg/time.svg';
import { useTypedSelector } from '@/store/store';
import { selectOfflineValuationUserChoice } from '@/store/reducers/offline-valuation-user-data';
import { OfflineCheckedValuesModel } from '@/modules/ValueCar/components/OfflineValuation/components/ContactFormOfflineValuation/interfaces';
import TextInput from '@/components/TextInput';

import TimePicker from './component/TimePicker';

interface Props {
  timeRef: MutableRefObject<HTMLDivElement | null>;
  checkedValues?: OfflineCheckedValuesModel;
  setCheckedValues?: Dispatch<SetStateAction<OfflineCheckedValuesModel>>;
  isChecked?: boolean;
  isShowTimePicker: boolean;
  setIsShowTimePicker: Dispatch<SetStateAction<boolean>>;
}

function TimeComponent({
  timeRef,
  setCheckedValues,
  checkedValues,
  isChecked,
  isShowTimePicker,
  setIsShowTimePicker,
}: Props) {
  const chosenData = useTypedSelector(selectOfflineValuationUserChoice);
  const [isShowEmptyMessage, setIsShowEmptyMessage] = useState(false);

  return (
    <Component ref={timeRef} isChecked={isChecked}>
      <TextInput
        label="Время"
        value={chosenData.time}
        badge={<TimeIcon />}
        onClick={() => {
          setIsShowEmptyMessage(false);
          setIsShowTimePicker(!isShowTimePicker);
        }}
      />
      {isShowTimePicker && (
        <TimePicker
          chosenData={chosenData}
          setIsShowTimePicker={setIsShowTimePicker}
          onClick={() => setIsShowTimePicker(false)}
          onClose={() => setIsShowTimePicker(false)}
          isShowEmptyMessage={isShowEmptyMessage}
          setIsShowEmptyMessage={setIsShowEmptyMessage}
          setCheckedValues={setCheckedValues}
          checkedValues={checkedValues}
        />
      )}
    </Component>
  );
}

export default TimeComponent;

const Component = styled.div<{ isChecked?: boolean }>`
  width: 384px;
  position: relative;

  ${({ isChecked }) =>
    !isChecked &&
    css`
      input {
        border: 1px solid #ee8f8f;
        background: #fff4f4;
      }
    `};

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 35px;
  }
`;
