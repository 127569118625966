import React from 'react';
import styled, { css } from 'styled-components';

import { HowToQuestionsAndAnswersItem } from '@/modules/HowTo/HowTo.types';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function QuestionAndAnswer({
  id,
  question,
  answer,
}: HowToQuestionsAndAnswersItem) {
  return (
    <Component id={id ?? ''}>
      <Question>{question}</Question>
      <Answer dangerouslySetInnerHTML={{ __html: answer ?? '' }} />
    </Component>
  );
}

export default QuestionAndAnswer;

const Component = styled.div`
  padding-top: 20px;
  border-top: 1px solid ${colors.gray400};
`;

const Question = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const Answer = styled.div`
  color: ${(props) => props.theme.black};

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.black200};

    ${media.tablet(css`
      font-size: 14px;
      line-height: 20px;
    `)}

    a {
      color: ${colors.main};
      border-bottom: 1px solid transparent;

      &:hover {
        border-color: ${colors.main};
      }
    }
  }

  h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    ${media.tablet(css`
      font-size: 14px;
      line-height: 20px;
    `)}
  }

  ul,
  ol {
    margin: 30px 0 20px 30px;

    ${media.tablet(css`
      margin: 20px 0 20px 20px;
    `)}

    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: ${(props) => props.theme.black200};
      list-style-type: square;
      padding-left: 5px;

      ${media.tablet(css`
        font-size: 14px;
        line-height: 20px;
      `)}

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
`;
