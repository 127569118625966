import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { useAmpLandingData } from '@/modules/AmpLanding/AmpLanding.hooks';

function Hero() {
  const { heroImage } = useAmpLandingData();

  return (
    <Component>
      <StyledPicture
        loading="lazy"
        src={heroImage?.url}
        src2x={heroImage?.url_2x}
        srcWebp={heroImage?.url_webp}
        srcWebp2x={heroImage?.url_webp_2x}
      />
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  padding-top: 33.26%;

  ${media.mobile(css`
    padding-top: 66.26%;
  `)}
`;

const StyledPicture = styled(Picture)`
  position: static !important;

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    right: 0;
    object-fit: cover;
  }
`;

export default Hero;
