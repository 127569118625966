import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { DisplayIcon } from '@/components/Collapse/Collapse';
import ArrowIcon from '@/assets/svg/arrow-down.svg';
import { colors } from '@/constants/theme';
import useCollapse from '@/components/Collapse/hooks/useCollapse';
import { media } from '@/utils/mixin';

interface Props {
  question: Nullable<string>;
  children: React.ReactNode;
}

function CollapsedQuestion({ question, children }: Props) {
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <Component isExpanded={isExpanded}>
      <Display
        isExpanded={isExpanded}
        {...getToggleProps({
          onClick: () => setExpanded(!isExpanded),
        })}
      >
        <Title>{question}</Title>
        <DisplayIcon>
          <ArrowIcon />
        </DisplayIcon>
      </Display>
      <Content {...getCollapseProps()}>
        <ContentContainer>{children}</ContentContainer>
      </Content>
    </Component>
  );
}

export default CollapsedQuestion;

const Component = styled.div<{ isExpanded?: boolean }>`
  width: 100%;
  position: relative;
  padding-bottom: 3px;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      margin-bottom: 25px;
      box-shadow: ${(props) => props.theme.blackShadow100};
      padding-bottom: 0;
    `}
`;

const Content = styled.div`
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 24px 20px 20px;
  text-align: left;
  background: ${(props) => props.theme.white};

  ${media.mobile(css`
    padding: 15px;
  `)}
`;

const Display = styled.div<{
  isExpanded?: boolean;
}>`
  position: relative;
  display: flex;
  text-align: left;
  padding: 0 24px 0 20px;
  cursor: pointer;
  background: ${(props) => props.theme.white_1};

  svg {
    fill: ${colors.main};
    transition: 0.3s;

    path {
      fill: ${colors.main};
    }
  }

  ${media.mobile(css`
    padding: 0 15px;
  `)}

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      background: ${(props) => props.theme.gray_1};

      svg {
        transform: rotate(180deg);
      }
    `}
`;

const Title = styled.span`
  width: 100%;
  font-size: 22px;
  line-height: 30px;
  padding: 15px 0;
  color: ${(props) => props.theme.blue200};

  ${media.mobile(css`
    font-size: 18px;
    line-height: 24px;
    padding: 15px 15px 15px 0;
  `)}
`;
