import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import Button from '@/components/Button';

import { BannerProps } from './types';
import Benefit from './components/Benefit';

function Banner({
  image,
  imageMobile,
  title,
  benefits,
  buttonText,
  onClick,
}: BannerProps) {
  return (
    <Component>
      <Image
        loading="lazy"
        desktop={convertThumbnailToPictureImage(image)}
        laptop={convertThumbnailToPictureImage(image)}
        tabletSmall={convertThumbnailToPictureImage(imageMobile ?? image)}
        mobile={convertThumbnailToPictureImage(imageMobile ?? image)}
      />

      <Content>
        <ContentContainer>
          <Main>
            <Title>{title}</Title>
            <BenefitsList>
              {benefits.map((benefit, index) => (
                <BenefitItem key={index}>
                  <Benefit {...benefit} />
                </BenefitItem>
              ))}
            </BenefitsList>
            <ButtonWrapper>
              <Button variant="contained" onClick={onClick}>
                {buttonText}
              </Button>
            </ButtonWrapper>
          </Main>
        </ContentContainer>
      </Content>
    </Component>
  );
}

export default Banner;

const Component = styled.div`
  position: relative;
  padding-top: 21.74%;

  ${media.tablet(css`
    padding-top: 0;
  `)}
`;

const Image = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  ${media.tablet(css`
    position: relative;
    padding-top: 40%;
  `)}

  ${media.mobile(css`
    padding-top: 69.446%;
  `)}

  picture,
  img {
    width: 100%;
    height: 100%;

    ${media.tablet(css`
      position: absolute;
      inset: 0;
    `)}
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);

  ${media.tablet(css`
    position: relative;
    transform: none;
    padding: 20px 15px 0;
  `)}
`;

const ContentContainer = styled.div`
  max-width: 1610px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0;
  `)}
`;

const Main = styled.div`
  max-width: 50%;

  ${media.tablet(css`
    max-width: none;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;

  ${media.tablet(css`
    font-size: 30px;
    line-height: 35px;
    color: ${(props) => props.theme.black};
  `)}
`;

const BenefitsList = styled.div`
  display: flex;
  margin-top: 20px;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const BenefitItem = styled.div`
  &:not(:first-child) {
    margin-left: 40px;

    ${media.mobile(css`
      margin-left: 0;
      margin-top: 10px;
    `)}
  }
`;

const ButtonWrapper = styled.div`
  max-width: 240px;
  margin-top: 40px;

  ${media.tablet(css`
    margin-top: 30px;
  `)}

  ${media.mobile(css`
    max-width: none;
  `)}
`;
