import React, { useEffect } from 'react';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  selectSellCarLoading,
  selectSellCarView,
  setSellCarView,
} from '@/store/reducers/sell-car';
import { scrollTop } from '@/utils/scroll';
import PageLoader from '@/components/PageLoader';
import Layout from '@/layout';

import ValueCarHome from './components/ValueCarHome';
import OnlineValuation from './components/OnlineValuation';
import OfflineValuation from './components/OfflineValuation';

const ValueCar = () => {
  const dispatch = useTypedDispatch();

  const view = useTypedSelector(selectSellCarView);
  const loading = useTypedSelector(selectSellCarLoading);

  useEffect(() => {
    dispatch(setSellCarView('HOME'));
  }, []);

  useEffect(() => {
    scrollTop();
  }, [view]);

  return (
    <Layout>
      <PageLoader enabled={loading} />
      {view === 'HOME' ? (
        <ValueCarHome />
      ) : view === 'ONLINE' ? (
        <OnlineValuation />
      ) : (
        <OfflineValuation />
      )}
    </Layout>
  );
};

export default ValueCar;
