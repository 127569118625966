import React from 'react';
import styled from 'styled-components';

import { AllInclusiveItem } from '@/modules/Subscription/Subscription.types';
import Picture from '@/components/Picture';

function AllInclusiveCard({ image, text }: AllInclusiveItem) {
  return (
    <Component>
      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
      />
      <Text>{text}</Text>
    </Component>
  );
}

export default AllInclusiveCard;

const Component = styled.div`
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled(Picture)`
  width: 96px;
  height: 96px;
  border-radius: 50%;

  picture,
  img {
    width: 100%;
    height: 100%;
  }
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  max-width: 275px;
  margin-top: 30px;
  color: ${(props) => props.theme.blue100};
`;
