import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import HeroSlider from './components/HeroSlider';
import SloganText from './components/SloganText';
import Atvs from './components/Atvs';
import Buggy from './components/Buggy';
import About from './components/About';
import Guarantee from './components/Guarantee';
import Benefits from './components/Benefits';
import Videos from './components/Videos';
import Credit from './components/Credit';
import ServiceForm from './components/ServiceForm';
import Map from './components/Map';
import Departments from './components/Departments';

function HisunHome() {
  return (
    <Layout>
      <Component>
        <HeroSlider />
        <SloganText />
        <Atvs />
        <Buggy />
        <About />
        <Guarantee />
        <Benefits />
        <Videos />
        <Credit />
        <ServiceForm />
        <Map />
        <Departments />
      </Component>
    </Layout>
  );
}

export default HisunHome;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
