import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { useTypedDispatch } from '@/store/store';
import { setOfflineChosenTime } from '@/store/reducers/offline-valuation-user-data';
import { OfflineValuationUserChoiceModel } from '@/modules/ValueCar/ValueCar.types';
import { OfflineCheckedValuesModel } from '@/modules/ValueCar/components/OfflineValuation/components/ContactFormOfflineValuation/interfaces';

import { MOCK_TIME_SEE_AUTO } from './helpers';

interface Props {
  chosenData: OfflineValuationUserChoiceModel;
  setIsShowTimePicker: Dispatch<SetStateAction<boolean>>;
  onClick: (value: string) => void;
  onClose: () => void;
  isShowEmptyMessage: boolean;
  setIsShowEmptyMessage: Dispatch<SetStateAction<boolean>>;
  checkedValues?: OfflineCheckedValuesModel;
  setCheckedValues?: Dispatch<SetStateAction<OfflineCheckedValuesModel>>;
}

function TimePicker({
  setIsShowTimePicker,
  onClose,
  chosenData,
  isShowEmptyMessage,
  setIsShowEmptyMessage,
  checkedValues,
  setCheckedValues,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState(0);
  const dispatch = useTypedDispatch();

  function filterDate(chosenData: OfflineValuationUserChoiceModel) {
    const date = new Date().getDate();
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();

    if (date !== Number(chosenData.date.split('.')[0])) {
      return MOCK_TIME_SEE_AUTO;
    }

    const filteredArr = MOCK_TIME_SEE_AUTO.filter(
      (item) => Number(item.split(':')[0]) >= hours + 2
    );

    if (minutes <= 30 && filteredArr.length >= 1) {
      filteredArr.shift();
    } else {
      if (filteredArr.length >= 2) {
        filteredArr.shift();
        filteredArr.shift();
      } else if (filteredArr.length === 1) {
        filteredArr.shift();
      }
    }

    if (filteredArr.length === 0) {
      setIsShowEmptyMessage(true);

      return filteredArr;
    }

    return filteredArr;
  }

  useLayoutEffect(() => {
    const timeElement = ref.current;

    if (timeElement) {
      const coordinate = timeElement.getBoundingClientRect();
      const currentHeight = coordinate.height;

      setPosition(-currentHeight);
    }
  }, []);

  useEffect(() => {
    function handleClickByOutSide(event: MouseEvent) {
      if (ref.current && event.target instanceof Element) {
        if (event.target.contains(ref.current)) {
          onClose();
        }
      }
    }

    document.addEventListener('click', handleClickByOutSide);
    return () => document.removeEventListener('click', handleClickByOutSide);
  }, []);

  function handleClick(timeItem: string) {
    dispatch(setOfflineChosenTime(timeItem));
    setIsShowTimePicker(false);
    setIsShowEmptyMessage(false);
    if (setCheckedValues && checkedValues) {
      setCheckedValues({ ...checkedValues, timeCheck: true });
    }
  }

  return (
    <Component ref={ref} position={position}>
      <TimeList>
        {isShowEmptyMessage && (
          <Span>{'На выбранную дату нету свободного времени'}</Span>
        )}

        {!chosenData.date && <Span>{'Сначала выберите дату'}</Span>}

        {chosenData.date &&
          filterDate(chosenData).map((timeItem, index) => (
            <TimeItem
              key={index}
              isActive={chosenData.time === timeItem}
              onClick={() => handleClick(timeItem)}
            >
              {timeItem}
            </TimeItem>
          ))}
      </TimeList>
    </Component>
  );
}

const Component = styled.div<{ position: number }>`
  width: 100%;
  height: auto !important;
  position: absolute;

  ${(props) =>
    !!props.position
      ? css`
          top: ${props.position}px;
        `
      : null}

  left: 0;
  right: -0;
  padding: 12px 9px;
  background: ${(props) => props.theme.white_1};
  border: 1px solid ${(props) => props.theme.black100_alpha15_1};
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 5px;
  z-index: 2000;
`;

const TimeItem = styled.span<{ isActive: boolean }>`
  display: block;
  font-size: 13px;
  margin: 3px;
  line-height: 24px;
  padding: 2px 10px;
  border: 1px solid ${(props) => props.theme.black100_alpha15};
  border-radius: 4px;
  cursor: pointer;
  color: ${(props) => props.theme.black};

  ${(props) =>
    props.isActive
      ? css`
          background: ${colors.main};
          color: ${colors.white};
          border-color: ${colors.main};
        `
      : null}
`;

const TimeList = styled.div`
  display: flex;

  flex-wrap: wrap;
`;

const Span = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.blue100};
`;

export default TimePicker;
