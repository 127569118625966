import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import { Tabs, Title } from '@/components/CarModelLandingWidgets';
import { media } from '@/utils/mixin';
import { TabsOnChangeConfig } from '@/components/CarModelLandingWidgets/Tabs/types';
import ImagesSlider from '@/components/ImagesSlider';

function Gallery() {
  const openModal = useModal();
  const { galleryTitle, galleryImages } = useCarModelLandingData();

  const tabsData =
    galleryImages && galleryImages.length !== 0
      ? galleryImages.map((image) => ({
          label: image.section ?? '',
          value: image.section ?? '',
        }))
      : [];
  const [activeTab, setActiveTab] = useState<TabsOnChangeConfig>({
    activeIndex: 0,
    activeValue: tabsData[0]?.value ?? '',
  });

  if (!galleryImages || galleryImages.length === 0) {
    return null;
  }

  const slides = galleryImages[activeTab.activeIndex].images.map((image) => ({
    image,
  }));

  return (
    <Component id="gallery">
      <TitleWrapper>
        <Title title={galleryTitle ?? ''} />
      </TitleWrapper>

      <TabsWrapper>
        <Tabs
          data={tabsData}
          activeValue={activeTab.activeValue}
          onChange={setActiveTab}
        />
      </TabsWrapper>

      <SliderWrapper>
        <ImagesSlider
          slides={slides}
          config={{ withModalSlides: true, openModal }}
        />
      </SliderWrapper>
    </Component>
  );
}

export default Gallery;

const Component = styled.section`
  padding-bottom: 60px;
`;

const TitleWrapper = styled.div`
  padding: 0 20px;
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`;

const SliderWrapper = styled.div`
  margin-top: 30px;
`;
