import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import ArrowIcon from '@/assets/svg/arrow-down.svg';

import { CollapseTableProps } from './types';

export function CollapseTable({ title, collapse }: CollapseTableProps) {
  const [isOpened, setOpened] = useState(true);

  const handleChangeOpenedState = () => {
    setOpened(!isOpened);
  };

  if (!collapse || collapse.length === 0) {
    return null;
  }

  return (
    <Component>
      <Header onClick={handleChangeOpenedState}>
        <Title>
          {title} <StyledArrowIcon isOpened={isOpened} />
        </Title>
      </Header>
      {isOpened && collapse.length > 0 && (
        <Table>
          {collapse.map(({ name, cells }, index) => (
            <TableRow key={index}>
              <TableTitle>{name}</TableTitle>
              {cells.map((row, index) => (
                <Row key={index}>
                  <RowTitle>{row.name}</RowTitle>
                  <RowText notText={!row.value}>
                    {row.value === ''
                      ? '+'
                      : row.value === null
                      ? '-'
                      : row.value}
                  </RowText>
                </Row>
              ))}
            </TableRow>
          ))}
        </Table>
      )}
    </Component>
  );
}

const Component = styled.div``;

const Title = styled.p`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => props.theme.blue100};
`;

const Table = styled.div`
  margin-top: 40px;
`;

const Row = styled.div`
  display: flex;
  margin-top: 20px;
`;

const TableTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.blue100};
`;

const RowTitle = styled.p`
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.blue100};
`;

const RowText = styled.p<{ notText?: boolean }>`
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  word-break: break-word;
  margin-left: 10px;
  color: ${(props) => props.theme.black200};

  ${({ notText }) =>
    notText &&
    css`
      color: ${colors.gray500};
    `};
`;

const Header = styled.div`
  padding: 18.5px 20px;
  display: block;
  width: 100%;
  border-radius: 10px;
  background: ${(props) => props.theme.gray_1};
`;

const StyledArrowIcon = styled(ArrowIcon)<{ isOpened: boolean }>`
  width: 12px;
  height: 12px;
  margin-left: 6px;
  transition: 0.2s;

  path {
    fill: ${(props) => props.theme.blue100};
  }

  ${({ isOpened }) =>
    isOpened &&
    css`
      transform: rotate(-180deg);
    `}
`;

const TableRow = styled.div`
  &:not(:first-child) {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid ${colors.gray200};
  }
`;
