import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { useAtlantm30Data } from '@/modules/Atlantm30/Atlantm30.hooks';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

function Hero() {
  const { heroImageDesktop, heroImageMobile, heroLeftBanner, heroRightBanner } =
    useAtlantm30Data();

  if (
    !heroImageDesktop ||
    !heroImageMobile ||
    !heroLeftBanner ||
    !heroRightBanner
  ) {
    return null;
  }

  return (
    <Component>
      <Background
        loading="lazy"
        desktop={convertThumbnailToPictureImage(heroImageDesktop)}
        tabletSmall={convertThumbnailToPictureImage(heroImageDesktop)}
        mobile={convertThumbnailToPictureImage(heroImageMobile)}
      />

      <PromoImage
        loading="lazy"
        isLeftPromo
        src={heroLeftBanner?.url}
        src2x={heroLeftBanner?.url}
        srcWebp={heroLeftBanner?.url}
        srcWebp2x={heroLeftBanner?.url}
      />

      <PromoImage
        loading="lazy"
        isRightPromo
        src={heroRightBanner?.url}
        src2x={heroRightBanner?.url}
        srcWebp={heroRightBanner?.url}
        srcWebp2x={heroRightBanner?.url}
      />
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  position: relative;
  padding-top: 43.178%;

  ${media.mobile(css`
    padding-top: 147.7%;
  `)}
`;

const Background = styled(Picture)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const PromoImage = styled(Picture)<{
  isLeftPromo?: boolean;
  isRightPromo?: boolean;
}>`
  picture,
  img {
    position: absolute;
    max-width: 100%;
    width: 20vw;
    bottom: 0;

    ${media.mobile(css`
      width: 50vw;
    `)}

    ${({ isLeftPromo }) =>
      isLeftPromo &&
      css`
        left: 5%;

        ${media.mobile(css`
          left: 0;
        `)}
      `}

    ${({ isRightPromo }) =>
      isRightPromo &&
      css`
        right: 5%;

        ${media.mobile(css`
          right: 0;
        `)}
      `}
  }

  img {
    object-fit: cover;
  }
`;
