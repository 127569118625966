import React from 'react';
import styled, { css } from 'styled-components';

import RuleCard from '@/modules/HelpOnTheRoad/components/Main/components/Rules/components/RuleCard';
import { media } from '@/utils/mixin';

import ArrowIcon from './assets/arrow.svg';
import { RulesListProps } from './types';

function RulesList({ rules }: RulesListProps) {
  if (!rules || rules.length === 0) {
    return null;
  }

  return (
    <Component>
      {rules.map((rule, index) => (
        <RuleCardItem key={index}>
          <RuleCard {...rule} />
          {rules.length !== index + 1 && <StyledArrowIcon />}
        </RuleCardItem>
      ))}
    </Component>
  );
}

export default RulesList;

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 80px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 80px;
  `)}
`;

const RuleCardItem = styled.div`
  position: relative;
`;

const StyledArrowIcon = styled(ArrowIcon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -60px;
  width: 40px;
  height: 20px;

  ${media.tablet(css`
    display: none;
  `)}
`;
