import React from 'react';
import styled from 'styled-components';

import EstimateCar from '@/modules/AmpLanding/components/EstimateCar';
import Hero from '@/modules/AmpLanding/components/Hero';
import SoldCar from '@/modules/AmpLanding/components/SoldCar';
import Video from '@/modules/AmpLanding/components/Video';
import Consultation from '@/modules/AmpLanding/components/Consultation';
import Map from '@/modules/AmpLanding/components/Map';
import { useAmpLandingData } from '@/modules/AmpLanding/AmpLanding.hooks';
import { useTypedSelector } from '@/store/store';
import { selectAmpLandingDealers } from '@/store/reducers/amp-landing';
import Layout from '@/layout';

import Service from './components/Service';

function AmpLanding() {
  const { mapTitle, mapSocialTitle } = useAmpLandingData();
  const locationsList = useTypedSelector(selectAmpLandingDealers);

  const data = locationsList
    ? locationsList.map((item) => {
        return {
          id: item.city.id,
          name: item.city.name || '',
          items: item.locations.map((dealer) => {
            return {
              id: dealer.id,
              latitude: dealer.latitude,
              longitude: dealer.longitude,
              name: dealer.address,
              workTime: dealer.workTime,
              phone: null,
              image: null,
            };
          }),
        };
      })
    : [];

  return (
    <Layout>
      <Component>
        <Hero />
        <SoldCar />
        <EstimateCar />
        <Video />
        <Service />
        <Consultation />
        <Map title={mapTitle} socialsTitle={mapSocialTitle} data={data} />
      </Component>
    </Layout>
  );
}

export default AmpLanding;

const Component = styled.div`
  background: ${(props) => props.theme.white};
`;
