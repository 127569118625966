import React from 'react';
import styled, { css } from 'styled-components';

import { DealersCardsItem } from '@/modules/Brands/Brands.types';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';

function DealersCard({ imageItems, title, logoImage, link }: DealersCardsItem) {
  return (
    <Component to={link}>
      <Info>
        <List>
          {imageItems &&
            imageItems.map(({ image }, index) => (
              <CarLogo
                key={index}
                loading="lazy"
                src={image?.url}
                src2x={image?.url_2x}
                srcWebp={image?.url_webp}
                srcWebp2x={image?.url_webp_2x}
              />
            ))}
        </List>

        <Title>{title}</Title>
      </Info>
      <Logo
        loading="lazy"
        src={logoImage?.url}
        src2x={logoImage?.url_2x}
        srcWebp={logoImage?.url_webp}
        srcWebp2x={logoImage?.url_webp_2x}
      />
    </Component>
  );
}

export default DealersCard;

const Component = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  min-height: 200px;
  background: ${colors.white};

  ${media.tablet(css`
    padding: 20px;
  `)}

  &:hover {
    box-shadow: 0 5px 30px 0 rgb(23 43 77 / 10%);
  }
`;

const Info = styled.div`
  display: flex;
  align-items: flex-start;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 80px;
  border-right: 1px solid rgba(165, 173, 186, 0.3);
`;

const CarLogo = styled(Picture)`
  padding: 15px 0;
  max-width: 50px;
  max-height: 60px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-left: 20px;
  color: ${colors.blue700};

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const Logo = styled(Picture)`
  position: absolute !important;
  bottom: 0;
  right: 0;
  max-height: 200px;
  max-width: 50%;
`;
