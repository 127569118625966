import React from 'react';
import styled, { css } from 'styled-components';
import { useSelect } from 'downshift';

import { colors } from '@/constants/theme';
import ArrowIcon from '@/assets/svg/arrow-down.svg';
import Lock from '@/assets/svg/lock.svg';
import { media } from '@/utils/mixin';
import { ScrollStyles } from '@/components/styles';

import { SelectProps } from './Select.types';

function Select({
  value,
  options,
  onChange,
  className,
  isRequired,
  disabled,
}: SelectProps) {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    selectedItem: value,
    items: options,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange?.(selectedItem ?? options[0]);
    },
  });

  return (
    <Component className={className}>
      <Display type="button" disabled={disabled} {...getToggleButtonProps()}>
        <SelectedItem>
          {selectedItem?.label} {isRequired && <Required>*</Required>}
        </SelectedItem>
        {disabled ? <Lock /> : <StyledArrowIcon isOpen={isOpen} />}
      </Display>
      <DropdownMenu {...getMenuProps()} isOpen={isOpen}>
        {options.length > 0 ? (
          <OptionList>
            {options.map((option, index) => (
              <OptionItem
                key={`${option.value}${index}`}
                {...getItemProps({
                  item: option,
                  index,
                  isSelected: option.value === value?.value,
                })}
              >
                {option.label}
              </OptionItem>
            ))}
          </OptionList>
        ) : (
          <NoOptions>No options</NoOptions>
        )}
      </DropdownMenu>
    </Component>
  );
}

export default Select;

const Component = styled.div`
  position: relative;
  width: 100%;
`;

const Display = styled.button<{ disabled?: boolean }>`
  z-index: 4;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 21px 15px;
  height: 62px;
  border: 1px solid ${colors.main};
  border-radius: 5px;

  ${media.laptop(css`
    padding: 19px 16px;
    height: 50px;
  `)}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`;

const SelectedItem = styled.span`
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: ${(props) => props.theme.black100};

  ${media.laptop(css`
    font-size: 17px;
    line-height: 21px;
  `)}
`;

const Required = styled.span`
  color: rgba(244, 67, 54, 1) !important;
`;

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  z-index: 3;
  position: absolute;
  top: 100%;
  width: 100%;
  background: ${(props) => props.theme.white};
  border: 1px solid ${(props) => props.theme.black200_alpha40};
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  max-height: 320px;
  overflow-y: auto;
  ${ScrollStyles}

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    `};
`;

const StyledArrowIcon = styled(ArrowIcon)<{ isOpen: boolean }>`
  opacity: 0.4;
  transition: 0.3s;

  path {
    fill: ${(props) => props.theme.black100};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `};
`;

const OptionList = styled.ul`
  padding: 15px 0;
`;

const OptionItem = styled.li<{ isSelected: boolean }>`
  padding: 5px 15px;

  cursor: pointer;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.black100};
  white-space: nowrap;

  ${media.laptop(css`
    font-size: 15px;
    line-height: 17px;
  `)}

  ${({ isSelected }) =>
    isSelected &&
    css`
      font-weight: 500;
    `};
`;

const NoOptions = styled.div``;
