import React from 'react';
import styled from 'styled-components';

import CameraIcon from '@/assets/svg/camera_map.svg';
import CheckboxTickSvg from '@/assets/svg/checkbox-tick.svg';
import { colors } from '@/constants/theme';
import { StringField } from '@/typings/model';
import { getPhoneLink } from '@/utils/common';

import { cameraClassName } from '../../../LocationsMap.constants';

interface IProps {
  isMobile: boolean;
  className: string;

  isActive: boolean;
  isForm?: boolean;

  id: number;
  name: StringField;
  url?: StringField;
  urlLabel?: StringField;
  address?: StringField;
  phone?: StringField;
  image?: StringField;
  workTime?: StringField;

  handleClick(): void;

  setDataToParent(image: string, name: string, isHovered: boolean): void;
}

function ItemComponent({
  className,
  name,
  image,
  phone,
  url,
  urlLabel,
  address,
  workTime,
  isActive,
  handleClick,
  isMobile,
  setDataToParent,
  isForm,
}: IProps) {
  const handleClickDealer = (): void => {
    handleClick();
  };

  const handlerHoverCamera = (
    image: string,
    name: StringField,
    isHovered: boolean
  ): void => {
    setDataToParent(image, name || '', isHovered);
  };

  return (
    <Component>
      {isMobile && url ? (
        <ClickAreaLink href={url} target="_blank" />
      ) : (
        <ClickArea onClick={handleClickDealer} />
      )}
      <Inner isActive={isActive} className={className} isForm={!!isForm}>
        {image && !isMobile ? (
          <CameraContainer
            className={cameraClassName}
            onClick={() => handlerHoverCamera(image, name, true)}
            onMouseEnter={() => handlerHoverCamera(image, name, true)}
            onMouseLeave={() => handlerHoverCamera('', '', false)}
          >
            <CameraIcon />
          </CameraContainer>
        ) : null}
        <DataContainer>
          {isMobile && url && !isForm ? (
            <LinkLabel target="_blank" isActive={isActive} href={url}>
              {name}
            </LinkLabel>
          ) : (
            <Label isActive={isActive} onClick={handleClickDealer}>
              {name}
            </Label>
          )}
          {address ? <Address>{address}</Address> : null}
          {phone ? <Phone href={getPhoneLink(phone)}>{phone}</Phone> : null}
          {workTime ? <WorkTime>{workTime}</WorkTime> : null}
          {url && urlLabel && (isActive || isMobile) ? (
            <ItemLink target="_blank" href={url}>
              {urlLabel}
            </ItemLink>
          ) : null}
          {isActive && isForm ? (
            <SelectedIcon>
              <CheckboxTickSvg />
            </SelectedIcon>
          ) : null}
        </DataContainer>
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
`;

const Inner = styled.div<{ isActive: boolean; isForm: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 16px 20px;
  padding-right: ${(props) => (props.isForm ? '50px' : '20px')};
  border-bottom: 1px solid #ededed;
  background-color: ${(props): string =>
    props.isActive ? props.theme.gray1100 : 'none'};
  box-shadow: ${(props): string =>
    props.isActive ? '0 0 4px 0 rgb(0 0 0 / 40%)' : ''};

  @media (max-width: 768px) {
    padding: 18px 10px;
  }

  &:before {
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    border: 1px solid
      ${(props) => (props.isActive ? colors.main : colors.gray400)};
    background: ${(props) => (props.isActive ? colors.main : 'transparent')};
    display: ${(props) => (props.isForm ? 'block' : 'none')};
  }
`;

const ClickArea = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;

  &:hover + ${Inner} {
    color: red;
  }
`;

const ClickAreaLink = styled.a`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;

  &:hover + ${Inner} {
    color: red;
  }
`;

const CameraContainer = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 4px;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
`;

const Label = styled.span<{ isActive: boolean }>`
  padding: 3px 0;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  cursor: pointer;
  color: ${(props): string =>
    props.isActive ? `${colors.main}` : props.theme.blue200};

  &:hover {
    color: ${colors.main};
  }
`;

const LinkLabel = styled.a<{ isActive: boolean }>`
  padding: 3px 0;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  cursor: pointer;
  color: ${(props): string => (props.isActive ? colors.main : colors.blue700)};
  position: relative;
`;

const Phone = styled.a`
  width: 290px;
  color: ${colors.main};
  font-size: 16px;
  position: relative;
  z-index: 1;

  @media (max-width: 450px) {
    width: 225px;
  }
`;

const Address = styled.span`
  padding: 3px 0;
  line-height: 24px;
  color: ${colors.gray};
  font-size: 16px;
  display: block;
`;

const WorkTime = styled.span`
  padding: 3px 0;
  line-height: 24px;
  width: 290px;
  color: ${colors.gray};
  font-size: 16px;

  @media (max-width: 450px) {
    width: 225px;
  }
`;

const ItemLink = styled.a`
  color: ${colors.black};
  text-decoration: underline;
  margin-top: 10px;
  display: inline-block;
  position: relative;
  z-index: 1;
`;

const SelectedIcon = styled.div`
  position: absolute;
  right: 21px;
  top: 50%;
  margin-top: 2px;
  transform: translateY(-50%) scale(1.5);
`;

export default ItemComponent;
