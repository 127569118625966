import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/TopLevelFunnelLanding/common.styles';
import { useTopLevelFunnelLandingData } from '@/modules/TopLevelFunnelLanding/hooks';
import { media } from '@/utils/mixin';
import { Title, VideoSlider } from '@/components/LandingWidgets';
import { Component as TitleComponent } from '@/components/LandingWidgets/components/Title';

function Videos() {
  const { videoTitle, videoItems } = useTopLevelFunnelLandingData();

  if (!videoItems || videoItems.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <TitleWrapper>
          <Title title={videoTitle ?? ''} />
        </TitleWrapper>

        <VideoSlider
          id={4}
          videos={videoItems.map(({ link }) => ({ link: link ?? '' }))}
        />
      </ContentContainer>
    </Component>
  );
}

export default Videos;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;

    ${ContentContainer} {
      padding: 0 20px;
    }
  `)}
`;

const TitleWrapper = styled.div`
  margin-bottom: 20px;

  ${media.tablet(css`
    ${TitleComponent} {
      text-align: left;
    }
  `)}
`;
