import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { ContentContainer, Title } from '@/modules/HelpOnTheRoad/common.styles';
import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';
import { media } from '@/utils/mixin';

import BenefitList from './components/BenefitList';
import BenefitSlider from './components/BenefitSlider';
import { BenefitProps } from './components/Benefit/types';

function Benefits() {
  const { benefitsTitle, benefits } = useHelpOnTheRoadData();
  const isMobile = useMedia('(max-width: 767.9px)');

  if (!benefits || benefits.length === 0) {
    return null;
  }

  const convertedBenefits: BenefitProps[] = benefits.map(
    ({ image, title, text }) => ({
      image,
      title: title ?? '',
      text: text ?? '',
    })
  );

  return (
    <Component>
      <Content>
        <ContentContainer>
          <Title>{benefitsTitle}</Title>
          <Main>
            {!isMobile ? (
              <BenefitList benefits={convertedBenefits} />
            ) : (
              <BenefitSlider id={1} benefits={convertedBenefits} />
            )}
          </Main>
        </ContentContainer>
      </Content>
    </Component>
  );
}

export default Benefits;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;

const Content = styled.div`
  ${media.mobile(css`
    padding: 0 15px;
  `)}
`;

const Main = styled.div`
  margin-top: 20px;
`;
