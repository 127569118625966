import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { TabsProps } from './types';

export function Tabs({ data, onChange, activeValue, isBlock }: TabsProps) {
  const handleChangeTab = (activeIndex: number, activeValue: string) => {
    onChange?.({ activeIndex, activeValue });
  };

  if (data.length < 1) {
    return null;
  }

  return (
    <Component isBlock={isBlock}>
      {data.map(({ value, label }, index) => (
        <Tab
          key={index}
          onClick={() => handleChangeTab(index, value)}
          isActiveTab={value === activeValue}
          isBlock={isBlock}
        >
          {label}
        </Tab>
      ))}
    </Component>
  );
}

const Component = styled.div<{ isBlock?: boolean }>`
  display: flex;
  border-radius: 15px;
  background: ${colors.gray200};

  ${({ isBlock }) =>
    isBlock &&
    css`
      flex: 1;

      ${media.mobile(css`
        flex-direction: column;
      `)}
    `};
`;

const Tab = styled.div<{ isActiveTab?: boolean; isBlock?: boolean }>`
  padding: 8px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  border-radius: 15px;
  transition: 0.15s;
  cursor: pointer;
  text-align: center;
  background: ${colors.gray200};
  color: ${colors.black100};

  &:hover {
    color: ${colors.main};
  }

  ${({ isActiveTab }) =>
    isActiveTab &&
    css`
      font-weight: bold;
      background: rgba(20, 27, 39, 0.56);
      color: ${colors.white};

      &:hover {
        color: ${colors.white};
      }
    `};

  ${({ isBlock }) =>
    isBlock &&
    css`
      flex: 1;
    `};
`;
