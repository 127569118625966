import React from 'react';
import styled, { css } from 'styled-components';

import { useAmpLandingData } from '@/modules/AmpLanding/AmpLanding.hooks';
import { media } from '@/utils/mixin';

function Video() {
  const { videoTitle, videoSubtitle, videoLink } = useAmpLandingData();

  return (
    <Component>
      <Title>{videoTitle}</Title>
      <Subtitle>{videoSubtitle}</Subtitle>
      <VideoWrapper>
        <iframe
          src={videoLink ?? ''}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoWrapper>
    </Component>
  );
}

export default Video;

const Component = styled.div`
  max-width: 1280px;
  margin: 100px auto 0;
  padding: 0 20px;
`;

const Title = styled.h2`
  font-size: 36px;
  text-align: center;
  color: ${(props) => props.theme.black};

  @media (max-width: 1100px) {
    margin: 360px 0 0;
  }

  ${media.mobile(css`
    font-size: 30px;
  `)};

  @media (max-width: 450px) {
    margin: 250px 0 0;
  }
`;

const Subtitle = styled.span`
  display: block;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 16px 0 0;
  color: ${(props) => props.theme.blue100};
`;

const VideoWrapper = styled.div`
  overflow: hidden;
  border-radius: 17px;
  position: relative;
  margin-top: 40px;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
`;
