import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';

import { DesktopSliderProps } from './types';

function DesktopSlider({ images, link }: DesktopSliderProps) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  return (
    <Component
      onMouseOut={() => {
        setActiveSlideIndex(0);
      }}
    >
      <SlideList to={link}>
        {images.map((item, index) => {
          return (
            <SlideListItem
              key={index}
              onMouseEnter={() => {
                setActiveSlideIndex(index);
              }}
              isActive={activeSlideIndex === index}
            >
              <StyledPicture loading="lazy" src={item} />
            </SlideListItem>
          );
        })}
      </SlideList>

      {images.length > 1 && (
        <PaginationList>
          {images.map((item, index) => {
            return (
              <PaginationListItem
                key={index}
                isActive={activeSlideIndex === index}
              />
            );
          })}
        </PaginationList>
      )}
    </Component>
  );
}

export default DesktopSlider;

const Component = styled.div`
  position: relative;
  background-color: #efefef;
  padding-top: 65%;
`;

const SlideList = styled(Link)`
  z-index: 2;
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
`;

const SlideListItem = styled.div<{ isActive: boolean }>`
  flex-grow: 1;
  pointer-events: visible;

  &:hover {
    picture,
    img {
      &:before {
        opacity: 1;
      }
    }
  }

  &:last-child {
    cursor: pointer;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      ${StyledPicture} {
        opacity: 1;
        z-index: -1;
      }
    `}
`;

const StyledPicture = styled(Picture)`
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  border-radius: 8px 8px 0 0;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: contain;
  }

  &::before {
    position: absolute;
    content: '';
    display: none;
    inset: 0;
    background: ${colors.white_alpha60};
    z-index: 10;
  }
`;

const PaginationList = styled.div`
  z-index: 3;
  display: flex;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`;

const PaginationListItem = styled.div<{
  isActive: boolean;
}>`
  content: '';
  width: 40px;
  height: 4px;
  background-color: ${colors.black};
  border-radius: 3px;

  &:not(:last-child) {
    margin-right: 5px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${colors.white};
    `}
`;
