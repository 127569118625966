import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

import BlueIcon from '@/assets/svg/icon_map_blue.svg';
import RedIcon from '@/assets/svg/icon_map_red.svg';

import { IMapItem, IMapLatLng } from '../LocationsMap.typings';
import { mapDefaultProps } from '../LocationsMap.constants';

interface IProps {
  items: Array<IMapItem>;

  zoom?: number;
  center: IMapLatLng;
  handleClickMapDealer?: (id: number) => void;
  getSelectedElement?: (element: HTMLDivElement) => void;
}

function Map({
  zoom = mapDefaultProps.zoom,
  center,
  items,
  handleClickMapDealer,
  getSelectedElement,
}: IProps) {
  function onClick(id: number) {
    if (handleClickMapDealer) {
      handleClickMapDealer(id);
    }

    const list = document.querySelector(`.className_${id}`);
    if (list && getSelectedElement) {
      getSelectedElement(list as HTMLDivElement);
    }
  }

  return (
    <MapContainer>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.NEXT_PUBLIC_API_KEY_GOOGLE_MAP ?? '',
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        center={center}
      >
        {items.map((item) => (
          <IconContainer key={item.id} lat={item.latitude} lng={item.longitude}>
            {item.active ? (
              <StyledBlueIcon onClick={() => onClick(item.id)} />
            ) : (
              <StyledRedIcon onClick={() => onClick(item.id)} />
            )}
          </IconContainer>
        ))}
      </GoogleMapReact>
    </MapContainer>
  );
}

const MapContainer = styled.div`
  width: 100%;
  height: 760px;
  margin-top: 31px;
  margin-bottom: 82px;
`;

const IconContainer = styled.div<{ lat: number; lng: number }>`
  width: 30px;
  height: 30px;

  svg {
    top: -50px;
    left: -20px;
    position: absolute;
    cursor: pointer;
  }
`;

const StyledBlueIcon = styled(BlueIcon)`
  z-index: 5;
`;

const StyledRedIcon = styled(RedIcon)`
  z-index: 1;
`;

export default Map;
