import React from 'react';
import styled, { css } from 'styled-components';

import { useTopLevelFunnelLandingData } from '@/modules/TopLevelFunnelLanding/hooks';
import { ContentContainer } from '@/modules/TopLevelFunnelLanding/common.styles';
import { AboutCardSecondary } from '@/components/LandingWidgets';
import { media } from '@/utils/mixin';

function TradeIn() {
  const {
    tradeInTitle,
    tradeInDescription,
    tradeInBenefits,
    tradeInButtonTitle,
    tradeInButtonLink,
  } = useTopLevelFunnelLandingData();

  if (!tradeInBenefits || tradeInBenefits.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <AboutCardSecondary
          id={6}
          title={tradeInTitle ?? ''}
          description={tradeInDescription ?? ''}
          benefits={tradeInBenefits.map(({ image, title, description }) => ({
            image,
            title: title ?? '',
            description: description ?? '',
          }))}
          buttonTitle={tradeInButtonTitle ?? ''}
          buttonLink={tradeInButtonLink ?? ''}
        />
      </ContentContainer>
    </Component>
  );
}

export default TradeIn;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;

    ${ContentContainer} {
      padding: 0 20px;
    }
  `)}
`;
