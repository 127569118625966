import React from 'react';
import styled from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { SliderWrapper } from '@/components/LandingWidgets';

import RuleCard from '../RuleCard';
import { Component as RuleCardComponent } from '../RuleCard/RuleCard';

import { RulesSliderProps } from './types';

function RulesSlider({ id, rules }: RulesSliderProps) {
  if (!rules || rules.length === 0) {
    return null;
  }

  const isWithoutPagination = rules.length === 1;
  const isNoSwiping = rules.length === 1;

  return (
    <Component>
      <SliderWrapper
        id={id}
        options={{
          spaceBetween: 30,
          noSwipingClass: 'swiper-no-swiping',
          withoutPagination: isWithoutPagination,
          withoutNavs: true,
        }}
      >
        {rules.map((rule, index) => (
          <SwiperSlide
            key={index}
            className={isNoSwiping ? 'swiper-no-swiping' : ''}
          >
            <RuleCard key={index} {...rule} />
          </SwiperSlide>
        ))}
      </SliderWrapper>
    </Component>
  );
}

export default RulesSlider;

const Component = styled.div`
  .swiper-slide {
    height: auto;

    ${RuleCardComponent} {
      height: 100%;
    }
  }
`;
