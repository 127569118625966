import React from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';

interface Props {
  sectionTitle: Nullable<string>;
  sectionDescription: Nullable<string>;
  Links: React.ReactNode;
  TabButtons: React.ReactNode;
  TabContent: React.ReactNode;
  Die?: React.ReactNode;
  Button?: React.ReactNode;
}

function BaseHomeSection({
  sectionTitle,
  sectionDescription,
  Links,
  TabButtons,
  TabContent,
  Die,
  Button,
}: Props) {
  return (
    <Component>
      <ContentContainerSmall>
        <Content>
          <Header>
            {Die && <DieBlock>{Die}</DieBlock>}
            <TabsButtonsBlock>{TabButtons}</TabsButtonsBlock>
          </Header>
          <InfoBlock>
            <InfoTitle>{sectionTitle}</InfoTitle>
            <InfoDescription>{sectionDescription}</InfoDescription>
          </InfoBlock>
          <LinksBlock>{Links}</LinksBlock>
          {Button && <ButtonBlock>{Button}</ButtonBlock>}
          <PreviewCatalogBlog>{TabContent}</PreviewCatalogBlog>
        </Content>
      </ContentContainerSmall>
    </Component>
  );
}

export default BaseHomeSection;

const Component = styled.section`
  width: 100%;
  padding: 40px 0;
  background: ${(props) => props.theme.gray};

  ${media.mobile(css`
    background: ${(props) => props.theme.white};
    padding: 30px 0;
  `)}
`;

const Content = styled.div`
  display: grid;
  grid-template-areas:
    'j x x'
    'a b b'
    'd b b'
    'c b b';
  grid-template-columns: 31% 69%;
  grid-template-rows: auto auto auto 1fr;

  ${media.tabletSmall(css`
    grid-template-areas:
      'a a a'
      'x x x'
      'b b b'
      'd d d'
      'c c c';
  `)}
`;

const Header = styled.div`
  grid-area: x;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 30px;

  ${media.tabletSmall(css`
    flex-direction: column;
    margin-top: 20px;
  `)}
`;

const DieBlock = styled.div`
  width: 100%;
  margin-bottom: -30px;

  ${media.tabletSmall(css`
    margin-bottom: 30px;
  `)}
`;

const TabsButtonsBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const InfoBlock = styled.div`
  grid-area: a;
  padding-right: 60px;

  ${media.tabletSmall(css`
    padding-right: 0;
  `)}
`;

const LinksBlock = styled.div`
  grid-area: d;
  padding-right: 60px;
  margin-top: 20px;

  ${media.tabletSmall(css`
    padding-right: 0;
  `)}
`;

const ButtonBlock = styled.div`
  grid-area: c;
  padding-right: 60px;
  margin-top: 40px;

  ${media.tabletSmall(css`
    padding-right: 0;
  `)}

  ${media.mobile(css`
    z-index: 1;
    position: sticky;
    bottom: 20px;
    margin-top: 20px;
  `)}
`;

const PreviewCatalogBlog = styled.div`
  grid-area: b;
`;

const InfoTitle = styled.span`
  display: block;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: ${(props) => props.theme.black};

  ${media.tabletSmall(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const InfoDescription = styled.p`
  margin-top: 30px;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.blue100};

  ${media.tabletSmall(css`
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  `)}
`;
