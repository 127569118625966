import styled, { css } from 'styled-components';
import React from 'react';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { FeatureCardProps } from './types';

function FeatureCard({ title, icon, text, list }: FeatureCardProps) {
  return (
    <Component>
      <Container>
        <InfoCardHeader>{title}</InfoCardHeader>
        <InfoCardImageContainer>
          <Picture loading="lazy" {...convertThumbnailToPictureProps(icon)} />
        </InfoCardImageContainer>
        <InfoListContainer>
          <InfoListHeader>{text}</InfoListHeader>
          <InfoUl>
            {list.map((text, index) => (
              <InfoLi key={index}>
                <Span>{text}</Span>
              </InfoLi>
            ))}
          </InfoUl>
        </InfoListContainer>
      </Container>
    </Component>
  );
}

const Component = styled.div`
  padding: 20px 15px;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  background: ${(props) => props.theme.white_1};
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px 20px 30px;
  border-radius: 17px;
  box-shadow: ${(props) => props.theme.blackShadow100};

  ${media.mobile(css`
    padding: 15px 15px 25px;
  `)}
`;

const InfoCardHeader = styled.h3`
  height: 56px;
  font-size: 24px;
  text-align: center;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 20px;
  `)}
`;

const InfoCardImageContainer = styled.div`
  width: 123px;
  height: 123px;
  border-radius: 50%;
  margin-top: 36px;

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`;

const InfoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
`;

const InfoListHeader = styled.span`
  display: block;
  font-weight: 600;
  font-size: 16px;
  margin: 35px auto 0;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`;

const InfoUl = styled.ul`
  align-self: flex-start;
  padding-left: 17px;
  margin-top: 16px;
`;

const InfoLi = styled.li`
  font-size: 16px;
  list-style-type: disc;
  margin-top: 10px;
  color: ${colors.main};

  &:first-child {
    margin-top: 0;
  }
`;

const Span = styled.span`
  color: ${(props) => props.theme.blue100};
`;

export default FeatureCard;
