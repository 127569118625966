import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';
import { useModal } from '@tager/web-components';

import Picture from '@/components/Picture';
import { useElectrocarsLandingData } from '@/modules/ElectrocarsLanding/hooks';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Button from '@/components/Button';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { submitElectrocarsLandingSelectionCar } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';

import Menu from './components/Menu';

function Hero() {
  const openModal = useModal();
  const {
    menuItems,
    heroImage,
    heroMobileImage,
    heroTitle,
    heroSubtitle,
    heroButtonTitle,
    heroModalTitle,
    heroGoogleEvent,
    heroBenefits,
  } = useElectrocarsLandingData();

  const sendApplicationForm = async (
    values: ApplicationFormFields
  ): Promise<void> => {
    try {
      await submitElectrocarsLandingSelectionCar(values).then(() => {
        if (heroGoogleEvent) {
          googleEvent(heroGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenModal = () => {
    openModal(
      ApplicationFormModal,
      {
        title: heroModalTitle ?? '',
        onSubmit: sendApplicationForm,
        fieldsConfig: { withoutEmail: true },
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component>
      <Background
        loading="lazy"
        desktop={convertThumbnailToPictureImage(heroImage)}
        laptop={convertThumbnailToPictureImage(heroImage)}
        tabletSmall={convertThumbnailToPictureImage(heroImage)}
        mobile={convertThumbnailToPictureImage(heroMobileImage ?? heroImage)}
      />

      <Content>
        <Header>
          <Left>
            <Title>{heroTitle}</Title>
            <Subtitle>{heroSubtitle}</Subtitle>
          </Left>

          {menuItems && menuItems.length > 0 && (
            <Right>
              <Menu
                items={menuItems.map(({ text, anchor }) => ({
                  text: text ?? '',
                  anchor: anchor ?? '',
                }))}
              />
            </Right>
          )}
        </Header>

        <Bottom>
          <Actions>
            <StyledButton variant="contained" onClick={handleOpenModal}>
              {heroButtonTitle}
            </StyledButton>
          </Actions>

          {heroBenefits && heroBenefits.length !== 0 && (
            <List>
              {heroBenefits.map(({ image, text }, index) => (
                <Benefit key={index}>
                  <BenefitImage
                    loading="lazy"
                    src={image?.url}
                    src2x={image?.url_2x}
                    srcWebp={image?.url_webp}
                    srcWebp2x={image?.url_webp_2x}
                  />
                  <BenefitText>{text}</BenefitText>
                </Benefit>
              ))}
            </List>
          )}
        </Bottom>
      </Content>
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  position: relative;
  padding-top: calc(100vh - 80px);

  ${media.tablet(css`
    padding-top: 55.7639%;
  `)}

  ${media.mobile(css`
    padding-top: initial;
    height: 704px;
  `)}
`;

const Background = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1340px;
  padding: 60px 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 40px 20px;
  `)}
`;

const Header = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  flex: 1 1 800px;
  max-width: 800px;
`;

const Right = styled.div`
  position: sticky;
  top: 51px;
  align-self: flex-start;

  ${media.tablet(css`
    display: none;
  `)}
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 60px;
  line-height: 65px;
  margin: 0;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 34px;
    line-height: 34px;
  `)}
`;

const Subtitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  max-width: 359px;
  margin-top: 20px;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 16px;
    line-height: 19px;
  `)}
`;

const Bottom = styled.div``;

const Actions = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  max-width: 295px;

  ${media.tablet(css`
    max-width: 280px;
  `)}
`;

const List = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  margin-top: 42px;

  ${media.tablet(css`
    grid-template-columns: repeat(3, 1fr);
    gap: 15px 0;
    margin-top: 52px;
  `)}

  ${media.mobile(css`
    grid-template-columns: repeat(2, 1fr);
  `)}
`;

const Benefit = styled.div`
  display: flex;
  align-items: center;
`;

const BenefitImage = styled(Picture)`
  flex: 0 0 50px;
  width: 50px;
  height: 50px;

  ${media.tablet(css`
    flex: 0 0 30px;
    width: 30px;
    height: 30px;
  `)}

  picture,
  img {
    width: 50px;
    height: 50px;

    ${media.tablet(css`
      width: 30px;
      height: 30px;
    `)}
  }
`;

const BenefitText = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-left: 23px;
  color: ${colors.white};

  ${media.tablet(css`
    margin-left: 16px;
  `)}
`;
