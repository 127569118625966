import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Hero from './components/Hero';
import About from './components/About';
import Guarantee from './components/Guarantee';
import CatalogCars from './components/CatalogCars';
import Videos from './components/Videos';
import Credit from './components/Credit';
import TradeIn from './components/TradeIn';
import ServiceBanner from './components/ServiceBanner';
import AboutDealer from './components/AboutDealer';
import ConsultationForm from './components/ConsultationForm';
import Contacts from './components/Contacts';

function TopLevelFunnelLanding() {
  return (
    <Layout>
      <Component>
        <Hero />
        <About />
        <Guarantee />
        <CatalogCars />
        <Videos />
        <Credit />
        <TradeIn />
        <ServiceBanner />
        <AboutDealer />
        <ConsultationForm />
        <Contacts />
      </Component>
    </Layout>
  );
}

export default TopLevelFunnelLanding;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
