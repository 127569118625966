import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { Page } from '@/typings/page';
import { ValueCar } from '@/modules/ValueCar/ValueCar.types';

function Hero() {
  const pageFields = useCurrentPage<Page<ValueCar>>().templateFields;

  if (!pageFields.innerBlockImage) return null;

  return (
    <Content>
      <Component>
        <StyledPicture
          loading="lazy"
          {...convertThumbnailToPictureProps(pageFields.innerBlockImage)}
        />
      </Component>
    </Content>
  );
}

export default Hero;

const Content = styled.div`
  width: 60%;
  z-index: 1;
  @media (max-width: 768px) {
    width: calc(100% + 40px);
    margin: 0 0 20px -20px;
  }
`;

const Component = styled.div`
  position: relative;
  padding-top: 49.26%;
  ${media.mobile(css`
    padding-top: 66.26%;
  `)}
`;

const StyledPicture = styled(Picture)`
  position: static !important;

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    right: 0;
    object-fit: cover;
  }
`;
