import React, { useState } from 'react';
import { FormikErrors, useFormik } from 'formik';
import styled, { css } from 'styled-components';

import { convertRequestErrorToMap, useUpdateEffect } from '@tager/web-core';
import { convertThumbnailToPictureImage } from '@tager/web-modules';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import StockFilterInput from '@/components/StockFilterInput';
import StockFilterTextArea from '@/components/StockFilterTextArea';
import { CheckboxAgreement } from '@/components/Checkbox';
import Loader from '@/components/Loader';
import Button from '@/components/Button';
import { media } from '@/utils/mixin';

import {
  ConsultationFormTertiaryProps,
  ConsultationFormTertiaryFields,
} from './types';
import {
  initialConsultationFormTertiaryValues,
  validationConsultationFormTertiarySchema,
} from './helpers';

function ConsultationFormTertiary({
  image,
  imageMobile,
  title,
  subtitle,
  buttonText,
  onSubmit,
}: ConsultationFormTertiaryProps) {
  const [isFormSend, setFormSend] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setErrors,
    resetForm,
    isSubmitting,
  } = useFormik<ConsultationFormTertiaryFields>({
    initialValues: initialConsultationFormTertiaryValues,
    validationSchema: validationConsultationFormTertiarySchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await onSubmit?.(values).then(() => {
          resetForm();
          setUserAgreement(false);
          setFormSend(true);
        });
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<ConsultationFormTertiaryFields>;
        setErrors(errorMap);
        console.log(error);
      }

      setSubmitting(false);
    },
  });

  useUpdateEffect(() => {
    if (
      isFormSend &&
      (values.name || values.phone || values.message || userAgreement)
    ) {
      setFormSend(false);
    }
  }, [values, userAgreement]);

  return (
    <Component>
      <Background
        loading="lazy"
        desktop={convertThumbnailToPictureImage(image)}
        laptop={convertThumbnailToPictureImage(image)}
        tabletSmall={convertThumbnailToPictureImage(image)}
        mobile={convertThumbnailToPictureImage(imageMobile ?? image)}
      />
      <ContentContainer>
        <Form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Header>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </Header>

          <Fields>
            {isFormSend && <SuccessMessage>Заявка отправлена</SuccessMessage>}

            <Inputs>
              <Input>
                <StockFilterInput
                  id="name"
                  name="name"
                  placeholder="Имя"
                  value={values.name}
                  onChange={handleChange}
                  errorMessage={
                    errors.name && touched.name ? errors.name : undefined
                  }
                />
              </Input>

              <Input>
                <StockFilterInput
                  id="phone"
                  name="phone"
                  placeholder="Телефон"
                  value={values.phone}
                  onChange={handleChange}
                  errorMessage={
                    errors.phone && touched.phone ? errors.phone : undefined
                  }
                />
              </Input>
            </Inputs>

            <Area>
              <StockFilterTextArea
                id="message"
                name="message"
                value={values.message}
                onChange={handleChange}
                placeholder="Комментарий"
              />
            </Area>

            <CheckBoxWrapper>
              <CheckboxAgreement
                checked={userAgreement}
                onChange={() => setUserAgreement(!userAgreement)}
              />
            </CheckBoxWrapper>

            <ButtonWrapper>
              <StyledButton
                type="submit"
                variant="contained"
                disabled={!isSubmitting ? !userAgreement : true}
              >
                {!isSubmitting ? buttonText : <Loader color={colors.white} />}
              </StyledButton>
            </ButtonWrapper>
          </Fields>
        </Form>
      </ContentContainer>
    </Component>
  );
}

export default ConsultationFormTertiary;

const Component = styled.div`
  position: relative;
  padding: 60px 0;

  ${media.tablet(css`
    padding: 40px 0;
  `)}

  ${media.mobile(css`
    height: 850px;
  `)}
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1340px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    height: 100%;
  `)}
`;

const Background = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Form = styled.form`
  position: relative;
  max-width: 588px;

  ${media.mobile(css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: none;
    height: 100%;
  `)}
`;

const Header = styled.div``;

const Title = styled.p`
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 28px;
    line-height: 33px;
  `)}
`;

const Subtitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  margin-top: 10px;
  color: ${colors.white};

  ${media.tablet(css`
    font-size: 18px;
    line-height: 21px;
    margin-top: 20px;
  `)}
`;

const Fields = styled.div`
  position: relative;
  margin-top: 63px;
`;

const Inputs = styled.div`
  display: flex;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Input = styled.div`
  width: 100%;

  &:not(:first-child) {
    margin-left: 20px;

    ${media.mobile(css`
      margin: 20px 0 0;
    `)}
  }
`;

const Area = styled.div`
  margin-top: 30px;

  ${media.mobile(css`
    margin-top: 20px;
  `)}

  textarea {
    min-height: 150px;

    ${media.tablet(css`
      min-height: 120px;
    `)}
  }
`;

const CheckBoxWrapper = styled.div`
  margin-top: 20px;

  span {
    color: ${colors.white};
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 34px;

  ${media.tablet(css`
    margin-top: 40px;
  `)}

  ${media.mobile(css`
    display: flex;
    justify-content: center;
  `)}
`;

const StyledButton = styled(Button)`
  max-width: 221px;
`;

const SuccessMessage = styled.span`
  position: absolute;
  right: 0;
  top: -20px;
  font-size: 14px;
  line-height: 14px;
  color: ${colors.green};
`;
