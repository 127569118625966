import React from 'react';
import styled from 'styled-components';

import { ContentContainer } from '@/modules/CarModelLanding/common.styles';
import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import { AboutCard, Title } from '@/components/CarModelLandingWidgets';

function About() {
  const {
    aboutTitle,
    aboutImage,
    aboutText,
    aboutButtonTitle,
    aboutButtonLink,
  } = useCarModelLandingData();

  if (
    !aboutTitle &&
    !aboutImage &&
    !aboutText &&
    !aboutButtonTitle &&
    !aboutButtonLink
  ) {
    return null;
  }

  return (
    <Component id="about">
      <ContentContainer>
        <Title title={aboutTitle ?? ''} />

        <AboutCardWrapper>
          <AboutCard
            image={aboutImage}
            text={aboutText ?? ''}
            buttonTitle={aboutButtonTitle ?? ''}
            buttonLink={aboutButtonLink ?? ''}
          />
        </AboutCardWrapper>
      </ContentContainer>
    </Component>
  );
}

export default About;

const Component = styled.section`
  padding-bottom: 60px;
`;

const AboutCardWrapper = styled.div`
  margin-top: 25px;
`;
