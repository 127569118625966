import React from 'react';
import styled, { css } from 'styled-components';

import CheckIcon from './assets/check.svg';
import MinusIcon from './assets/minus.svg';
import { TableSectionDesktopProps } from './types';

function TableSectionDesktop({ title, rows }: TableSectionDesktopProps) {
  if (!rows || rows.length === 0) {
    return null;
  }

  return (
    <Component>
      <Header>
        <Title>{title}</Title>
      </Header>

      <Body>
        {rows.map(({ title, cells }, index) => (
          <Row key={index}>
            <RowTitle>{title}</RowTitle>
            <Cells cellsCount={cells.length}>
              {cells.map((cell, index) => (
                <Cell key={index}>
                  {cell === '' ? (
                    <CheckIcon />
                  ) : cell === null ? (
                    <MinusIcon />
                  ) : (
                    cell
                  )}
                </Cell>
              ))}
            </Cells>
          </Row>
        ))}
      </Body>
    </Component>
  );
}

export default TableSectionDesktop;

const Component = styled.div``;

const Header = styled.div`
  padding: 10px;
  background: #a9d3fd;
  border-radius: 10px 0 0 10px;
`;

const Title = styled.p`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  max-width: 580px;
  color: ${(props) => props.theme.black};
`;

const Body = styled.div`
  margin-top: 10px;
`;

const Row = styled.div`
  display: flex;
`;

const RowTitle = styled.div`
  flex: 0 0 600px;
  max-width: 600px;
  padding: 10px;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.black200};
`;

const Cells = styled.div<{ cellsCount: number }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex: 100%;
  max-width: 100%;

  ${({ cellsCount }) =>
    cellsCount &&
    css`
      grid-template-columns: repeat(${cellsCount}, 1fr);
    `}
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.black200};
  text-align: center;
  margin-left: 20px;
`;
