import React from 'react';
import styled, { css } from 'styled-components';

import ArrowSvg from '@/assets/svg/arrow-right.svg';
import useCurrentPage from '@/hooks/useCurrentPage';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';

function EcoContent() {
  const page = useCurrentPage();

  if (!page) {
    return null;
  }

  return (
    <Component>
      {page.templateFields?.contentText && (
        <TextContent
          dangerouslySetInnerHTML={{ __html: page.templateFields?.contentText }}
        />
      )}
      {page.templateFields?.contentItems && (
        <ItemsWrapper>
          {page.templateFields.contentItems.map(
            (
              item: { title?: string; link?: string; itemColor?: string },
              index: number
            ) => {
              return (
                <ContentItem
                  key={index}
                  color={item.itemColor}
                  to={item.link}
                  target="_blank"
                >
                  <ItemText>{item.title}</ItemText>
                  <SvgWrapper>
                    <ArrowSvg />
                  </SvgWrapper>
                </ContentItem>
              );
            }
          )}
        </ItemsWrapper>
      )}
    </Component>
  );
}

export default EcoContent;

const Component = styled.div`
  max-width: 1360px;
  margin: 0 auto 20vh;
  box-shadow: ${(props) => props.theme.blackShadow100};
  display: flex;
  padding: 40px;
  background: ${(props) => props.theme.white_1};

  @media (max-width: 1000px) {
    padding: 20px;
  }
`;

const TextContent = styled.div`
  max-width: 300px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  color: ${(props) => props.theme.blue100};

  h2 {
    font-size: 16px;
  }

  @media (max-width: 1350px) {
    display: none;
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  margin: 0 20px;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1350px) {
    margin: 0 auto;
  }

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const ContentItem = styled(Link)<{ color?: string }>`
  position: relative;
  width: 280px;
  height: 340px;
  padding: 40px;
  margin: 15px 12px 0;
  border-radius: 8px 45px 8px 8px;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}

  &:hover {
    transform: translateY(-10px);
    box-shadow: ${(props) => props.theme.blackShadow100};
  }

  @media (max-width: 960px) {
    width: 100%;
    height: 166px;
    margin: 15px 0 0 0;
  }

  @media (max-width: 500px) {
    padding: 14px 20px;
  }
`;

const ItemText = styled.div`
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  text-transform: uppercase;
  text-align: center;
  word-wrap: break-word;
  color: ${colors.white};

  @media (max-width: 960px) {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
  }
`;

const SvgWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  left: 40px;

  svg {
    height: 24.59px;
    width: 47.16px;

    path {
      fill: ${colors.white};
      fill-opacity: 1;
    }
  }

  @media (max-width: 960px) {
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
