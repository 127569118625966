import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { useSpecialOfferData } from '@/modules/SpecialOffer/SpecialOffer.hooks';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';

function Banner() {
  const { heroImage, heroMobileImage, heroTitle, heroSubtitle, heroSpecial } =
    useSpecialOfferData();
  const isTabletSmall = useMedia('(max-width: 1023.9px)');

  return (
    <Component>
      <HeroContainer>
        {isTabletSmall ? (
          <HeroMobile
            loading="lazy"
            src={heroMobileImage?.url}
            src2x={heroMobileImage?.url_2x}
            srcWebp={heroMobileImage?.url_webp}
            srcWebp2x={heroMobileImage?.url_webp_2x}
          />
        ) : (
          <HeroDesktop
            loading="lazy"
            src={heroImage?.url}
            src2x={heroImage?.url_2x}
            srcWebp={heroImage?.url_webp}
            srcWebp2x={heroImage?.url_webp_2x}
          />
        )}

        <ContentInner>
          <Container>
            <TitleContainer>
              <Title>{heroTitle}</Title>
              <Subtitle
                dangerouslySetInnerHTML={{
                  __html: heroSubtitle ?? '',
                }}
              />
            </TitleContainer>
          </Container>
        </ContentInner>
      </HeroContainer>

      <Container>
        <TextContainer>
          <Text>{heroSpecial}</Text>
        </TextContainer>
      </Container>
    </Component>
  );
}

export default Banner;

const Component = styled.div``;

const HeroContainer = styled.div`
  position: relative;
  padding-top: 24.26%;

  ${media.tabletSmall(css`
    padding-top: 71.26%;
  `)}
`;

const HeroDesktop = styled(Picture)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const HeroMobile = styled(HeroDesktop)``;

const ContentInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  height: 33vw;

  ${media.mobile(css`
    height: 66vw;
  `)}
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 40px;

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const Title = styled.h2`
  color: ${colors.white};
  font-size: 46px;
  text-transform: uppercase;
  padding-top: 6vw;
  line-height: 36px;

  ${media.laptop(css`
    padding-top: 4vw;
  `)}

  ${media.mobile(css`
    max-width: 410px;
    font-size: 32px;
    line-height: 36px;
  `)};

  @media (max-width: 425px) {
    font-size: 29px;
  }

  @media (max-width: 390px) {
    font-size: 26px;
  }

  @media (max-width: 355px) {
    font-size: 23px;
  }
`;

const Subtitle = styled.span`
  display: block;
  line-height: 56px;
  font-size: 41px;
  color: ${colors.white};
  padding-top: 55px;
  width: 700px;

  strong {
    font-weight: normal;
    color: ${colors.yellow};
  }

  ${media.laptop(css`
    padding-top: 30px;
  `)}

  ${media.tabletSmall(css`
    width: 500px;
    color: ${colors.yellow};
  `)}

  ${media.mobile(css`
    font-size: 20px;
    line-height: 27px;
    width: 200px;
    padding-top: 20px;
  `)}
`;

const TitleContainer = styled.div``;

const TextContainer = styled.div`
  margin-top: 50px;
`;

const Text = styled.span`
  display: block;
  font-size: 24px;
  line-height: 36px;
  color: ${(props) => props.theme.blue200};
  text-align: center;

  ${media.mobile(css`
    text-align: justify;
    text-indent: 25px;
    font-size: 18px;
    line-height: 24px;
  `)}
`;
