import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';

import { generateNumberArray, useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { ContentContainerSmall } from '@/components/ContentContainer';
import { useOnlineShoppingData } from '@/modules/OnlineShopping/OnlineShopping.hooks';
import { colors } from '@/constants/theme';
import ArrowIcon from '@/assets/svg/arrow-right-40px.svg';

import HowItWorksCard from './components/HowItWorksCard';

SwiperCore.use([Navigation, Pagination]);

function HowItWorks() {
  const { howItWorksTitle, howItWorksItems } = useOnlineShoppingData();
  const [currentStep, setCurrentStep] = useState(0);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const desktopMedia = useMedia('(min-width: 1260px)');

  const stepsArray = howItWorksItems
    ? generateNumberArray(howItWorksItems.length)
    : [1];

  const handleChangeActiveStep = (index: number) => {
    swiper?.slideTo(index);
    setCurrentStep(index);
  };

  useEffect(() => {
    if (!swiper) {
      return;
    }

    swiper.on('slideChange', () => {
      setCurrentStep(swiper.realIndex);
    });
  }, [swiper]);

  return (
    <Component>
      <ContentContainerSmall>
        <Title>{howItWorksTitle}</Title>

        {stepsArray.length > 1 && (
          <Steps>
            {stepsArray.map((index) => (
              <React.Fragment key={index}>
                <Bullet
                  isActive={index <= currentStep}
                  onClick={() => handleChangeActiveStep(index)}
                >
                  {index + 1}
                </Bullet>
                {stepsArray.length !== index + 1 && (
                  <Space isActive={index < currentStep} key={index} />
                )}
              </React.Fragment>
            ))}
          </Steps>
        )}

        <Slider>
          <Swiper
            onSwiper={setSwiper}
            slidesPerView={1}
            speed={700}
            spaceBetween={20}
            effect={'fade'}
            navigation={{
              prevEl: `.swiper-prev`,
              nextEl: `.swiper-next`,
            }}
            noSwipingClass={'swiper-no-swiping'}
          >
            {howItWorksItems &&
              howItWorksItems.map(({ text, image }, index) => (
                <SwiperSlide
                  key={index}
                  className={desktopMedia ? 'swiper-no-swiping' : ''}
                >
                  <HowItWorksCard text={text} image={image} />
                </SwiperSlide>
              ))}

            <NavButton className={`swiper-prev`} prev>
              <ArrowIcon />
            </NavButton>
            <NavButton className={`swiper-next`} next>
              <ArrowIcon />
            </NavButton>
          </Swiper>
        </Slider>
      </ContentContainerSmall>
    </Component>
  );
}

export default HowItWorks;

const Component = styled.section`
  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Steps = styled.div`
  display: flex;
  align-items: center;
  max-width: 512px;
  margin-top: 30px;

  ${media.tablet(css`
    max-width: 50%;
  `)}

  ${media.mobile(css`
    max-width: none;
  `)}
`;

const Bullet = styled.div<{ isActive: boolean }>`
  flex: 0 0 auto;
  width: 41px;
  height: 41px;
  font-size: 20px;
  line-height: 41px;
  font-weight: 700;
  box-shadow: 0 0 0 1px ${colors.gray} inset;
  transition: 0.15s;
  text-align: center;
  border-radius: 50%;
  color: ${(props) => props.theme.black200};
  cursor: pointer;

  ${media.tablet(css`
    font-size: 14px;
  `)}

  &:hover {
    opacity: 0.8;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${colors.main};
      box-shadow: none;
      color: ${colors.white};
    `};
`;

const Space = styled.div<{ isActive: boolean }>`
  flex: 1 1 auto;
  height: 1px;
  background: ${colors.gray};
  transition: 0.15s;

  ${({ isActive }) =>
    isActive &&
    css`
      background: ${colors.main};
    `};
`;

const Slider = styled.div`
  margin-top: 60px;

  ${media.tablet(css`
    margin-top: 20px;
  `)};

  @media (min-width: 1260px) {
    .swiper-wrapper {
      transform: translate3d(0, 0, 0) !important;
      min-height: 300px;
    }

    .swiper-container {
      position: relative;
    }

    .swiper-slide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s linear;
      transform: translate3d(0, 0, 0) !important;

      &-active {
        opacity: 1;
        visibility: visible;
        transition: all 0.3s linear 0.3s;
      }
    }
  }

  .swiper-button-disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`;

const NavButton = styled.button<{
  prev?: boolean;
  next?: boolean;
}>`
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: 40px;
  left: 0;
  border: 1px solid ${colors.main};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: 0.3s;
  cursor: pointer;

  ${(props) =>
    props.next &&
    css`
      left: 60px;
    `}

  &:hover {
    background: ${colors.main};

    svg {
      fill: ${colors.white};
    }
  }

  svg {
    width: 15px;
    height: 21px;
    transform: ${(props) => (props.prev ? 'rotate(180deg)' : 'rotate(0deg)')};
    fill: ${colors.main};
  }

  ${media.tablet(css`
    display: none;
  `)}
`;
