import React from 'react';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

import { useUpdateEffect } from '@tager/web-core';

import KeyboardArrowUpIcon from '@/assets/svg/keyboard_arrow_up.svg';
import KeyboardArrowDownIcon from '@/assets/svg/keyboard_arrow_down.svg';
import LockIcon from '@/assets/svg/lock.svg';
import { colors } from '@/constants/theme';

import useCollapse from './hooks/useCollapse';
import Tooltip from './components/Tooltip';

interface Props {
  title: string;
  children: React.ReactNode;
  disabled?: boolean;
  hintText?: string;
  className?: string;
}

function Collapse({ title, children, disabled, hintText, className }: Props) {
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse();

  useUpdateEffect(() => {
    if (disabled) {
      setExpanded(false);
    }
  }, [disabled]);

  return (
    <CollapseComponent className={className}>
      <Display {...getToggleProps({ disabled })}>
        <DisplayText>{title}</DisplayText>
        {disabled ? (
          <Tippy
            content={<Tooltip text={hintText} />}
            offset={[25, 10]}
            placement={'bottom-end'}
          >
            <DisplayIcon>
              <LockIcon />
            </DisplayIcon>
          </Tippy>
        ) : (
          <DisplayIcon isExpanded={isExpanded}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </DisplayIcon>
        )}
      </Display>
      <Content {...getCollapseProps()}>
        <ContentContainer>{children}</ContentContainer>
      </Content>
    </CollapseComponent>
  );
}

export default Collapse;

export const DisplayText = styled.span`
  flex: 1;
  margin-right: 20px;
  color: ${(props) => props.theme.black};
`;

export const CollapseComponent = styled.div`
  padding-bottom: 15px;
  padding-top: 15px;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.gray400};
  }
`;

export const Display = styled.button<{ disabled?: boolean }>`
  display: flex;
  align-items: center;

  width: 100%;

  font-weight: 500;
  font-size: 19px;
  line-height: 26px;
  color: ${colors.black200};
  text-align: left;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `};
`;

export const DisplayIcon = styled.span<{ isExpanded?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${(props) => props.theme.blue100};
  }

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      svg {
        fill: ${colors.main};
      }
    `}
`;

export const Content = styled.section``;

export const ContentContainer = styled.div`
  padding-top: 20px;
`;
