import React from 'react';
import styled, { css } from 'styled-components';

import { YearItem } from '@/modules/History/History.types';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function HistoryCard({ date, title, text, image }: YearItem) {
  return (
    <Component>
      <Content>
        <Date>{date}</Date>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Content>
      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
      />
    </Component>
  );
}

export default HistoryCard;

const Component = styled.div`
  display: flex;
  padding: 30px 0;

  ${media.tablet(css`
    justify-content: space-between;
  `)}

  ${media.mobile(css`
    padding: 20px 0;
    flex-direction: column-reverse;
  `)}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${media.mobile(css`
    padding: 0 20px;
    margin-top: 20px;
  `)}
`;

const Date = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray500};
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-top: 10px;

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-top: 30px;
  color: ${colors.blue600};

  ${media.tablet(css`
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
  `)}
`;

const Image = styled(Picture)`
  flex: 0 0 380px;
  max-width: 380px;
  max-height: 380px;
  margin-left: 30px;

  ${media.tablet(css`
    flex: 0 0 320px;
    max-width: 320px;
    max-height: 320px;
  `)}

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    max-height: none;
    margin-left: 0;
  `)}

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: contain;
  }
`;
