import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import Benefit from '../Benefit';

import { BenefitListProps } from './types';

function BenefitList({ benefits }: BenefitListProps) {
  if (!benefits || benefits.length === 0) {
    return null;
  }

  return (
    <Component>
      {benefits.map((benefit, index) => (
        <Benefit key={index} {...benefit} />
      ))}
    </Component>
  );
}

export default BenefitList;

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
  `)}
`;
