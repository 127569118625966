import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';

import { GuaranteeCardProps } from './types';

export function GuaranteeCard({
  title,
  description,
  image,
}: GuaranteeCardProps) {
  return (
    <Component>
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Content>

      <CarImage
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
        alt={title}
      />
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  align-items: center;
  padding: 30px;
  background: ${(props) => props.theme.gray_1};

  ${media.mobile(css`
    flex-direction: column;
    align-items: initial;
    padding: 20px;
  `)}
`;

const Content = styled.div`
  flex: 1 1 60%;
  max-width: 60%;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
  `)}
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.black};

  ${media.tabletSmall(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
  color: ${(props) => props.theme.blue100};

  ${media.tabletSmall(css`
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  `)}
`;

const CarImage = styled(Picture)`
  flex: 1 1 40%;
  max-width: 40%;
  padding-top: 21.93%;
  margin-left: 20px;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    margin: 20px 0 0;
    padding-top: 60%;
  `)}

  img,
  picture {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;
