import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useState,
} from 'react';
import styled from 'styled-components';

import { OfflineCheckedValuesModel } from '@/modules/ValueCar/components/OfflineValuation/components/ContactFormOfflineValuation/interfaces';

import DateComponent from './components/DateComponent';
import TimeComponent from './components/TimeComponent';

interface Props {
  dateRef: MutableRefObject<HTMLDivElement | null>;
  timeRef: MutableRefObject<HTMLDivElement | null>;
  checkedValues?: OfflineCheckedValuesModel;
  setCheckedValues?: Dispatch<SetStateAction<OfflineCheckedValuesModel>>;
}

function DateAndTime({
  dateRef,
  timeRef,
  checkedValues,
  setCheckedValues,
}: Props) {
  const [isShowTimePicker, setIsShowTimePicker] = useState(false);

  return (
    <Component>
      <Title>Дата и время</Title>
      <Container>
        <DateComponent
          dateRef={dateRef}
          setCheckedValues={setCheckedValues}
          checkedValues={checkedValues}
          isChecked={checkedValues?.dateCheck}
          setIsShowTimePicker={setIsShowTimePicker}
        />
        <TimeComponent
          timeRef={timeRef}
          setCheckedValues={setCheckedValues}
          checkedValues={checkedValues}
          isChecked={checkedValues?.timeCheck}
          isShowTimePicker={isShowTimePicker}
          setIsShowTimePicker={setIsShowTimePicker}
        />
      </Container>
    </Component>
  );
}

export default DateAndTime;

const Component = styled.div`
  text-align: left;
  margin-top: 30px;
  padding-bottom: 33px;
  border-bottom: 1px solid #ededed;
`;

const Title = styled.h2`
  font-size: 24px;
  line-height: 24px;
  color: ${(props) => props.theme.black};
`;

const Container = styled.div`
  margin-top: 40px;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
