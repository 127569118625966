import React from 'react';
import styled, { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { useMedia } from '@tager/web-core';
import { useModal } from '@tager/web-components';

import { ContentContainer } from '@/modules/ElectrocarsLanding/common.styles';
import { useElectrocarsLandingData } from '@/modules/ElectrocarsLanding/hooks';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { SliderWrapper } from '@/components/LandingWidgets';
import { Header as SliderWrapperHeader } from '@/components/LandingWidgets/components/SliderWrapper';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { submitElectrocarsLandingTestDrive } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';

import TestDriveCard from './components/TestDriveCard';

function TestDrive() {
  const openModal = useModal();
  const { testDriveItems } = useElectrocarsLandingData();
  const isTablet = useMedia('(max-width: 1259.9px)');

  if (!testDriveItems || testDriveItems.length === 0) {
    return null;
  }

  const isWithoutNavs = !isTablet ? testDriveItems.length === 1 : true;
  const isWithoutPagination = testDriveItems.length === 1 || !isTablet;
  const isNoSwiping = testDriveItems.length === 1;

  const sendApplicationForm = async (
    values: ApplicationFormFields,
    model: string,
    event: string
  ): Promise<void> => {
    try {
      await submitElectrocarsLandingTestDrive({ ...values, model }).then(() => {
        if (event) {
          googleEvent(event);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenModal = (title: string, model: string, event: string) => {
    openModal(
      ApplicationFormModal,
      {
        title,
        onSubmit: (values) => sendApplicationForm(values, model, event),
        fieldsConfig: { withoutEmail: true },
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component id="test-drive">
      <ContentContainer>
        <SliderWrapper
          id={2}
          options={{
            spaceBetween: 20,
            noSwipingClass: 'swiper-no-swiping',
            withoutNavs: isWithoutNavs,
            withoutPagination: isWithoutPagination,
          }}
        >
          {testDriveItems.map(
            (
              { title, text, buttonTitle, image, modalTitle, googleEvent },
              index
            ) => (
              <SwiperSlide
                key={index}
                className={isNoSwiping ? 'swiper-no-swiping' : ''}
              >
                <TestDriveCard
                  title={title ?? ''}
                  text={text ?? ''}
                  buttonTitle={buttonTitle ?? ''}
                  image={image}
                  onClick={() =>
                    handleOpenModal(
                      modalTitle ?? '',
                      title ?? '',
                      googleEvent ?? ''
                    )
                  }
                />
              </SwiperSlide>
            )
          )}
        </SliderWrapper>
      </ContentContainer>
    </Component>
  );
}

export default TestDrive;

const Component = styled.section`
  padding: 60px 0;
  background: ${colors.gray200};

  ${media.tablet(css`
    padding: 40px 0;
  `)}

  ${SliderWrapperHeader} {
    left: 0;
    top: 0;

    .swiper-prev2:not(.swiper-button-disabled),
    .swiper-next2:not(.swiper-button-disabled) {
      border: 1px solid ${colors.main};
    }
  }
`;
