import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import ArrowIcon from '@/assets/svg/arrow-down.svg';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';

import OptionsModal from './components/OptionsModal';
import { FeatureCardProps } from './types';

export function FeatureCard({
  image,
  video,
  title,
  description,
  options,
  isReverse,
}: FeatureCardProps) {
  const openModal = useModal();
  const [isOptionsOpened, setOptionsOpened] = useState(false);

  const handleOpenOptions = () => {
    setOptionsOpened(!isOptionsOpened);
  };

  const handleOpenOptionsModal = (activeSlideIndex: number) => {
    openModal(
      OptionsModal,
      {
        options,
        activeSlideIndex,
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component>
      <Content isReverse={isReverse}>
        {image ? (
          <Image
            loading="lazy"
            src={image?.url}
            src2x={image?.url_2x}
            srcWebp={image?.url_webp}
            srcWebp2x={image?.url_webp_2x}
            alt={title}
          />
        ) : (
          <VideoWrapper>
            <Video
              src={video + '#t=0.1'}
              preload="metadata"
              controls
              playsInline
            />
          </VideoWrapper>
        )}

        <Information>
          <Title>{title}</Title>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
          {options.length > 0 && (
            <MoreOptions
              onClick={handleOpenOptions}
              isOptionsOpened={isOptionsOpened}
            >
              Больше опций
              <ArrowIcon />
            </MoreOptions>
          )}
        </Information>
      </Content>

      {options.length > 0 && (
        <OptionsList isOpened={isOptionsOpened}>
          {options.map(({ image, title }, index) => (
            <Option
              key={index}
              onClick={() => handleOpenOptionsModal(index)}
              isOpened={isOptionsOpened}
            >
              <OptionImage
                loading="lazy"
                src={image?.url}
                src2x={image?.url_2x}
                srcWebp={image?.url_webp}
                srcWebp2x={image?.url_webp_2x}
                alt={title}
              />
              <OptionTitle>{title}</OptionTitle>
            </Option>
          ))}
        </OptionsList>
      )}
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
`;

const Content = styled.div<{
  isReverse?: boolean;
}>`
  display: flex;
  align-items: center;

  ${media.mobile(css`
    flex-direction: column;
    align-items: initial;
  `)}

  ${({ isReverse }) =>
    isReverse &&
    css`
      flex-direction: row-reverse;
    `};
`;

const Image = styled(Picture)`
  flex: 1 1 60%;
  max-width: 60%;
  padding-top: 30%;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    padding-top: 57.145%;
  `)}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  flex: 1 1 60%;
  max-width: 60%;
  padding-top: 30%;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    padding-top: 57.145%;
  `)}
`;

const Video = styled.video`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Information = styled.div`
  flex: 1 1 40%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 40px;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
    margin: 10px 0 0;
  `)}
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  color: ${(props) => props.theme.black};

  ${media.tabletSmall(css`
    font-size: 24px;
    line-height: 32px;
  `)};
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
  color: ${(props) => props.theme.black};

  ul,
  ol {
    padding-left: 16px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ${media.tabletSmall(css`
    margin-top: 10px;
  `)}
`;

const MoreOptions = styled.span<{
  isOptionsOpened?: boolean;
}>`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  margin-top: 15px;
  cursor: pointer;
  user-select: none;
  color: ${(props) => props.theme.black};

  svg {
    width: 11px;
    height: 11px;
    margin-left: 4px;

    path {
      fill: ${(props) => props.theme.black100};
    }
  }

  ${({ isOptionsOpened }) =>
    isOptionsOpened &&
    css`
      svg {
        transform: rotate(-180deg);
      }
    `};
`;

const OptionsList = styled.div<{ isOpened: boolean }>`
  display: flex;
  justify-content: center;

  ${media.mobile(css`
    flex-wrap: wrap;
  `)}

  ${({ isOpened }) =>
    isOpened &&
    css`
      margin-top: 15px;

      ${media.mobile(css`
        margin: 20px -10px 0;
      `)}
    `}
`;

const Option = styled.div<{ isOpened: boolean }>`
  display: none;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 17%;
  cursor: pointer;

  ${media.mobile(css`
    max-width: 46%;
    margin: 0 10px 20px;
  `)}

  &:not(:first-child) {
    margin-left: 20px;

    ${media.mobile(css`
      margin-left: 10px;
    `)}
  }

  ${({ isOpened }) =>
    isOpened &&
    css`
      display: flex;
    `}
`;

const OptionImage = styled(Picture)`
  padding-top: 58%;
  max-height: 58%;
  width: 100%;
  height: 100%;

  picture,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const OptionTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 5px;
  color: ${(props) => props.theme.black200_alpha40};
`;
