import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

import { PaymentCheckboxProps } from './types';
import VisaIcon from './assets/visa.svg?url';
import MasterCardIcon from './assets/mastercard.svg?url';
import BelCardIcon from './assets/belcard.svg?url';
import MirIcon from './assets/mir.svg?url';
import OplatiIcon from './assets/oplati.svg?url';
import EripIcon from './assets/erip.svg?url';

function PaymentCheckbox({
  label,
  checked,
  onChange,
  paymentType,
}: PaymentCheckboxProps) {
  return (
    <Component checked={checked}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange?.(e.target.checked)}
      />

      <Header>
        <Circle>
          <CircleInner checked={checked} />
        </Circle>

        <Label>{label}</Label>
      </Header>

      <Logos>
        {paymentType === 'OPLATI' ? (
          <img loading="lazy" src={OplatiIcon} alt="" />
        ) : paymentType === 'ERIP' ? (
          <img loading="lazy" src={EripIcon} alt="" />
        ) : (
          <React.Fragment>
            <img loading="lazy" src={VisaIcon} alt="" />
            <img loading="lazy" src={MasterCardIcon} alt="" />
            <img loading="lazy" src={BelCardIcon} alt="" />
            <img loading="lazy" src={MirIcon} alt="" />
          </React.Fragment>
        )}
      </Logos>
    </Component>
  );
}

export default PaymentCheckbox;

export const Component = styled.div<{ checked?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 20px 0;
  background: ${(props) => props.theme.white_1};
  border: 1px solid #ebecf0;
  border-radius: 10px;

  input {
    z-index: 1;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
  }

  ${({ checked }) =>
    checked &&
    css`
      background: ${(props) => props.theme.gray};
      border: 1px solid ${colors.main};
    `}
`;

const Header = styled.div`
  display: flex;
`;

const Circle = styled.div`
  padding: 3px;
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  border: 1px solid ${colors.gray500};
`;

const CircleInner = styled.div<{ checked?: boolean }>`
  width: 100%;
  height: 100%;

  ${({ checked }) =>
    checked &&
    css`
      background: ${colors.main};
      border-radius: 10px;
    `}
`;

const Label = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-left: 15px;
  color: ${(props) => props.theme.black200};
`;

const Logos = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 13px;

  img {
    margin: 10px 17px 10px 0;
  }
`;
