import React from 'react';
import styled from 'styled-components';

import Picture from '@/components/Picture';

import { BenefitProps } from './types';

function Benefit({ image, title, text }: BenefitProps) {
  return (
    <Component>
      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
      />
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Component>
  );
}

export default Benefit;

export const Component = styled.div`
  padding: 30px 20px;
  border: 1px solid ${(props) => props.theme.gray1300_alpha50};
  border-radius: 10px;
  background: ${(props) => props.theme.white_1};
`;

const Image = styled(Picture)`
  picture,
  img {
    width: 220px;
    height: 153px;
  }

  img {
    object-fit: cover;
  }
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  margin-top: 30px;
  color: ${(props) => props.theme.black200};
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  color: ${(props) => props.theme.blue100};
`;
