import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { useServicesData } from '@/modules/Services/Services.hooks';

function Bonus() {
  const data = useServicesData();

  if (data.isBonusHidden) {
    return null;
  }

  return (
    <Component>
      <Title>{data.bonusTitle ?? ''}</Title>
      <Description>{data.bonusDescription ?? ''}</Description>
      <Content
        dangerouslySetInnerHTML={{ __html: data.bonusTextField ?? '' }}
      />
    </Component>
  );
}

export default Bonus;

const Component = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 30px;

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  margin: 40px 0 20px;
  color: ${(props) => props.theme.black};

  @media (max-width: 700px) {
    font-size: 32px;
    line-height: 38px;
    margin: 20px 0 10px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.black};
`;

const Content = styled.div`
  margin-top: 20px;
  padding: 20px 30px;
  border-left: 3px solid ${colors.main};
  color: ${(props) => props.theme.black};
  background: ${(props) => props.theme.gray};

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.black200};
  }

  ul,
  ol {
    li {
      position: relative;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-indent: 15px;
      color: ${(props) => props.theme.black200};

      &:before {
        position: absolute;
        content: '';
        top: 9px;
        left: 0;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: ${(props) => props.theme.black};
      }
    }
  }

  blockquote {
    padding: 20px 30px 20px 27px;
    margin: 20px 0;
    border-left: 3px solid ${colors.main};
    background: ${(props) => props.theme.gray};

    ${media.tablet(css`
      margin: 25px 0;
    `)}

    p,
    ul,
    ol {
      margin: 0;
    }
  }
`;
