import React, { useRef } from 'react';
import styled, { css } from 'styled-components';

import { ContentContainerSmall } from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { ScrollStyles } from '@/components/styles';
import useCurrentPage from '@/hooks/useCurrentPage';
import { useFixedElement } from '@/hooks/useFixedElement';

import { LinkTabsProps } from './types';

function LinkTabs({ linksTabs }: LinkTabsProps) {
  const componentRef = useRef<HTMLDivElement>(null);
  const isFixed = useFixedElement(componentRef);
  const pagePath = useCurrentPage().path;

  return (
    <Component isFixed={isFixed} ref={componentRef}>
      <Wrapper isFixed={isFixed}>
        <ContentContainerSmall>
          <Tabs isFixed={isFixed}>
            {linksTabs &&
              linksTabs.map(({ text, link, isNewTab }, index) => (
                <Tab
                  activeTab={link === pagePath}
                  key={index}
                  to={link}
                  target={`${isNewTab ? '_blank' : '_self'}`}
                >
                  {text}
                </Tab>
              ))}
          </Tabs>
        </ContentContainerSmall>
      </Wrapper>
    </Component>
  );
}

export default LinkTabs;

const Component = styled.div<{ isFixed: boolean }>`
  z-index: 100;
  margin-top: -40px;
  min-height: 82px;
  overflow: hidden;

  ${media.tablet(css`
    min-height: 62px;
  `)}

  ${media.mobile(css`
    min-height: 82px;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)}

    ${media.mobile(css`
      padding: 0;
    `)}
  }
`;

const Wrapper = styled.div<{ isFixed: boolean }>`
  ${({ isFixed }) =>
    isFixed &&
    css`
      position: fixed;
      width: 100%;
      top: 0;
      background: ${(props) => props.theme.white_1};
      box-shadow: ${(props) => props.theme.blackShadow100};

      ${media.tablet(css`
        top: 80px;
      `)}

      ${media.mobile(css`
        top: 60px;
      `)}
    `};
`;

const Tabs = styled.div<{ isFixed: boolean }>`
  display: flex;
  background: ${(props) => props.theme.white_1};
  border-bottom: 1px solid ${colors.gray200};
  box-shadow: ${(props) => props.theme.blackShadow100};

  ${media.mobile(css`
    max-width: 767px;
    overflow-x: auto;
    overflow-y: hidden;

    ${ScrollStyles}
  `)}

  ${({ isFixed }) =>
    isFixed &&
    css`
      box-shadow: none;
      border: none;
    `};
`;

const Tab = styled(Link)<{ activeTab: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 30px;
  color: ${(props) => props.theme.black};

  &:not(:last-child) {
    border-right: 2px solid ${colors.gray200};
  }

  ${({ activeTab }) =>
    activeTab &&
    css`
      color: ${colors.main};
    `};

  &:hover {
    color: ${colors.main};
  }

  ${media.tablet(css`
    padding: 20px;
  `)}
`;
