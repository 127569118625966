import React from 'react';
import styled, { css } from 'styled-components';

import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import { media } from '@/utils/mixin';
import { FeatureCardProps } from '@/components/CarModelLandingWidgets/FeatureCard/types';
import { FeatureCard, Title } from '@/components/CarModelLandingWidgets';

function Features() {
  const { benefitsTitle, benefits } = useCarModelLandingData();

  if (!benefits || benefits.length === 0) {
    return null;
  }

  const convertedBenefits: FeatureCardProps[] = benefits.map(
    ({ image, video, title, description, options }, index) => ({
      image,
      video: video?.url ?? '',
      title: title ?? '',
      description: description ?? '',
      isReverse: index % 2 === 1,
      options: options.map(({ image, title, description }) => ({
        image,
        title: title ?? '',
        description: description ?? '',
      })),
    })
  );

  return (
    <Component id="features">
      <TitleWrapper>
        <Title title={benefitsTitle ?? ''} />
      </TitleWrapper>

      <FeatureList>
        {convertedBenefits.map((benefit, index) => (
          <FeatureCardWrapper key={index}>
            <FeatureCard {...benefit} />
          </FeatureCardWrapper>
        ))}
      </FeatureList>
    </Component>
  );
}

export default Features;

const Component = styled.section`
  padding: 60px 0;
  background: ${(props) => props.theme.gray_1};
`;

const TitleWrapper = styled.div`
  padding: 0 20px;
  margin: 0 auto;
  max-width: 470px;
`;

const FeatureList = styled.div`
  margin-top: 40px;

  ${media.tabletSmall(css`
    margin-top: 20px;
  `)}

  ${media.mobile(css`
    margin: 20px 20px 0;
  `)}
`;

const FeatureCardWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 60px;

    ${media.tabletSmall(css`
      margin-top: 50px;
    `)}
  }
`;
