import React from 'react';
import styled, { css } from 'styled-components';

import { RecommendationsItem } from '@/modules/OnlineShopping/OnlineShopping.types';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { ScrollStyles } from '@/components/styles';

function RecommendationCard({
  author,
  city,
  history,
  image,
  car,
}: RecommendationsItem) {
  return (
    <Component>
      <Content>
        <Header>
          <Author>{author}</Author>
          <City>({city})</City>
        </Header>

        <HistoryWrapper>
          <History>{history}</History>
        </HistoryWrapper>
      </Content>
      <ImageWrapper>
        <Image
          loading="lazy"
          src={image?.url}
          src2x={image?.url_2x}
          srcWebp={image?.url_webp}
          srcWebp2x={image?.url_webp_2x}
        />
        <Car>{car}</Car>
      </ImageWrapper>
    </Component>
  );
}

export default RecommendationCard;

const Component = styled.div`
  display: flex;
  min-height: 425px;

  ${media.mobile(css`
    flex-direction: column-reverse;
  `)}
`;

const Content = styled.div`
  position: relative;
  flex: 1 1 72%;
  padding: 60px 30px 100px 40px;
  background: ${(props) => props.theme.white_1};

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 172px;
    background: ${(props) => props.theme.whiteGradient100};

    ${media.mobile(css`
      height: 120px;
    `)}
  }
}

  ${media.mobile(css`
    padding: 20px 20px 76px;
  `)}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Author = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const City = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-left: 5px;
  color: ${(props) => props.theme.blue100};

  ${media.tablet(css`
    font-size: 14px;
    line-height: 20px;
  `)}
}
`;

const HistoryWrapper = styled.div`
  position: relative;
  height: 190px;
  margin-top: 20px;
  overflow-y: auto;

  ${ScrollStyles}
`;

const History = styled.p`
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-right: 20px;
  margin-bottom: 30px;
  color: ${(props) => props.theme.blue100};
`;

const ImageWrapper = styled.div`
  position: relative;
  flex: 1 1 29%;
`;

const Image = styled(Picture)`
  width: 100%;
  height: 100%;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const Car = styled.span`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 30px 20px;
  font-size: 14px;
  line-height: 20px;
  background: ${colors.white_alpha20};
  color: ${colors.white};

  ${media.mobile(css`
    padding: 20px;
  `)}
`;
