import React from 'react';
import styled, { css } from 'styled-components';

import { HistoryItem } from '@/modules/About/About.types';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface HistoryCardProps extends HistoryItem {
  reverse?: boolean;
}

function HistoryCard({
  image,
  backgroundImage,
  title,
  text,
  reverse,
}: HistoryCardProps) {
  return (
    <Component reverse={reverse}>
      <ImageWrapper>
        <Background
          loading="lazy"
          src={backgroundImage?.url}
          src2x={backgroundImage?.url_2x}
          srcWebp={backgroundImage?.url_webp}
          srcWebp2x={backgroundImage?.url_webp_2x}
        />
        {image && (
          <InnerImage
            loading="lazy"
            src={image?.url}
            src2x={image?.url_2x}
            srcWebp={image?.url_webp}
            srcWebp2x={image?.url_webp_2x}
          />
        )}
      </ImageWrapper>
      <Content>
        <Info reverse={reverse}>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </Info>
      </Content>
    </Component>
  );
}

export default HistoryCard;

const Component = styled.div<{ reverse: HistoryCardProps['reverse'] }>`
  display: flex;

  ${media.tablet(css`
    flex-direction: column;
  `)}

  &:not(:first-child) {
    margin-top: 90px;

    ${media.tablet(css`
      margin-top: 40px;
    `)}
  }

  ${({ reverse }) =>
    reverse &&
    css`
      flex-direction: row-reverse;
    `}
`;

const ImageWrapper = styled.div`
  position: relative;
  flex: 0 0 66%;
  max-width: 66%;
  height: 400px;

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    height: auto;
  `)}
`;

const Background = styled(Picture)`
  width: 100%;
  height: 100%;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const InnerImage = styled(Picture)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  img {
    ${media.mobile(css`
      max-width: 280px;
    `)}
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Info = styled.div<{ reverse: HistoryCardProps['reverse'] }>`
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 60px;
  width: 470px;
  min-height: 340px;
  margin-left: -60px;
  background-color: ${colors.white};
  box-shadow: 0 5px 30px 0 rgb(80 95 121 / 10%);

  ${media.tablet(css`
    box-shadow: none;
    margin: 20px 0 0;
    padding: 0 20px;
    width: auto;
    min-height: auto;
    text-align: left;
  `)}

  ${({ reverse }) =>
    reverse &&
    css`
      margin-right: -60px;
      text-align: right;
    `}
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  ${media.tablet(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
  color: ${colors.blue600};

  ${media.tablet(css`
    margin-top: 10px;
  `)}
`;
