import React from 'react';
import styled, { css } from 'styled-components';

import { useAtlantm30Data } from '@/modules/Atlantm30/Atlantm30.hooks';
import vector1 from '@/modules/Atlantm30/assets/vector1.png';
import vector2 from '@/modules/Atlantm30/assets/vector2.png';
import { colors } from '@/constants/theme';
import { ContentContainer } from '@/modules/Atlantm30/Atlantm30.styles';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';

import QuestionIcon from './assets/question.svg';
import QuestionAccordion from './components/QuestionAccordion';

function Help() {
  const {
    helpTitle,
    helpDescription,
    helpLinkTitle,
    helpLink,
    helpQuestionItems,
  } = useAtlantm30Data();

  if (!helpQuestionItems) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Content>
          <Left>
            <Title>{helpTitle}</Title>
            <Description>{helpDescription}</Description>
            <Rules to={helpLink}>{helpLinkTitle}</Rules>
            <StyledQuestionIcon />
          </Left>
          <Right>
            {helpQuestionItems.map(({ question, answer }, index) => (
              <QuestionAccordion
                key={index}
                question={question}
                answer={answer}
              />
            ))}
          </Right>
        </Content>
      </ContentContainer>
    </Component>
  );
}

export default Help;

const Component = styled.section`
  position: relative;
  margin: 60px 0;
  background: ${colors.yellow};

  &:before {
    content: '';
    display: inline-block;
    margin-top: -46px;
    width: 100%;
    height: 46px;
    background-image: url('${vector1}');
    background-repeat: no-repeat;
    background-position: right top;
  }

  &:after {
    content: '';
    display: inline-block;
    margin-bottom: -46px;
    width: 100%;
    height: 46px;
    background-image: url('${vector2}');
    background-repeat: no-repeat;
    background-position: right top;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Left = styled.div`
  position: relative;
  padding: 29px 0 0 50px;
  max-width: 320px;

  ${media.tabletSmall(css`
    padding: 70px 0 0 50px;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: ${colors.main};

  ${media.tabletSmall(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 49px 0 1.5vw;
  color: ${colors.blue600};

  ${media.tabletSmall(css`
    margin: 60px 0 1.5vw;
  `)}
`;

const Rules = styled(Link)`
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid transparent;
  color: ${colors.main};

  &:hover {
    border-color: ${colors.main};
  }
`;

const StyledQuestionIcon = styled(QuestionIcon)`
  position: absolute;
  top: 0;
  left: -15px;
  height: 162px;
`;

const Right = styled.div`
  flex: 1;
  margin-left: 80px;

  ${media.mobile(css`
    width: 100%;
    margin: 20px 0 0;
  `)}
`;
