import React from 'react';
import styled, { css } from 'styled-components';

import { StatsItem } from '@/modules/About/About.types';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function StatsCard({ number, description, image }: StatsItem) {
  return (
    <Component>
      <Content>
        <Title>{number}</Title>
        <Description>{description}</Description>
      </Content>
      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
      />
    </Component>
  );
}

export default StatsCard;

const Component = styled.div`
  position: relative;
  overflow: hidden;
  padding: 60px;
  background-color: ${colors.gray200};

  ${media.tablet(css`
    padding: 20px;
  `)}
`;

const Content = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 230px;

  ${media.tablet(css`
    max-width: 260px;
  `)}

  ${media.mobile(css`
    max-width: 185px;
  `)}
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
  color: ${colors.blue600};

  ${media.tablet(css`
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  `)}
`;

const Image = styled(Picture)`
  position: absolute;
  right: 0;
  bottom: 0;

  img,
  picture {
    width: 236px;
    height: 205px;

    ${media.tablet(css`
      width: 140px;
      height: 123px;
    `)}
  }
`;
