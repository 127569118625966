import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { useJacHomeData } from '@/modules/JacHome/hooks';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { ContentContainer } from '@/modules/JacHome/common.styles';
import { media } from '@/utils/mixin';
import { TestDriveCard } from '@/components/LandingWidgets';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import { submitJacTestDriveForm } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';
import { getRequestSource } from '@/utils/common';

function TestDriveBanner() {
  const openModal = useModal();
  const {
    testDriveTitle,
    testDriveText,
    testDriveImage,
    testDriveMobileImage,
    testDriveButtonText,
    testDriveFormTitle,
    testDriveGoogleEvent,
  } = useJacHomeData();

  const sendApplicationForm = async (
    values: ApplicationFormFields
  ): Promise<void> => {
    try {
      await submitJacTestDriveForm({
        ...values,
        source: getRequestSource(),
      }).then(() => {
        if (testDriveGoogleEvent) {
          googleEvent(testDriveGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenApplicationFormModal = () => {
    openModal(
      ApplicationFormModal,
      {
        title: testDriveFormTitle ?? '',
        onSubmit: sendApplicationForm,
        fieldsConfig: { withoutEmail: true },
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component>
      <ContentContainer>
        <TestDriveCard
          title={testDriveTitle ?? ''}
          description={testDriveText ?? ''}
          image={testDriveImage}
          imageMobile={testDriveMobileImage}
          buttonText={testDriveButtonText ?? ''}
          onSubmit={handleOpenApplicationFormModal}
        />
      </ContentContainer>
    </Component>
  );
}

export default TestDriveBanner;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}
`;
