import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainer } from '@/modules/Atlantm30/Atlantm30.styles';
import { colors } from '@/constants/theme';
import { useAtlantm30Data } from '@/modules/Atlantm30/Atlantm30.hooks';
import { media } from '@/utils/mixin';

import NumberIcon from './assets/nine.svg';
import Brand from './components/Brand';

function Brands() {
  const { brandsTitle, brandsText, brandsItems } = useAtlantm30Data();

  if (!brandsItems) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Content>
          <Left>
            <Title>{brandsTitle}</Title>
            <Text>{brandsText}</Text>
            <StyledNumberIcon />
          </Left>
          <Right>
            {brandsItems.map(({ image, name, link }, index) => (
              <Brand key={index} image={image} name={name} link={link} />
            ))}
          </Right>
        </Content>
      </ContentContainer>
    </Component>
  );
}

export default Brands;

const Component = styled.section`
  padding-bottom: 60px;
  background: ${colors.white};
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Left = styled.div`
  position: relative;
  padding: 29px 0 0 50px;
  max-width: 290px;

  ${media.tabletSmall(css`
    padding: 50px 0 0 50px;
  `)}
`;

const Title = styled.p`
  position: relative;
  z-index: 1;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: ${colors.main};

  ${media.tabletSmall(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const Text = styled.p`
  position: relative;
  z-index: 1;
  font-size: 16px;
  line-height: 24px;
  margin-top: 50px;
  color: ${colors.blue600};
`;

const StyledNumberIcon = styled(NumberIcon)`
  position: absolute;
  top: 0;
  left: -15px;
  height: 162px;
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin-left: 80px;

  & > a {
    width: 33.3%;
  }

  ${media.mobile(css`
    width: 100%;
    margin-left: 0;
  `)}
`;
