import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import {
  selectOnlineUserChoice,
  setOnlineUserChoice,
} from '@/store/reducers/online-valuation-user-data';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import RateCarSuccessPage from '@/components/RateCarSuccessPage/RateCarSuccessPage';
import FormPageHeader from '@/modules/ValueCar/components/FormPageHeader';
import { setSellCarLoading, setSellCarView } from '@/store/reducers/sell-car';
import useCurrentPage from '@/hooks/useCurrentPage';
import { Page } from '@/typings/page';
import { ValueCar } from '@/modules/ValueCar/ValueCar.types';
import ContactForm from '@/modules/ValueCar/components/ContactForm';
import { ButtonPrimary } from '@/components/CarCard/components/Button';
import { media } from '@/utils/mixin';
import { ContactFormOfflineValuationModel } from '@/modules/ValueCar/components/OfflineValuation/components/ContactFormOfflineValuation/interfaces';
import { getClearedOfflineData } from '@/modules/ValueCar/components/OfflineValuation/components/ContactFormOfflineValuation/helpers';
import { setOfflineUserChoice } from '@/store/reducers/offline-valuation-user-data';
import PhotosSection from '@/modules/ValueCar/components/OnlineValuation/components/PhotosSection';
import { scrollToDomElement } from '@/utils/scroll';
import { handleValidateContactForm } from '@/modules/ValueCar/utils';
import { googleEvent } from '@/utils/events';
import { submitRateCarOnline } from '@/services/requests';

import { OnlineCheckedValuesModel } from './interfaces';
import {
  checkIsFillOnlineValues,
  checkIsScrollNeed,
  checkUserValue,
  getClearedOnlineData,
  getOnlineDataWithValues,
} from './helpers';
import ChosenCarsInfo from './components/ChosenCarsInfo';

function OnlineValuation() {
  const pageFields = useCurrentPage<Page<ValueCar>>().templateFields;

  const initPhotosValue: (string | null)[][] = [];
  if (pageFields.onlinePhotos) {
    pageFields.onlinePhotos.forEach((section) => {
      const array = [];
      for (let i = 0; i < section.items.length; i++) {
        array.push(null);
      }
      initPhotosValue.push(array);
    });
  }

  const dispatch = useTypedDispatch();
  const userChoice = useTypedSelector(selectOnlineUserChoice);

  const formRef = useRef<HTMLDivElement | null>(null);
  const bodyTypeRef = useRef<HTMLDivElement | null>(null);
  const generationRef = useRef<HTMLDivElement | null>(null);
  const parametersRef = useRef<HTMLDivElement | null>(null);

  const [photos, setPhotos] = useState<(string | null)[][]>(initPhotosValue);
  const [invalidPhotosIndexes, setInvalidPhotoIndexes] = useState<
    Array<{ section: number; index: number }>
  >([]);

  const [isDataSanded, setIsDataSanded] = useState(false);

  const [checkedValues, setCheckedValues] = useState<OnlineCheckedValuesModel>({
    carDataCheck: true,
    bodyTypeCheck: true,
    generationCheck: true,
    parametersCheck: true,
  });

  const chosenData = useTypedSelector(selectOnlineUserChoice);

  useEffect(() => {
    dispatch(
      setOnlineUserChoice(
        getOnlineDataWithValues(
          userChoice.brand,
          userChoice.model,
          userChoice.year,
          userChoice.bodyType,
          userChoice.generation,
          userChoice.parameters.fuel,
          userChoice.parameters.gearBox,
          userChoice.parameters.enginePower,
          userChoice.parameters.engineVolume
        )
      )
    );
  }, []);

  async function onSubmit(values: ContactFormOfflineValuationModel) {
    if (
      !generationRef.current ||
      !formRef.current ||
      !bodyTypeRef.current ||
      !parametersRef.current
    ) {
      return;
    }

    const checkedValues = checkUserValue(chosenData);
    setCheckedValues(checkedValues);
    checkIsScrollNeed(
      formRef,
      generationRef,
      bodyTypeRef,
      parametersRef,
      checkedValues
    );

    const isFillValues = checkIsFillOnlineValues(checkedValues);

    if (!isFillValues) {
      return;
    }

    const invalidIds: Array<{ section: number; index: number }> = [];

    pageFields.onlinePhotos.forEach((section, sectionInd) => {
      section.items.forEach((item, itemInd) => {
        if (item.required && photos[sectionInd][itemInd] === null) {
          invalidIds.push({
            section: sectionInd,
            index: itemInd,
          });
        }
      });
    });

    setInvalidPhotoIndexes(invalidIds);

    if (invalidIds.length) {
      const firstSectionIndex = invalidIds[0].section;
      const domSectionNode = document.getElementById(
        'photos-row-' + firstSectionIndex
      );

      if (domSectionNode) {
        scrollToDomElement(domSectionNode);
      }

      return;
    }

    try {
      dispatch(setSellCarLoading(true));

      const images: string[] = [];
      photos.forEach((item) =>
        item.forEach((image) => image && images.push(image))
      );

      await submitRateCarOnline({
        brand: chosenData.brand,
        model: chosenData.model,
        year: Number(chosenData.year),
        body: chosenData.bodyType,
        generation: chosenData.generation,
        fuel: chosenData.parameters.fuel,
        gearbox: chosenData.parameters.gearBox,
        enginePower: chosenData.parameters.enginePower,
        engineVolume: chosenData.parameters.engineVolume,
        images,
        name: values.name,
        phone: values.phone,
        email: values.email,
      }).then(() => {
        if (pageFields.onlineGoogleEvent) {
          googleEvent(pageFields.onlineGoogleEvent);
        }
      });

      window.scrollTo({ top: 0, behavior: 'smooth' });

      setIsDataSanded(true);
      dispatch(setOfflineUserChoice(getClearedOfflineData()));
      dispatch(setOnlineUserChoice(getClearedOnlineData()));

      dispatch(setSellCarLoading(false));
    } catch (error: any) {
      console.log(error);
      toast.error(error?.message);
      dispatch(setSellCarLoading(false));
    }
  }

  return (
    <Component>
      {isDataSanded ? (
        <RateCarSuccessPage
          title={pageFields.onlineSuccessTitle}
          text={pageFields.onlineSuccessText}
        />
      ) : (
        <Container>
          <FormPageHeader
            formRef={formRef}
            title={pageFields.onlineTitle || ''}
            isChecked={checkedValues.carDataCheck}
            switchTitle={pageFields.onlineSwitchText || ''}
            switchLabel={pageFields.onlineSwitchButton || ''}
            onSwitch={() => dispatch(setSellCarView('OFFLINE'))}
          />
          <Formik<ContactFormOfflineValuationModel>
            initialValues={{ name: '', phone: '', email: '' }}
            onSubmit={onSubmit}
            validate={handleValidateContactForm}
            validateOnChange
          >
            {({
              handleSubmit,
              values,
              errors,
              handleChange,
              handleBlur,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <ChosenCarsInfo
                  bodyTypeRef={bodyTypeRef}
                  generationRef={generationRef}
                  parametersRef={parametersRef}
                  isBodyTypeChecked={checkedValues.bodyTypeCheck}
                  isGenerationChecked={checkedValues.generationCheck}
                  isParametersChecked={checkedValues.parametersCheck}
                  checkedValues={checkedValues}
                  setCheckedValues={setCheckedValues}
                />
                {pageFields.onlinePhotos
                  ? pageFields.onlinePhotos.map((item, sectionInd) => {
                      const invalidIds = invalidPhotosIndexes
                        .filter((item) => item.section === sectionInd)
                        .map((item) => item.index);
                      return (
                        <PhotosRow
                          key={sectionInd}
                          id={`photos-row-${sectionInd}`}
                          isLast={
                            sectionInd === pageFields.onlinePhotos.length - 1
                          }
                        >
                          <PhotosSection
                            model={item}
                            invalidIds={invalidIds}
                            onChange={(ind, value) => {
                              const newPhotos = [
                                ...photos.slice(0, sectionInd),
                                [
                                  ...photos[sectionInd].slice(0, ind),
                                  value,
                                  ...photos[sectionInd].slice(ind + 1),
                                ],
                                ...photos.slice(sectionInd + 1),
                              ];
                              setPhotos(newPhotos);
                            }}
                          />
                        </PhotosRow>
                      );
                    })
                  : null}

                <ContactForm
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched}
                />
                <ButtonRow>
                  <Button
                    type="submit"
                    disabled={
                      !values.name ||
                      Boolean(errors.phone) ||
                      Boolean(errors.email)
                    }
                  >
                    Отправить
                  </Button>
                </ButtonRow>
              </form>
            )}
          </Formik>
        </Container>
      )}
    </Component>
  );
}

export default OnlineValuation;

const Component = styled.div`
  margin-bottom: 80px;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled(ButtonPrimary)`
  padding: 10px 0;
  width: 384px;
  margin-top: 26px;
  font-size: 16px;
  border-radius: 8px;

  &[disabled] {
    opacity: 0.4;
  }

  ${media.mobile(css`
    width: 100%;
    margin-top: 32px;
  `)}
`;

const Container = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const PhotosRow = styled.div<{ isLast: boolean }>`
  margin-top: 50px;
  padding-bottom: ${(props) => (props.isLast ? '30px' : 0)};
  border-bottom: ${(props) => (props.isLast ? '1px solid #ededed' : 0)};
`;
