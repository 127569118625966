import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

import { CarComplectationInfoProps } from './types';

export function CarComplectationInfo({
  title,
  list,
  image,
}: CarComplectationInfoProps) {
  return (
    <Component>
      <Info>
        <Title>{title}</Title>
        <List>
          {list.map((listItem, index) => (
            <ListItem key={index}>{listItem}</ListItem>
          ))}
        </List>
      </Info>
      {image && (
        <Image
          loading="lazy"
          src={image?.url}
          src2x={image?.url_2x}
          srcWebp={image?.url_webp}
          srcWebp2x={image?.url_webp_2x}
          alt={title}
        />
      )}
    </Component>
  );
}

const Component = styled.div`
  display: flex;

  ${media.tabletSmall(css`
    flex-direction: column-reverse;
  `)}
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  flex: 0 0 400px;
  background: ${(props) => props.theme.gray_1};

  ${media.tabletSmall(css`
    flex: auto;
    padding: 20px;
  `)}
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => props.theme.black};

  ${media.tabletSmall(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`;

const List = styled.ul`
  margin-top: 20px;
`;

const ListItem = styled.li`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.black};

  &:before {
    display: inline-block;
    content: '–';
    margin-right: 2px;
  }

  &:not(:first-child) {
    margin-top: 5px;
  }
`;

const Image = styled(Picture)`
  flex: 1;
  padding-top: 55.7%;

  ${media.tabletSmall(css`
    padding-top: 85.1%;
  `)}

  picture,
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;
