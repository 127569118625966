import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import CarSelectionHelperDie from '@/components/CarSelectionHelperDie';
import Link from '@/components/Link';

export const StylesHoverLink = css`
  position: relative;

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &:before {
    position: absolute;
    display: block;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    border-radius: 5px;
    background: ${colors.main};
    opacity: 0;
    transition: opacity 0.4s;
  }
`;

export const StyledButton = styled(Link)`
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  background-color: ${colors.main};
  text-transform: uppercase;
  text-align: center;
  color: ${colors.white};
  padding: 13px 28px;
  border: none;
  border-radius: 1.25rem;
  outline: none;
  width: 100%;

  &:hover {
    background-color: ${colors.main100};
    border: none;
  }
`;

export const StyledCarSelectionHelperDie = styled(CarSelectionHelperDie)`
  max-width: 335px;
`;
