import React from 'react';
import styled, { css } from 'styled-components';

import { useTopLevelFunnelLandingData } from '@/modules/TopLevelFunnelLanding/hooks';
import { AboutCardPrimary } from '@/components/LandingWidgets';
import { Image as AboutCardPrimaryImage } from '@/components/LandingWidgets/components/AboutCardPrimary';
import { ContentContainer } from '@/modules/TopLevelFunnelLanding/common.styles';
import { media } from '@/utils/mixin';

function About() {
  const {
    aboutImage,
    aboutTitle,
    aboutTopText,
    aboutBenefits,
    aboutBottomText,
  } = useTopLevelFunnelLandingData();

  if (!aboutImage || !aboutTitle || !aboutTopText) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <AboutCardPrimary
          id={2}
          image={aboutImage}
          title={aboutTitle}
          topText={aboutTopText}
          benefits={
            aboutBenefits && aboutBenefits.length !== 0
              ? aboutBenefits.map(({ image, text }) => ({
                  image,
                  text: text ?? '',
                }))
              : []
          }
          bottomText={aboutBottomText ?? ''}
        />
      </ContentContainer>
    </Component>
  );
}

export default About;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 0 0 20px;

    ${AboutCardPrimaryImage} {
      display: none;
    }
  `)}
`;
