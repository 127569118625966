import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { BenefitCardProps } from './types';

export function BenefitCard({ image, title, text }: BenefitCardProps) {
  return (
    <Component>
      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
        alt={title}
      />
      <Content>
        <Title>{title}</Title>
        {text && <Text>{text}</Text>}
      </Content>
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.mobile(css`
    flex-direction: row;
  `)}
`;

const Image = styled(Picture)`
  padding: 10px;
  border-radius: 50%;
  background: ${colors.gray200};

  picture,
  img {
    width: 40px;
    height: 40px;
  }

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  margin-top: 20px;
  text-align: center;

  ${media.mobile(css`
    margin: 0 0 0 20px;
    text-align: left;
  `)}
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.black};
`;
