import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';

import { CarComplectationProps } from './types';

export function CarComplectation({
  image,
  name,
  isActive = true,
  onClick,
}: CarComplectationProps) {
  return (
    <Component onClick={onClick}>
      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
        isActive={isActive}
        alt={name}
      />
      <Info>{name}</Info>
    </Component>
  );
}

const Component = styled.div`
  cursor: pointer;
`;

const Image = styled(Picture)<{ isActive: boolean }>`
  padding-top: 57.145%;

  img,
  picture {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }

  ${({ isActive }) =>
    !isActive &&
    css`
      opacity: 0.7;
    `};
`;

const Info = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 20px;
  color: ${(props) => props.theme.black};
`;
