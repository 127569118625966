import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import { useElectrocarsLandingEventMicromarking } from './hooks';
import Hero from './components/Hero';
import CatalogCars from './components/CatalogCars';
import TestDrive from './components/TestDrive';
import Benefits from './components/Benefits';
import PurchaseSteps from './components/PurchaseSteps';
import StockCars from './components/StockCars';
import Reviews from './components/Reviews';
import Chargers from './components/Chargers';
import News from './components/News';
import Form from './components/Form';

function ElectrocarsLanding() {
  const eventMicromarking = useElectrocarsLandingEventMicromarking();

  return (
    <Layout>
      <Component>
        <Hero />
        <CatalogCars />
        <TestDrive />
        <Benefits />
        <PurchaseSteps />
        <StockCars />
        <Reviews />
        <Chargers />
        <News />
        <Form />
      </Component>

      {eventMicromarking.map((event, index) => (
        <script
          key={index}
          id="electrocarsLandingEvent"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: event,
          }}
        />
      ))}
    </Layout>
  );
}

export default ElectrocarsLanding;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
