import React from 'react';
import styled, { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { useMedia } from '@tager/web-core';

import DetailedCarBaseSlider from '@/components/DetailedCarBaseSlider';
import PostCard from '@/components/PostCard';
import { useServicesData } from '@/modules/Services/Services.hooks';
import { useTypedSelector } from '@/store/store';
import { selectPostsListPosts } from '@/store/reducers/postsList';
import { media } from '@/utils/mixin';

function SharesSlider() {
  const sharesItems = useTypedSelector(selectPostsListPosts);
  const isMobile = useMedia('(max-width: 767.9px)');
  const data = useServicesData();

  if (data.isSharesHidden) {
    return null;
  }

  return (
    <Component>
      <Title>{data.sharesTitle ?? ''}</Title>
      {!sharesItems || !sharesItems.items.length ? null : (
        <>
          {isMobile ? (
            <MobileList>
              {sharesItems.items.map((item, index) => {
                return (
                  <MobileListItem key={index}>
                    <PostCard
                      title={item.title ?? ''}
                      url={item.url ?? ''}
                      image={item.image}
                    />
                  </MobileListItem>
                );
              })}
            </MobileList>
          ) : (
            <DetailedCarBaseSlider
              isButtonHidden={sharesItems.items.length < 4}
            >
              {sharesItems.items.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <PostCard
                      title={item.title ?? ''}
                      url={item.url ?? ''}
                      image={item.image}
                    />
                  </SwiperSlide>
                );
              })}
            </DetailedCarBaseSlider>
          )}
        </>
      )}
    </Component>
  );
}

export default SharesSlider;

const Component = styled.div`
  max-width: 1440px;
  margin: 20px auto 0;
  padding: 0 30px;
  position: relative;
  overflow: hidden;

  .swiper-slide {
    max-width: 360px;
    height: auto;
  }

  ${media.mobile(css`
    padding: 0 20px;
  `)}
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 52px;
  margin: 40px 0 20px;
  color: ${(props) => props.theme.black};

  @media (max-width: 767.9px) {
    font-size: 32px;
    line-height: 38px;
    margin: 20px 0 10px;
  }
`;

const MobileList = styled.div``;

const MobileListItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;
