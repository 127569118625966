import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { useTypedSelector } from '@/store/store';
import { selectStockCars } from '@/store/reducers/special-offer';
import LandingCarCard from '@/components/LandingCarCard';
import { media } from '@/utils/mixin';
import { useSpecialOfferData } from '@/modules/SpecialOffer/SpecialOffer.hooks';
import { getCatalogItems } from '@/store/reducers/catalogNew';
import { switchNamesItems } from '@/modules/DetailedCarModule/DetailedCar.helper';
import defaultStockCar from '@/assets/images/defaultStockCar.png';
import { getCatalogCarsByIds } from '@/utils/common';

function CarsGrid() {
  const { mayLikeDataCatalog, mayLikeDataStockNew } = useSpecialOfferData();
  const stockCars = useTypedSelector(selectStockCars);
  const catalogCars = getCatalogCarsByIds(
    useTypedSelector(getCatalogItems).catalogItemsData,
    mayLikeDataCatalog ?? []
  );
  const isTablet = useMedia('(max-width: 1259px)');

  if (
    (!stockCars || stockCars.length === 0) &&
    (!catalogCars || catalogCars.length === 0)
  ) {
    return null;
  }

  return (
    <Component>
      {stockCars.map((car, index) => {
        const title = `${car.brand.name} ${car.model.name}`;
        const link = `/cars/new/${[mayLikeDataStockNew ?? []][index]}`;
        const images =
          car.images.length !== 0
            ? car.images
                .slice(0, 5)
                .map(
                  (image) => image.gallery?.url_webp ?? image.gallery?.url ?? ''
                )
            : [defaultStockCar];

        return (
          <LandingCarCard
            key={index}
            images={images}
            title={title}
            subtitle={
              car.params.bodyType ? switchNamesItems(car.params.bodyType) : ''
            }
            price={car.price ?? 0}
            link={link}
            isMobile={isTablet}
          />
        );
      })}

      {catalogCars.map((car, index) => {
        const title = `${car.brand.name} ${car.model.name}`;
        const link = `/cars/new/${car.brand.alias}/${car.model.alias}`;
        const images =
          car.colors && car.colors.length > 0
            ? [
                ...car.colors
                  .slice(0, 5)
                  .map(({ images }) =>
                    images && images.length > 0
                      ? images
                          .slice(0, 5)
                          .map(
                            (image) =>
                              image.desktop?.url_webp ??
                              image.desktop?.url ??
                              defaultStockCar
                          )
                      : [defaultStockCar]
                  )
                  .flat(1),
              ].slice(0, 5)
            : car.images && car.images.length > 0
            ? car.images
                .slice(0, 5)
                .map(
                  (image) =>
                    image.desktop?.url_webp ??
                    image.desktop?.url ??
                    defaultStockCar
                )
            : [defaultStockCar];

        return (
          <LandingCarCard
            key={index}
            images={images}
            title={title}
            subtitle={car.body?.name ?? ''}
            price={car.stock.minPrice ?? 0}
            pricePrefix="от"
            link={link}
            isMobile={isTablet}
          />
        );
      })}
    </Component>
  );
}

export default CarsGrid;

const Component = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 40px;

  ${media.mobile(css`
    grid-template-columns: repeat(2, 1fr);
  `)};

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
