import React from 'react';
import styled, { css } from 'styled-components';

import FaqAccordion from '@/components/FaqAccordion';
import useCurrentPage from '@/hooks/useCurrentPage';
import { Page } from '@/typings/page';
import { ValueCar } from '@/modules/ValueCar/ValueCar.types';
import { media } from '@/utils/mixin';

function Questions() {
  const pageFields = useCurrentPage<Page<ValueCar>>().templateFields;

  return (
    <Component>
      <Container>
        <Content>
          <Title>{pageFields.faqBlockTitle}</Title>
          <QuestionsContainer>
            <FaqAccordion data={pageFields.faqBlockItems} />
          </QuestionsContainer>
        </Content>
      </Container>
    </Component>
  );
}

export default Questions;

const Component = styled.div`
  background: ${(props) => props.theme.gray};
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 40px 25px 40px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 0 20px 25px 20px;
  }
`;

const Content = styled.div`
  padding-top: 20px;
`;

const Title = styled.h2`
  font-size: 42px;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 36px;
  `)};

  ${media.mobile(css`
    font-size: 30px;
  `)};
`;

const QuestionsContainer = styled.div`
  margin-top: 30px;

  ${media.mobile(css`
    margin-top: 20px;
  `)};
`;
