import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { isBrowser } from '@tager/web-core';

import { useHelpOnTheRoadContext } from '@/modules/HelpOnTheRoad/hooks';

import Hero from './components/Hero';
import Benefits from './components/Benefits';
import Packages from './components/Packages';
import Rules from './components/Rules';
import ConsultationForm from './components/ConsultationForm';
import Faq from './components/Faq';
import PackageForm from './components/PackageForm';
import Success from './components/Success';

function Main() {
  const router = useRouter();
  const { selectedPackage, setSelectedPackage } = useHelpOnTheRoadContext();

  const email = isBrowser()
    ? localStorage?.getItem?.('helpOnTheRoadEmail')
    : null;

  if (router.query && !router.query.success && router.query.id && email) {
    const orderId = (() => {
      if (Array.isArray(router.query.id)) {
        return router.query.id[0];
      } else {
        return router.query.id;
      }
    })();

    return <Success email={email} orderId={orderId} />;
  }

  return (
    <Component>
      {!selectedPackage ? (
        <React.Fragment>
          <Hero />
          <Benefits />
          <Packages />
          <Rules />
          <ConsultationForm />
          <Faq />
        </React.Fragment>
      ) : (
        <PackageForm
          id={selectedPackage.systemName}
          title={selectedPackage.fullName}
          price={selectedPackage.price}
          handleBack={() => setSelectedPackage(null)}
        />
      )}
    </Component>
  );
}

export default Main;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
