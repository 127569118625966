import React from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import { PersonalCabinetInfoItem } from '@/modules/PersonalCabinet/PersonalCabinet.types';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface InfoListItemProps extends PersonalCabinetInfoItem {
  mobileAppLanding?: boolean;
}

function InfoListItem({ text, image, mobileAppLanding }: InfoListItemProps) {
  return (
    <Component mobileAppLanding={mobileAppLanding}>
      <Text dangerouslySetInnerHTML={{ __html: text ?? '' }} />
      {image && (
        <Image
          loading="lazy"
          src={image?.url}
          src2x={image?.url_2x}
          srcWebp={image?.url_webp}
          srcWebp2x={image?.url_webp_2x}
        />
      )}
    </Component>
  );
}

export default InfoListItem;

const Component = styled.div<{
  mobileAppLanding: InfoListItemProps['mobileAppLanding'];
}>`
  display: flex;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: 20px;
  }

  &:nth-child(3) > div:last-child {
    margin-top: -150px;

    ${media.mobile(css`
      margin-top: 0;
    `)}
  }

  ${media.mobile(css`
    flex-direction: column;
  `)}

  ${({ mobileAppLanding }) =>
    mobileAppLanding &&
    css`
      &:nth-child(3) > div:last-child {
        margin-top: -160px;
        max-width: 69%;

        @media (max-width: 906px) {
          margin-top: -80px;
        }

        ${media.mobile(css`
          max-width: none;
          margin-top: 0;
        `)}
      }

      &:nth-child(5) > div:last-child {
        margin-top: -120px;
        max-width: 69%;

        @media (max-width: 906px) {
          margin-top: -40px;
        }

        ${media.mobile(css`
          max-width: none;
          margin-top: 0;
        `)}
      }
    `}
`;

const Text = styled.div`
  flex: 1 1 835px;
  max-width: 835px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    flex: auto;
    max-width: none;
  `)}

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;

    ${media.mobile(css`
      font-size: 18px;
      line-height: 24px;
    `)}
  }

  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;

    ${media.tablet(css`
      font-size: 14px;
      line-height: 20px;
    `)}
  }

  ul {
    margin: 10px 0 20px;

    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-left: 20px;
      color: ${(props) => props.theme.blue100};

      ${media.tablet(css`
        font-size: 14px;
        line-height: 20px;
      `)}

      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 6px;
        height: 6px;
        background: ${(props) => props.theme.white};
        border-radius: 50%;
        border: 1px solid ${colors.main};
        margin-right: 10px;
        margin-left: -16px;
      }

      &:not(:first-child) {
        margin-top: 5px;

        ${media.tablet(css`
          margin-top: 0;
        `)}
      }
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.blue100};

    ${media.mobile(css`
      font-size: 14px;
      line-height: 20px;
    `)}

    a {
      color: ${colors.main};
      border-bottom: 1px solid transparent;

      &:hover {
        border-color: ${colors.main};
      }
    }
  }
`;

const Image = styled(Picture)`
  position: relative;
  flex: 0 0 300px;
  max-width: 300px;
  width: 100%;
  height: 100%;
  margin-left: 20px;

  ${media.tabletSmall(css`
    flex: 1 1 435px;
  `)}

  ${media.mobile(css`
    flex: 1 1 300px;
    max-width: 300px;
    margin: 0 auto;
  `)}

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;
