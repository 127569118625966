import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import styled from 'styled-components';

import { colors } from '@/constants/theme';
import { AllInclusiveItem } from '@/modules/Subscription/Subscription.types';
import AllInclusiveCard from '@/modules/Subscription/components/AllInclusive/components/AllInclusiveCard';

SwiperCore.use([Navigation]);

interface Props {
  items: AllInclusiveItem[];
}

function AllInclusiveCardSlider({ items }: Props) {
  return (
    <Component>
      <Swiper
        slidesPerView="auto"
        loop={true}
        speed={500}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        }}
      >
        {items.map(({ image, text }, index) => (
          <SwiperSlide key={index}>
            <AllInclusiveCard image={image} text={text} />
          </SwiperSlide>
        ))}
      </Swiper>

      <Pagination className="swiper-pagination" />
    </Component>
  );
}

export default AllInclusiveCardSlider;

const Component = styled.div`
  position: relative;
  width: 100%;
  margin-top: 30px;
`;

const Pagination = styled.div`
  width: 100%;
  bottom: -20px;

  .swiper-pagination-bullet {
    cursor: pointer;
    width: 7px;
    height: 7px;
    margin: 0 3px;
    background: ${(props) => props.theme.black};
  }

  .swiper-pagination-bullet-active {
    background: ${colors.main};
  }
`;
