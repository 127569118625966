import React from 'react';
import styled, { css } from 'styled-components';

import Button from '@/components/Button';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { TestDriveCardProps } from './types';

function TestDriveCard({
  title,
  text,
  buttonTitle,
  image,
  onClick,
}: TestDriveCardProps) {
  return (
    <Component>
      <Content>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <Actions>
          <StyledButton variant="contained" onClick={onClick}>
            {buttonTitle}
          </StyledButton>
        </Actions>
      </Content>

      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
      />
    </Component>
  );
}

export default TestDriveCard;

const Component = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.mobile(css`
    position: relative;
    flex-direction: column;
    align-items: initial;
    padding-bottom: 74px;
  `)}
`;

const Content = styled.div`
  flex: 0 0 358px;
  max-width: 358px;

  ${media.mobile(css`
    flex: auto;
    max-width: none;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 40px;
  line-height: 47px;

  ${media.tablet(css`
    font-size: 28px;
    line-height: 33px;
  `)}
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
  color: ${colors.blue600};
`;

const Actions = styled.div`
  margin-top: 30px;

  ${media.mobile(css`
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
  `)}
`;

const StyledButton = styled(Button)`
  max-width: 221px;

  ${media.mobile(css`
    max-width: 280px;
  `)}
`;

const Image = styled(Picture)`
  flex: 1;
  max-width: 61.5873%;
  padding-top: 38.7302%;
  margin-left: 20px;

  ${media.mobile(css`
    max-width: none;
    padding-top: 74.6269%;
    margin: 20px 0 0;
  `)}

  picture,
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  img {
    object-fit: cover;
  }
`;
