import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import SuccessIcon from '@/assets/svg/success.svg';
import { getHelpOnTheRoadSuccessInfo } from '@/services/orders/orders-service';
import Loader from '@/components/Loader';
import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';

import { SuccessProps } from './types';

function Success({ orderId, email }: SuccessProps) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { successTitle, successText } = useHelpOnTheRoadData();

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const response = await getHelpOnTheRoadSuccessInfo(orderId);
        setSuccess(response.status === 'COMPLETED');
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      localStorage.removeItem('helpOnTheRoadEmail');
    };
  }, []);

  return (
    <Component>
      {loading ? (
        <Loader />
      ) : (
        <>
          {success ? (
            <Container>
              <SuccessIconWrapper>
                <SuccessIcon />
              </SuccessIconWrapper>
              <Title>{successTitle ?? ''}</Title>
              <Text>
                {successText ? successText.replace('{{email}}', email) : ''}
              </Text>
            </Container>
          ) : (
            <Container>
              <Title>Ваш заказ {orderId} не оплачен</Title>
            </Container>
          )}
        </>
      )}
    </Component>
  );
}

export default Success;

const Component = styled.div`
  position: relative;
  padding: 100px 0 200px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessIconWrapper = styled.div``;

const Title = styled.span`
  margin-top: 15px;
  display: block;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${(props) => props.theme.black};
`;

const Text = styled.p`
  margin-top: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${(props) => props.theme.black100};
  opacity: 0.5;
`;
