import React from 'react';
import styled, { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';

import { useModal } from '@tager/web-components';

import { ContentContainer } from '@/modules/ElectrocarsLanding/common.styles';
import { useElectrocarsLandingData } from '@/modules/ElectrocarsLanding/hooks';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import {
  Header as SliderWrapperHeader,
  SliderWrapper,
} from '@/components/LandingWidgets/components/SliderWrapper';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import ChargerCard from '@/modules/ElectrocarsLanding/components/Chargers/components/ChargerCard';
import { submitElectrocarsLandingChargers } from '@/services/leads/leads-service';
import { googleEvent } from '@/utils/events';

function Chargers() {
  const openModal = useModal();
  const { chargersItems } = useElectrocarsLandingData();

  if (!chargersItems || chargersItems.length === 0) {
    return null;
  }

  const isWithoutNavs = chargersItems.length === 1;
  const isNoSwiping = chargersItems.length === 1;

  const sendApplicationForm = async (
    values: ApplicationFormFields,
    model: string,
    event: string
  ): Promise<void> => {
    try {
      await submitElectrocarsLandingChargers({ ...values, model }).then(() => {
        if (event) {
          googleEvent(event);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenModal = (title: string, model: string, event: string) => {
    openModal(
      ApplicationFormModal,
      {
        title,
        onSubmit: (values) => sendApplicationForm(values, model, event),
        fieldsConfig: { withoutEmail: true },
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component id="chargers">
      <ContentContainer>
        <SliderWrapper
          id={5}
          options={{
            spaceBetween: 20,
            noSwipingClass: 'swiper-no-swiping',
            withoutNavs: isWithoutNavs,
            withoutPagination: true,
          }}
        >
          {chargersItems.map(
            (
              {
                title,
                text,
                buttonTitle,
                buttonModalTitle,
                chargersCards,
                googleEvent,
              },
              index
            ) => (
              <SwiperSlide
                key={index}
                className={isNoSwiping ? 'swiper-no-swiping' : ''}
              >
                <ChargerCard
                  id={index + 10}
                  title={title ?? ''}
                  text={text ?? ''}
                  buttonTitle={buttonTitle ?? ''}
                  chargers={
                    chargersCards && chargersCards.length !== 0
                      ? chargersCards.map(
                          ({ image, name, title, description, price }) => ({
                            image,
                            name: name ?? '',
                            title: title ?? '',
                            text: description ?? '',
                            price: price ?? '',
                          })
                        )
                      : []
                  }
                  onClick={() =>
                    handleOpenModal(
                      buttonModalTitle ?? '',
                      title ?? '',
                      googleEvent ?? ''
                    )
                  }
                />
              </SwiperSlide>
            )
          )}
        </SliderWrapper>
      </ContentContainer>
    </Component>
  );
}

export default Chargers;

const Component = styled.section`
  padding: 60px 0;
  background: ${colors.gray200};

  ${media.tablet(css`
    padding: 80px 0 40px;
  `)}

  ${SliderWrapperHeader} {
    left: 0;
    top: 0;

    ${media.tablet(css`
      top: -40px;
      left: initial;
      right: 0;
    `)}

    .swiper-prev5:not(.swiper-button-disabled),
    .swiper-next5:not(.swiper-button-disabled) {
      border: 1px solid ${colors.main};
    }
  }
`;
