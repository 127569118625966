import React from 'react';
import styled, { css } from 'styled-components';

import { ContentContainerSmall } from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import { useInsuranceData } from '@/modules/Insurance/Insurance.hooks';

import InsuranceCard from './components/InsuranceCard';

function TypesOfInsurance() {
  const { insuranceTypesItems } = useInsuranceData();

  return (
    <Component>
      <ContentContainerSmall>
        <List>
          {(insuranceTypesItems ?? []).map(({ image, title, text }, index) => (
            <InsuranceCard
              key={index}
              image={image}
              title={title}
              text={text}
            />
          ))}
        </List>
      </ContentContainerSmall>
    </Component>
  );
}

export default TypesOfInsurance;

const Component = styled.section`
  padding: 90px 0;
  background: ${(props) => props.theme.gray};

  ${media.tablet(css`
    padding: 60px 0;
  `)}

  ${media.mobile(css`
    padding: 20px 0;
  `)}

  ${ContentContainerSmall} {
    padding: 0 40px;

    ${media.tablet(css`
      padding: 0 20px;
    `)};

    @media (max-width: 1023px) {
      padding: 0;
    }
  }
`;

const List = styled.div`
  margin-right: 410px;

  ${media.tablet(css`
    margin-right: 0;
    margin-left: 60px;
  `)};

  @media (max-width: 1023px) {
    margin-left: 0;
  }
`;
