import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { ContentContainer, Title } from '@/modules/HelpOnTheRoad/common.styles';
import { useHelpOnTheRoadData } from '@/modules/HelpOnTheRoad/hooks';

import RulesList from './components/RulesList';
import RulesSlider from './components/RulesSlider';
import { RuleCardProps } from './components/RuleCard/types';

function Rules() {
  const { rulesTitle, rulesItems } = useHelpOnTheRoadData();
  const isMobile = useMedia('(max-width: 767.9px)');

  if (!rulesItems || rulesItems.length === 0) {
    return null;
  }

  const convertedRulesItems: RuleCardProps[] = rulesItems.map(
    ({ image, text }) => ({ image, text: text ?? '' })
  );

  return (
    <Component>
      <Content>
        <ContentContainer>
          <Title>{rulesTitle}</Title>
          <Main>
            {!isMobile ? (
              <RulesList rules={convertedRulesItems} />
            ) : (
              <RulesSlider id={2} rules={convertedRulesItems} />
            )}
          </Main>
        </ContentContainer>
      </Content>
    </Component>
  );
}

export default Rules;

const Component = styled.section`
  padding: 30px 0;

  ${media.tablet(css`
    padding: 20px 0;
  `)}

  ${media.mobile(css`
    padding: 20px 0;
  `)}
`;

const Content = styled.div`
  ${media.mobile(css`
    padding: 0 15px;
  `)}
`;

const Main = styled.div`
  margin-top: 20px;
`;
