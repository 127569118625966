import React from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { BenefitItemType } from '@/modules/SpecialOffer/SpecialOffer.types';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

function BenefitItem({
  title,
  image,
  description,
  list,
  note,
  textColor,
  cardColor,
}: BenefitItemType) {
  return (
    <InfoCardContainer cardColor={cardColor}>
      <Container>
        <InfoCardHeader textColor={textColor}>{title}</InfoCardHeader>

        <InfoCardImageContainer>
          <InfoCardImage loading="lazy" src={image?.url} />
        </InfoCardImageContainer>

        <InfoListContainer>
          {description && (
            <Description textColor={textColor}>{description}</Description>
          )}

          {note && <Note textColor={textColor}>{note}</Note>}

          {list && (
            <InfoUl
              textColor={textColor}
              dangerouslySetInnerHTML={{ __html: list ?? '' }}
            />
          )}
        </InfoListContainer>
      </Container>
    </InfoCardContainer>
  );
}

export default BenefitItem;

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  padding: 10px 10px 30px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  ${media.mobile(css`
    width: 100%;
    padding: 10px 15px 30px;
  `)}
`;

const InfoCardContainer = styled.div<{ cardColor: Nullable<string> }>`
  position: relative;

  ${({ cardColor }) =>
    css`
      background-color: ${cardColor};
    `};

  z-index: 10;
  margin: 24px 24px 30px 0;
  padding: 0 20px;
  box-shadow: ${(props) => props.theme.blackShadow100};
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 390px;
  height: 435px;

  &:last-child {
    margin: 24px 0 30px;
  }

  ${media.mobile(css`
    min-width: 100%;
    height: fit-content;
    margin: 24px 0 0;
  `)}
`;

const InfoCardHeader = styled.h3<{ textColor: Nullable<string> }>`
  font-size: 30px;
  margin-top: 26px;

  ${({ textColor }) =>
    css`
      color: ${textColor};
    `};
`;

const Note = styled.span<{ textColor: Nullable<string> }>`
  display: block;
  margin-top: auto;

  ${({ textColor }) =>
    css`
      color: ${textColor};
    `};

  ${media.mobile(css`
    padding-top: 40px;
  `)}
`;

const InfoCardImageContainer = styled.div`
  position: relative;
  z-index: 100;
  width: 140px;
  height: 123px;
  margin-top: 20px;
  border-radius: 50%;
`;

const InfoCardImage = styled(Picture)``;

const InfoListContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const Description = styled.span<{ textColor: Nullable<string> }>`
  display: block;
  font-size: 22px;
  margin: 35px auto 0;
  font-weight: 500;
  line-height: 32px;

  ${({ textColor }) =>
    css`
      color: ${textColor};
    `};
`;

const InfoUl = styled.div<{ textColor: Nullable<string> }>`
  ul {
    padding-left: 17px;
    margin-top: 35px;

    li {
      ${({ textColor }) =>
        css`
          color: ${textColor};
        `};

      font-size: 22px;
      list-style-type: disc;
      margin-top: 10px;
      line-height: 32px;
      font-weight: 500;

      &::marker {
        color: ${colors.main};
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
`;
