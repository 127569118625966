import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Hero from './components/Hero';
import Menu from './components/Menu';
import ModelConfiguration from './components/ModelConfiguration';
import About from './components/About';
import Gallery from './components/Gallery';
import Reviews from './components/Reviews';
import Features from './components/Features';
import ComplectationStorage from './components/ComplectationStorage';
import Guarantee from './components/Guarantee';
import TestDriveForm from './components/TestDriveForm';
import Panel from './components/Panel';
import { useCarModelLandingEventMicromarking } from './hooks';

function CarModelLanding() {
  const eventMicromarking = useCarModelLandingEventMicromarking();

  return (
    <Layout>
      <Component>
        <Hero />
        <Menu />
        <ModelConfiguration />
        <About />
        <Gallery />
        <Reviews />
        <Features />
        <ComplectationStorage />
        <Guarantee />
        <TestDriveForm />
        <Panel />
      </Component>

      {eventMicromarking.map((micromarking, index) => (
        <script
          key={index}
          id="carModelLandingEvent"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: micromarking,
          }}
        />
      ))}
    </Layout>
  );
}

export default CarModelLanding;

const Component = styled.section`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
