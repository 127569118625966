import React from 'react';
import styled from 'styled-components';

import { ContentContainer } from '@/modules/CarModelLanding/common.styles';
import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import { GuaranteeCard } from '@/components/CarModelLandingWidgets';

function Guarantee() {
  const { guaranteeTitle, guaranteeDescription, guaranteeImage } =
    useCarModelLandingData();

  if (!guaranteeTitle && !guaranteeDescription) {
    return null;
  }

  return (
    <Component id="guarantee">
      <ContentContainer>
        <GuaranteeCard
          title={guaranteeTitle ?? ''}
          description={guaranteeDescription ?? ''}
          image={guaranteeImage}
        />
      </ContentContainer>
    </Component>
  );
}

export default Guarantee;

const Component = styled.section`
  padding-bottom: 60px;
`;
