import React from 'react';
import styled, { css } from 'styled-components';

import Hero from '@/modules/ValueCar/components/ValueCarHome/components/RateCar/components/Hero';
import AdvantageItem from '@/modules/ValueCar/components/ValueCarHome/components/RateCar/components/AdvantagesItem';
import FormRateCar from '@/components/FormRateCar';
import useCurrentPage from '@/hooks/useCurrentPage';
import { Page } from '@/typings/page';
import { ValueCar } from '@/modules/ValueCar/ValueCar.types';
import { media } from '@/utils/mixin';

const RateCar = () => {
  const pageFields = useCurrentPage<Page<ValueCar>>().templateFields;

  return (
    <Content>
      <Container>
        <Title>{pageFields.innerBlockTitle}</Title>
        <SubTitle>{pageFields.innerBlockText}</SubTitle>
        <ContentContainer>
          <LeftContainer>
            {pageFields.innerBlockTicks
              ?.split('\n')
              .slice(0, 2)
              .map((item, index) => {
                return <AdvantageItem key={index} label={item.trim()} />;
              })}
          </LeftContainer>
          <Hero />
          <RightContainer>
            {pageFields.innerBlockTicks
              ?.split('\n')
              .slice(2, 4)
              .map((item, index) => {
                return <AdvantageItem key={index} label={item.trim()} />;
              })}
          </RightContainer>
        </ContentContainer>
        <FormContainer>
          <FormRateCar
            isHorizontalForm={true}
            submitLabel={pageFields.innerBlockSubmit || ''}
          />
        </FormContainer>
      </Container>
    </Content>
  );
};

export default RateCar;

const FormContainer = styled.div`
  margin: 35px auto 0;
  max-width: 1200px;
  position: relative;

  ${media.mobile(css`
    margin: 0 auto;
  `)};
`;

const ContentContainer = styled.div`
  display: flex;

  ${media.mobile(css`
    display: block;
  `)};
`;

const Content = styled.div`
  margin-top: 20px;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 40px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const Title = styled.h1`
  font-size: 42px;
  margin: 0;
  color: ${(props) => props.theme.black};

  ${media.tablet(css`
    font-size: 36px;
  `)};

  ${media.mobile(css`
    font-size: 30px;
  `)};
`;

const SubTitle = styled.span`
  display: block;
  font-size: 16px;
  margin: 10px 0 30px 0;
  color: ${(props) => props.theme.blue100};
`;

const LeftContainer = styled.div`
  text-align: left;
  width: 20%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RightContainer = styled.div`
  text-align: right;
  width: 20%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 100%;
  }
`;
