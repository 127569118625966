import React from 'react';
import styled from 'styled-components';

import { LeaderItem } from '@/modules/Leadership/Leadership.types';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';

function LeadershipCard({ avatar, name, position }: LeaderItem) {
  const nameArray = name && name.split(' ');

  return (
    <Component>
      <Wrapper>
        <Avatar
          src={avatar.url}
          src2x={avatar.url_2x}
          srcWebp={avatar.url_webp}
          srcWebp2x={avatar.url_webp_2x}
        />
        <Name>
          {nameArray &&
            nameArray.map((name, index) => (
              <NameItem key={index}>{name}</NameItem>
            ))}
        </Name>
        <Position>{position}</Position>
      </Wrapper>
    </Component>
  );
}

export default LeadershipCard;

const Component = styled.div`
  width: 25%;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 1023px) {
    width: 50%;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media (max-width: 599px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;

  @media (max-width: 1023px) {
    padding: 20px;
  }

  @media (max-width: 599px) {
    padding: 20px 0;
  }
`;

const Avatar = styled(Picture)`
  align-items: flex-start;
  justify-content: flex-start;

  img {
    height: 100px;
    min-width: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const Name = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @media (max-width: 1023px) {
    margin-top: 20px;
  }
`;

const NameItem = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.blue700};

  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const Position = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-top: 30px;
  color: ${colors.blue600};
  opacity: 0.7;

  @media (max-width: 1023px) {
    margin-top: 10px;
  }
`;
