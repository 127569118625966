import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import ArrowIcon from '@/assets/svg/arrow-down.svg';
import { colors } from '@/constants/theme';

import { ChargerProps } from './types';

function Charger({ image, name, title, text, price }: ChargerProps) {
  const [isOpened, setOpened] = useState(false);

  const handleChargerOpen = () => {
    setOpened(!isOpened);
  };

  return (
    <Component onClick={handleChargerOpen}>
      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
      />

      <Name>
        {name} <ArrowIcon />
      </Name>

      <Content isOpened={isOpened}>
        <Header>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </Header>

        <Price>{price}</Price>
      </Content>
    </Component>
  );
}

export default Charger;

const Component = styled.div`
  cursor: pointer;
  position: relative;
  padding-top: 84.6395%;
  transition: 0.3s;

  ${media.tablet(css`
    padding-top: 80.598%;
  `)}

  &:hover {
    opacity: 0.8;
  }
`;

const Image = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  img {
    object-fit: cover;
  }
`;

const Name = styled.p`
  position: absolute;
  left: 37px;
  bottom: 30px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${colors.white};

  ${media.tablet(css`
    left: 20px;
    bottom: 20px;
    font-size: 16px;
    line-height: 19px;
  `)}

  svg {
    transform: rotate(-90deg);
    margin-left: 10px;

    path {
      fill: ${colors.white};
    }
  }
`;

const Content = styled.div<{ isOpened?: boolean }>`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 37px;
  background: ${colors.white};
  border-radius: 15px;
  transition: all 0.3s ease-in-out;

  ${media.tablet(css`
    padding: 20px;
  `)}

  ${({ isOpened }) =>
    isOpened &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const Header = styled.div``;

const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${colors.blue600};

  ${media.tablet(css`
    font-size: 16px;
    line-height: 19px;
  `)}
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-top: 10px;
  color: ${colors.blue600};
`;

const Price = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: ${colors.blue600};
`;
