import React, { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { Nullable, useMedia } from '@tager/web-core';

import Tooltip from '@/components/Collapse/components/Tooltip';
import { DisplayIcon } from '@/components/Collapse/Collapse';
import LockIcon from '@/assets/svg/lock.svg';
import { StoreDispatch, useTypedSelector } from '@/store/store';
import { OnlineCheckedValuesModel } from '@/modules/ValueCar/components/OnlineValuation/interfaces';
import { UserChoiceParametersModel } from '@/modules/ValueCar/ValueCar.types';
import { selectOnlineUserChoice } from '@/store/reducers/online-valuation-user-data';

import ValuesItem from './components/ValuesItem';

interface Props {
  title?: Nullable<string>;
  values?: { valueTitle: string; valuesList: Nullable<Array<string>> };
  setValue?: ActionCreatorWithPayload<string>;
  isContentSelect?: Nullable<string>;
  dispatch: StoreDispatch;
  hintText?: string;
  isShowIcon?: boolean;
  userChoiceParameter?: UserChoiceParametersModel;
  setParameter?: ActionCreatorWithPayload<UserChoiceParametersModel>;
  parametersList?: Nullable<Array<string>>;
  isParameter?: boolean;
  label?: Nullable<string>;
  isChecked: boolean;
  checkedValues?: OnlineCheckedValuesModel;
  setCheckedValues?: Dispatch<SetStateAction<OnlineCheckedValuesModel>>;
}

const TypedContent = React.forwardRef<HTMLDivElement | null, Props>(
  (
    {
      title,
      values,
      isContentSelect,
      dispatch,
      hintText,
      isShowIcon,
      setValue,
      parametersList,
      isParameter,
      userChoiceParameter,
      setParameter,
      label,
      isChecked,
      checkedValues,
      setCheckedValues,
    },
    ref
  ) => {
    const isMobile = useMedia('(max-width: 768px)');
    const userChoice = useTypedSelector(selectOnlineUserChoice);

    return (
      <Component isParameter={isParameter} ref={ref} isChecked={isChecked}>
        <Content isMobile={isMobile} isParameter={isParameter}>
          <TitleContainer>
            <Title isParameter={isParameter}>{title}</Title>
            {isShowIcon && (
              <Tippy
                content={<Tooltip text={hintText} />}
                offset={[25, 10]}
                placement={'bottom-end'}
              >
                <DisplayIcon>
                  <LockIcon />
                </DisplayIcon>
              </Tippy>
            )}
          </TitleContainer>

          {values?.valuesList && values.valuesList.length > 0 && (
            <ValuesItemContainer>
              {values.valuesList.map((item, index) => (
                <ValuesItem
                  key={index}
                  value={item}
                  dispatch={dispatch}
                  isContentSelect={isContentSelect ? isContentSelect : ''}
                  setValue={setValue}
                />
              ))}
            </ValuesItemContainer>
          )}

          {parametersList?.map((item, index) => {
            if (Boolean(item)) {
              return (
                <ValuesItem
                  key={index}
                  value={item}
                  label={label}
                  dispatch={dispatch}
                  userChoiceParameter={userChoiceParameter}
                  setParameter={setParameter}
                  checkedValues={checkedValues}
                  setCheckedValues={setCheckedValues}
                  userChoice={userChoice}
                />
              );
            }
          })}
        </Content>
      </Component>
    );
  }
);

export default TypedContent;

const Component = styled.div<{ isParameter?: boolean; isChecked?: boolean }>`
  border-radius: 8px;

  ${({ isParameter }) =>
    isParameter &&
    css`
      margin-right: 30px;
    `};

  ${({ isChecked }) =>
    !isChecked &&
    css`
      border: 1px solid #ee8f8f;
      background: ${(props) => props.theme.red};
    `};
`;

const Content = styled.div<{ isMobile?: boolean; isParameter?: boolean }>`
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  align-items: baseline;
  flex-direction: column;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 30px;
    `};

  ${({ isParameter }) =>
    isParameter &&
    css`
      border-top: none;
    `};

  ${({ isParameter }) =>
    isParameter &&
    css`
      margin-top: 0;
    `};
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h3<{ isParameter?: boolean }>`
  font-size: ${(props) => (props.isParameter ? '22px' : '24px')};
  line-height: 24px;
  padding-right: 10px;
  color: ${(props) => props.theme.black};
`;

const ValuesItemContainer = styled.ul`
  margin-top: 25px;
`;
