import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

import Checkbox from '@/components/Checkbox';
import { colors } from '@/constants/theme';
import ArrowIcon from '@/assets/svg/detailed-car/arrow.svg';
import { CarCard } from '@/components/CarModelCompareLandingWidgets';
import {
  useCarModelCompareLandingContext,
  useCarModelCompareLandingData,
} from '@/modules/CarModelCompareLanding/hooks';
import { ContentContainer } from '@/modules/CarModelCompareLanding/common.styles';

SwiperCore.use([Navigation, SwiperSlide]);

function ModelsDesktop() {
  const { models } = useCarModelCompareLandingData();
  const { isDifferentParams, setDifferentParams, setCurrentSlideIndex } =
    useCarModelCompareLandingContext();
  const prevNavButtonRef = useRef<HTMLButtonElement>(null);
  const nextNavButtonRef = useRef<HTMLButtonElement>(null);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const [smallHeader, setSmallHeader] = useState<boolean>(false);

  useEffect(() => {
    if (swiper) {
      swiper.update();
    }
  }, [swiper]);

  useEffect(() => {
    const onScroll = () => {
      if (!componentRef || !componentRef.current) {
        return;
      }

      setSmallHeader(componentRef.current.getBoundingClientRect().top <= 0);
    };

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  if (!models || models.length === 0) {
    return null;
  }

  const slidesPerView = 5;
  const isSliderOptions = models.length > slidesPerView;

  return (
    <Component ref={componentRef} smallHeader={smallHeader}>
      <ContentContainer>
        <Inner>
          <Aside>
            <Checkbox
              label="Скрыть одинаковые параметры"
              checked={isDifferentParams}
              onChange={setDifferentParams}
            />
          </Aside>
          <CarsList>
            <Swiper
              onSwiper={setSwiper}
              slidesPerView={slidesPerView}
              spaceBetween={10}
              onSlideChange={(swiper) => {
                setCurrentSlideIndex(swiper.activeIndex);
              }}
              navigation={{
                prevEl: prevNavButtonRef.current,
                nextEl: nextNavButtonRef.current,
              }}
              noSwipingClass="swiper-no-swiping"
            >
              {models.map(({ name, price, image, link }, index) => (
                <SwiperSlide
                  key={index}
                  className={!isSliderOptions ? 'swiper-no-swiping' : ''}
                >
                  <CarCard
                    image={image}
                    name={name ?? ''}
                    price={price ?? ''}
                    link={link ?? ''}
                    isMobile={smallHeader}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            {isSliderOptions && (
              <NavButtons>
                <NavButton ref={prevNavButtonRef} prev>
                  <ArrowIcon />
                </NavButton>
                <NavButton ref={nextNavButtonRef} next>
                  <ArrowIcon />
                </NavButton>
              </NavButtons>
            )}
          </CarsList>
        </Inner>
      </ContentContainer>
    </Component>
  );
}

export default ModelsDesktop;

const Component = styled.div<{ smallHeader: boolean }>`
  ${({ smallHeader }) =>
    smallHeader &&
    css`
      padding: 40px 0;
      box-shadow: ${(props) => props.theme.blackShadow100};
      background: ${(props) => props.theme.white_1};
    `}
`;

const Inner = styled.div`
  display: flex;
  max-width: 100%;
`;

const Aside = styled.div`
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 310px;
  flex: 0 0 310px;
`;

const CarsList = styled.div`
  flex: 1;
  max-width: calc(100% - 310px);
  padding: 0 50px;
  position: relative;

  .swiper-container {
    overflow: hidden;
    height: 100%;
  }
`;

const NavButtons = styled.div`
  z-index: 5;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
`;

const NavButton = styled.button<{ prev?: boolean; next?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0.7px solid ${colors.gray400};
  overflow: hidden;

  svg path {
    fill: ${colors.main};
  }

  &.swiper-button-disabled {
    pointer-events: none;

    svg path {
      fill: #dcdcdc !important;
    }
  }

  svg {
    width: 9px;
    height: 16px;
  }

  ${(props) =>
    props.prev
      ? css`
          svg {
            transform: rotate(-180deg);
          }
        `
      : css``}
`;
