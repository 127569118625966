import React from 'react';
import styled from 'styled-components';

import { useMedia } from '@tager/web-core';

import { useHowToData } from '@/modules/HowTo/HowTo.hooks';
import Picture from '@/components/Picture';

function Hero() {
  const { heroImage, heroMobileImage } = useHowToData();
  const mobile = useMedia('(max-width: 979.9px)');

  return (
    <Component>
      {mobile ? (
        <BackgroundMobile
          loading="lazy"
          src={heroMobileImage?.url}
          src2x={heroMobileImage?.url_2x}
          srcWebp={heroMobileImage?.url_webp}
          srcWebp2x={heroMobileImage?.url_webp_2x}
        />
      ) : (
        <BackgroundDesktop
          loading="lazy"
          src={heroImage?.url}
          src2x={heroImage?.url_2x}
          srcWebp={heroImage?.url_webp}
          srcWebp2x={heroImage?.url_webp_2x}
        />
      )}
    </Component>
  );
}

export default Hero;

const Component = styled.section`
  position: relative;
  padding-top: 29.167%;

  @media (max-width: 979.9px) {
    padding-top: 40.85%;
  }

  @media (max-width: 579.9px) {
    padding-top: 88.054%;
  }
`;

const BackgroundDesktop = styled(Picture)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`;

const BackgroundMobile = styled(BackgroundDesktop)`
  @media (max-width: 579.9px) {
    img {
      object-position: 12% center;
    }
  }
`;
