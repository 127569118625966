import React from 'react';
import styled from 'styled-components';

import { useModal } from '@tager/web-components';

import { useJetourHomeData } from '@/modules/JetourHome/hooks';
import { ModelsBanners } from '@/components/LandingWidgets';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import { submitJetourCarsLeadForm } from '@/services/leads/leads-service';
import { getRequestSource } from '@/utils/common';
import { googleEvent } from '@/utils/events';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';

function Models() {
  const openModal = useModal();
  const { modelsItems } = useJetourHomeData();

  if (!modelsItems || modelsItems.length === 0) {
    return null;
  }

  const sendApplicationForm = async (
    values: ApplicationFormFields,
    model: string,
    event: string
  ): Promise<void> => {
    try {
      await submitJetourCarsLeadForm({
        ...values,
        model,
        source: getRequestSource(),
      }).then(() => {
        if (event) {
          googleEvent(event);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenApplicationFormModal = (
    title: string,
    model: string,
    event: string
  ) => {
    openModal(
      ApplicationFormModal,
      {
        title,
        onSubmit: (values) => sendApplicationForm(values, model, event),
        fieldsConfig: { withoutEmail: true },
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <Component id="models">
      <ModelsBanners
        id={2}
        models={modelsItems.map(
          ({
            image,
            imageMobile,
            imagePreview,
            name,
            linkText,
            link,
            buttonText,
            modalFormTitle,
            googleEvent,
          }) => ({
            image,
            mobileImage: imageMobile,
            previewImage: imagePreview,
            name: name ?? '',
            linkText: linkText ?? '',
            link: link ?? '',
            buttonText: buttonText ?? '',
            onClick: () =>
              handleOpenApplicationFormModal(
                modalFormTitle ?? '',
                name ?? '',
                googleEvent ?? ''
              ),
          })
        )}
      />
    </Component>
  );
}

export default Models;

const Component = styled.section``;
