import React from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { SidebarProps } from './Sidebar.types';

const Sidebar = React.memo(({ years = [], activeYear }: SidebarProps) => {
  const handleYearTo = (year: Nullable<string>) => {
    if (!year) {
      return;
    }

    const elementTo = document.getElementById(year);

    if (!elementTo) {
      return;
    }

    window.scroll({
      top: elementTo.offsetTop - 82,
      behavior: 'smooth',
    });
  };

  return (
    <Component>
      <ComponentContainer>
        {years.map((year, index) => (
          <Year
            key={index}
            onClick={() => handleYearTo(year)}
            activeLink={year === activeYear}
          >
            {year}
          </Year>
        ))}
      </ComponentContainer>
    </Component>
  );
});

export default Sidebar;

const Component = styled.div`
  flex: 1 1 200px;
  max-width: 200px;

  ${media.tablet(css`
    display: none;
  `)}
`;

const ComponentContainer = styled.div`
  position: sticky;
  top: 140px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Year = styled.span<{ activeLink: boolean }>`
  font-size: 12px;
  line-height: 20px;
  padding: 5px 20px;
  border-left: 1px solid transparent;
  user-select: none;
  color: ${colors.gray500};
  transition: 0.3s;
  cursor: pointer;

  ${({ activeLink }) =>
    activeLink &&
    css`
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: ${colors.blue700};
      border-left-color: ${colors.main};
    `};

  &:hover {
    color: ${colors.main};
  }
`;
