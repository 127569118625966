import React from 'react';
import styled, { css } from 'styled-components';

import { useModal } from '@tager/web-components';

import { media } from '@/utils/mixin';
import {
  useBrandsOptions,
  useSubscriptionData,
} from '@/modules/Subscription/Subscription.hooks';
import { colors } from '@/constants/theme';
import FaqAccordion from '@/components/FaqAccordion';
import Link from '@/components/Link';
import { getPhoneLink } from '@/utils/common';

import { handleOpenModalFormClick } from '../../Subscription.helpers';

function Faq() {
  const {
    questionTitle,
    questionItems,
    aboutFormType,
    aboutGoogleEvent,
    phone,
  } = useSubscriptionData();
  const openModal = useModal();
  const brands = useBrandsOptions();

  if (!questionItems || questionItems.length === 0) {
    return null;
  }

  return (
    <Component>
      <ContentContainer>
        <Title>{questionTitle}</Title>

        <FaqAccordionWrapper>
          <FaqAccordion
            data={questionItems}
            linkHrefs={{
              ':modal:': () =>
                handleOpenModalFormClick(
                  openModal,
                  aboutFormType,
                  brands,
                  aboutGoogleEvent
                ),
            }}
          />
        </FaqAccordionWrapper>

        {phone ? (
          <MobilePhone to={getPhoneLink(phone)}>{phone}</MobilePhone>
        ) : null}
      </ContentContainer>
    </Component>
  );
}

export default Faq;

const Component = styled.section`
  padding: 160px 0 58px;
  background: ${(props) => props.theme.gray};

  ${media.mobile(css`
    padding: 262px 0 60px;
  `)}
`;

const ContentContainer = styled.div`
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  color: ${(props) => props.theme.black};

  ${media.mobile(css`
    font-size: 28px;
    line-height: 40px;
    text-align: center;
  `)}
`;

const FaqAccordionWrapper = styled.div`
  margin-top: 30px;
`;

const MobilePhone = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 315px;
  padding: 14px 0;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  margin: 60px auto 0;
  border-radius: 50px;
  color: ${colors.white};
  background: ${colors.main};

  &:hover {
    background: ${colors.main100};
  }
`;
