import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import { ContentContainer } from '@/modules/CarModelLanding/common.styles';
import { useCarModelLandingData } from '@/modules/CarModelLanding/hooks';
import {
  CarConfigurationSlider,
  ColorPicker,
  Description,
  FeatureListSecondary,
  Tabs,
  Title,
} from '@/components/CarModelLandingWidgets';
import { media } from '@/utils/mixin';
import { ColorPickerOnChangeConfig } from '@/components/CarModelLandingWidgets/ColorPicker/types';
import { TabsOnChangeConfig } from '@/components/CarModelLandingWidgets/Tabs/types';

function ModelConfiguration() {
  const {
    configurationTitle,
    configurationGallery,
    configurationText,
    configurationItems,
  } = useCarModelLandingData();
  const isTabletSmall = useMedia('(max-width: 1023.9px)');

  const complectationTabs =
    configurationGallery && configurationGallery.length !== 0
      ? configurationGallery.map(({ complectation }) => ({
          label: complectation ?? '',
          value: complectation ?? '',
        }))
      : [];

  const [activeComplectationTab, setActiveComplectationTab] =
    useState<TabsOnChangeConfig>({
      activeIndex: 0,
      activeValue: complectationTabs[0]?.value ?? '',
    });

  const colors =
    configurationGallery && configurationGallery.length !== 0
      ? configurationGallery[
          activeComplectationTab.activeIndex
        ]?.complectationItems.map((item) => ({ color: item.color ?? '' }))
      : [];

  const [activeComplectationColor, setActiveComplectationColor] =
    useState<ColorPickerOnChangeConfig>({
      activeColorIndex: 0,
      activeColor: colors[0]?.color ?? '',
    });

  const sliderImages =
    configurationGallery && configurationGallery.length !== 0
      ? configurationGallery[activeComplectationTab.activeIndex]
          ?.complectationItems[activeComplectationColor.activeColorIndex]
          ?.images
      : [];

  return (
    <Component id="configuration">
      <ContentContainer>
        <Title title={configurationTitle ?? ''} />

        <TabsWrapper>
          <Tabs
            data={complectationTabs}
            activeValue={activeComplectationTab.activeValue}
            onChange={(config) => {
              setActiveComplectationTab(config);
              setActiveComplectationColor({
                activeColorIndex: 0,
                activeColor: colors[0]?.color ?? '',
              });
            }}
            isBlock={isTabletSmall}
          />
        </TabsWrapper>

        {sliderImages && (
          <SliderWrapper>
            <CarConfigurationSlider images={sliderImages} />
          </SliderWrapper>
        )}

        <ColorPickerWrapper>
          <ColorPicker
            colors={colors}
            activeColor={activeComplectationColor.activeColor}
            onChange={setActiveComplectationColor}
          />
        </ColorPickerWrapper>

        <DescriptionWrapper>
          <Description description={configurationText ?? ''} />
        </DescriptionWrapper>

        <FeatureListWrapper>
          <FeatureListSecondary
            features={
              configurationItems && configurationItems.length !== 0
                ? configurationItems.map(({ image, title, description }) => ({
                    image,
                    title: title ?? '',
                    text: description ?? '',
                  }))
                : []
            }
          />
        </FeatureListWrapper>
      </ContentContainer>
    </Component>
  );
}

export default ModelConfiguration;

const Component = styled.section`
  padding: 60px 0;
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  ${media.tabletSmall(css`
    margin-top: 20px;
  `)}
`;

const SliderWrapper = styled.div`
  margin-top: 30px;
`;

const ColorPickerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const DescriptionWrapper = styled.div`
  margin-top: 40px;
`;

const FeatureListWrapper = styled.div`
  margin-top: 60px;
`;
