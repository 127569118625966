import { isNotNullish, Nullable } from '@tager/web-core';

import { CatalogCar, CatalogFiltersData } from '@/services/catalog/typings';
import { getCityFromCookie } from '@/utils/common';
import { SEOTemplate, SEOTemplatesTypes } from '@/typings/model';
import { OpenModalType, OptionType } from '@/typings/common';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import { googleEvent } from '@/utils/events';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import defaultStockCar from '@/assets/images/defaultStockCar.png';
import { submitCarCatalog } from '@/services/leads/leads-service';

const sendApplicationForm = async (
  model: string,
  values: ApplicationFormFields,
  event?: string
): Promise<void> => {
  try {
    await submitCarCatalog({
      model,
      ...values,
    }).then(() => {
      if (event) {
        googleEvent(event);
      }
    });
  } catch (error: any) {
    return Promise.reject(error);
  }
};

const handleOpenApplicationFormModal = (
  openModal: OpenModalType,
  model: string,
  event?: string
) => {
  openModal(
    ApplicationFormModal,
    {
      title: 'Хочу похожее авто',
      onSubmit: (values) => sendApplicationForm(model, values, event),
      fieldsConfig: { withoutEmail: true },
    },
    { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
  );
};

export const getCatalogCarButton = (
  openModal: OpenModalType,
  car: CatalogCar,
  buttonLink: string,
  event?: string
): {
  label: string;
  url?: string;
  onClick?: () => void;
} => {
  if (car.stock.availableQuantity) {
    return {
      label: `Авто в наличии (${car.stock.availableQuantity})`,
      url: buttonLink,
    };
  } else {
    return {
      label: 'Хочу похожее авто',
      onClick: () => handleOpenApplicationFormModal(openModal, car.name, event),
    };
  }
};

export const getCatalogCarLink = (
  car: CatalogCar
): { label: string; url: string } => {
  return { label: 'О модели', url: car.url };
};

export const getCatalogCarImages = (
  car: CatalogCar,
  isMobile?: boolean
): { color?: OptionType; items: string[] }[] => {
  return car.colors && car.colors.length > 0
    ? car.colors.map(({ hex, name, images }) => ({
        color: { label: name, value: '#' + hex },
        items:
          images && images.length > 0
            ? images.map((image) =>
                !isMobile
                  ? image.desktop?.url_webp ??
                    image.desktop?.url ??
                    defaultStockCar
                  : image.mobile?.url_webp ??
                    image.mobile?.url_webp ??
                    defaultStockCar
              )
            : [defaultStockCar],
      }))
    : car.images && car.images.length > 0
    ? [
        {
          items: car.images.map((image) =>
            !isMobile
              ? image.desktop?.url_webp ?? image.desktop?.url ?? defaultStockCar
              : image.mobile?.url_webp ?? image.mobile?.url ?? defaultStockCar
          ),
        },
      ]
    : [{ items: [defaultStockCar] }];
};

export function parseUrl(string: string) {
  if (string.substring(0, 7) === 'filter-') {
    let arr: string[] = [];
    string = string.substring(7);

    if (string.substring(0, 6) === 'brand-') {
      let newString = string.substring(6);

      if (newString.indexOf('body') !== -1) {
        arr = [
          ...arr,
          ...newString
            .substring(0, newString.indexOf('body') - 2)
            .split('--and--'),
        ];
      } else if (newString.indexOf('color') !== -1) {
        arr = [
          ...arr,
          ...newString
            .substring(0, newString.indexOf('color') - 2)
            .split('--and--'),
        ];
      } else {
        arr = [...arr, ...newString.split('--and--')];
      }
    }

    if (string.indexOf('body') !== -1) {
      let newString = string.substring(string.indexOf('body') + 5);
      if (newString.indexOf('color') !== -1) {
        arr = [
          ...arr,
          ...newString
            .substring(0, newString.indexOf('color') - 2)
            .split('--and--'),
        ];
      } else {
        arr = [...arr, ...newString.split('--and--')];
      }
    }

    if (string.indexOf('color') !== -1) {
      let newString = string.substring(string.indexOf('color') + 6);
      arr = [...arr, ...newString.split('--and--')];
    }

    if (string.indexOf('fuel') !== -1) {
      let newString = string.substring(string.indexOf('fuel') + 5);
      arr = [...arr, ...newString.split('--and--')];
    }

    return arr;
  } else {
    return [string];
  }
}

export function convertedActiveCheckboxes(
  activeCheckboxes: string[],
  allCheckboxes: CatalogFiltersData
) {
  let arr: { name: string; id: number; hex?: string; alias: string }[] = [
    ...allCheckboxes.brands.filter((item) =>
      activeCheckboxes.includes(item.alias)
    ),
    ...allCheckboxes.bodies.filter((item) =>
      activeCheckboxes.includes(item.alias)
    ),
    ...allCheckboxes.colors.filter((item) =>
      activeCheckboxes.includes(item.alias)
    ),
    ...allCheckboxes.fuelTypes.filter((item) =>
      activeCheckboxes.includes(item.alias)
    ),
  ];
  return arr.map((item) => {
    return { value: item.name, label: item.name };
  });
}

export function getCityUrl(): string {
  const city = getCityFromCookie();

  return city ? `/${city.value}/` : '';
}

export function getReplaceUrl(newUrl: string) {
  return newUrl ? newUrl + getCityUrl() : `/catalog${getCityUrl()}`;
}

export function getCatalogSEOTemplate(
  activeFiltersCheckbox: OptionType[],
  filters: Nullable<CatalogFiltersData>,
  city?: { default?: string; withCase?: string },
  seoData?: Nullable<SEOTemplate>
): { template: SEOTemplatesTypes; data: Nullable<SEOTemplate> } {
  const brandsNames = filters?.brands.map((brand) => brand.name) ?? [];

  if (filters && activeFiltersCheckbox.length === 1) {
    const bodiesNames = filters.bodies.map((body) => body.name);
    const fuelTypesNames = filters.fuelTypes.map((fuelType) => fuelType.name);
    const activeFilter = activeFiltersCheckbox[0].label;

    if (brandsNames.includes(activeFilter)) {
      const convertedSeoData = seoData
        ? {
            ...seoData,
            title:
              seoData.title
                ?.replaceAll('{{brand}}', activeFilter)
                ?.replaceAll('{{city}}', city?.default ?? '')
                ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
            description:
              seoData.description
                ?.replaceAll('{{brand}}', activeFilter)
                ?.replaceAll('{{city}}', city?.default ?? '')
                ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
            h1:
              seoData.h1
                ?.replaceAll('{{brand}}', activeFilter)
                ?.replaceAll('{{city}}', city?.default ?? '')
                ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
          }
        : null;

      if (city && (city.default || city.withCase)) {
        return { template: 'catalog_brand_city', data: convertedSeoData };
      }

      return { template: 'catalog_brand', data: convertedSeoData };
    } else if (bodiesNames.includes(activeFilter)) {
      const pluralName =
        filters.bodies.find((body) => body.name === activeFilter)?.pluralName ??
        '';

      const convertedSeoData = seoData
        ? {
            ...seoData,
            title:
              seoData.title
                ?.replaceAll('{{name}}', activeFilter)
                ?.replaceAll('{{name|lowercase}}', activeFilter.toLowerCase())
                ?.replaceAll('{{plural_name}}', pluralName)
                ?.replaceAll(
                  '{{plural_name|lowercase}}',
                  pluralName.toLowerCase()
                )
                ?.replaceAll('{{city}}', city?.default ?? '')
                ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
            description:
              seoData.description
                ?.replaceAll('{{name}}', activeFilter)
                ?.replaceAll('{{name|lowercase}}', activeFilter.toLowerCase())
                ?.replaceAll('{{plural_name}}', pluralName)
                ?.replaceAll(
                  '{{plural_name|lowercase}}',
                  pluralName.toLowerCase()
                )
                ?.replaceAll('{{city}}', city?.default ?? '')
                ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
            h1:
              seoData.h1
                ?.replaceAll('{{name}}', activeFilter)
                ?.replaceAll('{{name|lowercase}}', activeFilter.toLowerCase())
                ?.replaceAll('{{plural_name}}', pluralName)
                ?.replaceAll(
                  '{{plural_name|lowercase}}',
                  pluralName.toLowerCase()
                )
                ?.replaceAll('{{city}}', city?.default ?? '')
                ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
          }
        : null;

      if (city && (city.default || city.withCase)) {
        return { template: 'catalog_filter_body_city', data: convertedSeoData };
      }

      return { template: 'catalog_filter_body', data: convertedSeoData };
    } else if (fuelTypesNames.includes(activeFilter)) {
      const foundFuelType = filters.fuelTypes.find(
        (fuelType) => fuelType.name === activeFilter
      );

      const convertedSeoData =
        seoData && foundFuelType
          ? {
              ...seoData,
              title:
                seoData.title
                  ?.replaceAll('{{singular_name}}', foundFuelType.singularName)
                  ?.replaceAll(
                    '{{singular_name|lowercase}}',
                    foundFuelType.singularName.toLowerCase()
                  )
                  ?.replaceAll('{{plural_name}}', foundFuelType.pluralShortName)
                  ?.replaceAll(
                    '{{plural_name|lowercase}}',
                    foundFuelType.pluralShortName.toLowerCase()
                  )
                  ?.replaceAll(
                    '{{plural_full_name}}',
                    foundFuelType.pluralFullName
                  )
                  ?.replaceAll(
                    '{{plural_full_name|lowercase}}',
                    foundFuelType.pluralFullName.toLowerCase()
                  ) ?? '',
              description:
                seoData.description
                  ?.replaceAll('{{singular_name}}', foundFuelType.singularName)
                  ?.replaceAll(
                    '{{singular_name|lowercase}}',
                    foundFuelType.singularName.toLowerCase()
                  )
                  ?.replaceAll('{{plural_name}}', foundFuelType.pluralShortName)
                  ?.replaceAll(
                    '{{plural_name|lowercase}}',
                    foundFuelType.pluralShortName.toLowerCase()
                  )
                  ?.replaceAll(
                    '{{plural_full_name}}',
                    foundFuelType.pluralFullName
                  )
                  ?.replaceAll(
                    '{{plural_full_name|lowercase}}',
                    foundFuelType.pluralFullName.toLowerCase()
                  ) ?? '',
              h1:
                seoData.h1
                  ?.replaceAll('{{singular_name}}', foundFuelType.singularName)
                  ?.replaceAll(
                    '{{singular_name|lowercase}}',
                    foundFuelType.singularName.toLowerCase()
                  )
                  ?.replaceAll('{{plural_name}}', foundFuelType.pluralShortName)
                  ?.replaceAll(
                    '{{plural_name|lowercase}}',
                    foundFuelType.pluralShortName.toLowerCase()
                  )
                  ?.replaceAll(
                    '{{plural_full_name}}',
                    foundFuelType.pluralFullName
                  )
                  ?.replaceAll(
                    '{{plural_full_name|lowercase}}',
                    foundFuelType.pluralFullName.toLowerCase()
                  ) ?? '',
            }
          : null;

      return { template: 'catalog_filter_fuel', data: convertedSeoData };
    }
  } else if (filters && activeFiltersCheckbox.length > 1) {
    if (
      activeFiltersCheckbox.every((item) => brandsNames.includes(item.label))
    ) {
      return { template: 'catalog_brands', data: seoData ?? null };
    }
  }

  const convertedSeoData = seoData
    ? {
        ...seoData,
        title:
          seoData.title
            ?.replaceAll('{{city}}', city?.default ?? '')
            ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
        description:
          seoData.description
            ?.replaceAll('{{city}}', city?.default ?? '')
            ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
        h1:
          seoData.h1
            ?.replaceAll('{{city}}', city?.default ?? '')
            ?.replaceAll('{{cityWithCase}}', city?.withCase ?? '') ?? '',
      }
    : null;

  if (city && (city.default || city.withCase)) {
    return { template: 'catalog_city', data: convertedSeoData };
  }

  return { template: 'catalog', data: convertedSeoData };
}

export const getCatalogCarsMinAndMaxPrices = (cars: CatalogCar[]) => {
  if (!cars || cars.length === 0) {
    return { minPrice: 0, maxPrice: 0 };
  }

  const prices = cars.map(({ stock }) => stock.minPrice).filter(isNotNullish);

  if (prices.length < 1) {
    return { minPrice: 0, maxPrice: 0 };
  }

  return { minPrice: Math.min(...prices), maxPrice: Math.max(...prices) };
};
