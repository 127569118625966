import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';

import { useMedia } from '@tager/web-core';

import { useServicesData } from '@/modules/Services/Services.hooks';
import Marker from '@/components/DealersMap/components/Marker';

function ServicesMap() {
  const data = useServicesData();
  const isMobile = useMedia('(max-width: 767.9px)');

  return (
    <Component>
      <Content>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.NEXT_PUBLIC_API_KEY_GOOGLE_MAP ?? '',
          }}
          center={
            isMobile
              ? {
                  lat: 53.952,
                  lng: 27.715,
                }
              : {
                  lat: 53.947,
                  lng: 27.7,
                }
          }
          defaultZoom={14.8}
        >
          {data.dealersItems &&
            data.dealersItems.length > 0 &&
            data.dealersItems.map(
              (item) =>
                item.addressesMap &&
                item.addressesMap.length > 0 &&
                item.addressesMap.map((itemMap, index) => {
                  const latitude = +itemMap.addressMapLatitude;
                  const longitude = +itemMap.addressMapLongitude;

                  return (
                    <Marker
                      key={index}
                      name={item.name ?? ''}
                      address={itemMap.addressMap ?? ''}
                      lat={latitude}
                      lng={longitude}
                    />
                  );
                })
            )}
        </GoogleMapReact>
      </Content>
    </Component>
  );
}

export default ServicesMap;

const Component = styled.div`
  position: relative;
  padding-top: 60%;
  overflow: hidden;
`;

const Content = styled.div`
  position: absolute;
  inset: 0;
`;
