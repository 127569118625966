import React from 'react';

import { useModal } from '@tager/web-components';

import { useCarModelCompareLandingData } from '@/modules/CarModelCompareLanding/hooks';
import { ApplicationFormFields } from '@/components/modals/ApplicationFormModal/ApplicationFormModal.types';
import ApplicationFormModal from '@/components/modals/ApplicationFormModal';
import { StyledOverlay } from '@/components/modals/shared/StyledOverlay';
import { submitCarModelComplectations } from '@/services/leads/leads-service';
import { getRequestSource } from '@/utils/common';
import { googleEvent } from '@/utils/events';
import { ButtonsPanel } from '@/components/ButtonsPanel';

function Panel() {
  const openModal = useModal();
  const { panelModalButtonTitle, panelModalFormTitle, panelModalGoogleEvent } =
    useCarModelCompareLandingData();

  if (!panelModalButtonTitle) {
    return null;
  }

  const sendApplicationForm = async (
    values: ApplicationFormFields
  ): Promise<void> => {
    try {
      await submitCarModelComplectations({
        ...values,
        source: getRequestSource(),
      }).then(() => {
        if (panelModalGoogleEvent) {
          googleEvent(panelModalGoogleEvent);
        }
      });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const handleOpenApplicationFormModal = () => {
    openModal(
      ApplicationFormModal,
      {
        title: panelModalFormTitle ?? '',
        onSubmit: sendApplicationForm,
        fieldsConfig: { withoutEmail: true },
      },
      { components: { Overlay: StyledOverlay }, scrollLockDisabled: true }
    );
  };

  return (
    <ButtonsPanel
      buttons={[
        {
          title: panelModalButtonTitle,
          onClick: handleOpenApplicationFormModal,
        },
      ]}
    />
  );
}

export default Panel;
