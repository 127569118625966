import React from 'react';
import styled from 'styled-components';

import Layout from '@/layout';

import Hero from './components/Hero';
import Faq from './components/Faq';

function HowTo() {
  return (
    <Layout>
      <Component>
        <Hero />
        <Faq />
      </Component>
    </Layout>
  );
}

export default HowTo;

const Component = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.white};
`;
