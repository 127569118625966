import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';

import { PackageCardProps } from './types';

function PackageCard({ title, image, isHit }: PackageCardProps) {
  return (
    <Component>
      <Content>
        <Title>{title}</Title>
        {isHit && <Hit>ХИТ</Hit>}
      </Content>

      <Image
        loading="lazy"
        src={image?.url}
        src2x={image?.url_2x}
        srcWebp={image?.url_webp}
        srcWebp2x={image?.url_webp_2x}
      />
    </Component>
  );
}

export default PackageCard;

export const Component = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 290px;
  height: 140px;
  padding: 20px;
  border-radius: 10px 10px 0 0;

  ${media.tablet(css`
    width: 280px;
  `)}
`;

const Content = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.white};
`;

const Hit = styled.div`
  font-weight: 700;
  font-size: 13px;
  line-height: 15px;
  padding: 4px 20px;
  margin-top: 10px;
  color: ${colors.white};
  background: ${colors.orange};
  border-radius: 20px;
`;

const Image = styled(Picture)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  picture,
  img {
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0 0;
  }

  img {
    object-fit: cover;
  }
`;
