import * as Yup from 'yup';

import { emailRegexp } from '@/utils/textInput';

import { ApplicationFormFields } from './ApplicationFormModal.types';

export const initialApplicationFormValues: ApplicationFormFields = {
  name: '',
  phone: '',
  email: '',
  message: '',
};

export const validationApplicationSchema: Yup.SchemaOf<ApplicationFormFields> =
  Yup.object().shape({
    name: Yup.string().required('Укажите имя'),
    phone: Yup.string().label('Phone').required('Укажите номер телефона'),
    email: Yup.string()
      .label('Email')
      .required('Укажите Email')
      .matches(emailRegexp, 'Email введен неверно'),
    message: Yup.string(),
  });
