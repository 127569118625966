import React from 'react';
import styled from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';

function EcoHeader() {
  const page = useCurrentPage();

  if (!page) {
    return null;
  }

  return (
    <Component>
      {page.templateFields?.innerBlockTitle && (
        <Title>{page.templateFields.innerBlockTitle}</Title>
      )}
    </Component>
  );
}

export default EcoHeader;

const Component = styled.div`
  margin: 22px 40px;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0;
  color: ${(props) => props.theme.black};

  @media (max-width: 1350px) {
    font-size: 25px;
    line-height: 28px;
    text-align: center;
  }
`;
