import React from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { BrandsCardProps } from './types';

function BrandsCard({ image, name, link }: BrandsCardProps) {
  return !link ? (
    <Card>
      {image ? <Image loading="lazy" src={image} /> : null}
      <Name>{name}</Name>
    </Card>
  ) : (
    <LinkCard to={link}>
      {image ? <Image loading="lazy" src={image} /> : null}
      <Name>{name}</Name>
    </LinkCard>
  );
}

export default BrandsCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background: ${colors.white};
  box-shadow: 0 5px 30px 0 rgb(23 43 77 / 10%);

  ${media.tablet(css`
    padding: 20px;
    background: none;
    box-shadow: none;
  `)}
`;

const LinkCard = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.white};
  padding: 30px;

  ${media.tablet(css`
    padding: 20px;
  `)}

  &:hover {
    box-shadow: 0 5px 30px 0 rgb(23 43 77 / 10%);
  }
`;

const Image = styled(Picture)`
  height: 70px;
  max-width: 70px;

  ${media.mobile(css`
    height: 44px;
  `)}

  img,
  picture {
    width: 100%;
    height: 100%;
  }

  picture {
    ${media.mobile(css`
      display: flex;
      align-items: center;
    `)}
  }

  img {
    object-fit: contain;

    ${media.mobile(css`
      margin: 0 auto;
      max-height: 40px;
    `)}
  }
`;

const Name = styled.span`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  margin-top: 4px;
  color: ${colors.blue600};
`;
