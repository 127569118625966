import React from 'react';
import styled, { css } from 'styled-components';

import { useSpecialOfferData } from '@/modules/SpecialOffer/SpecialOffer.hooks';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

function Remember() {
  const { rememberText, rememberLeftImage, rememberRightImage } =
    useSpecialOfferData();

  return (
    <Component>
      <LeftCarContainer>
        <Car loading="lazy" src={rememberLeftImage?.url} />
      </LeftCarContainer>
      <TextContainer>
        <Text>{rememberText}</Text>
      </TextContainer>
      <RightCarContainer>
        <Car loading="lazy" src={rememberRightImage?.url} />
      </RightCarContainer>
    </Component>
  );
}

export default Remember;

const Component = styled.div`
  position: relative;
  margin-top: 50px;

  ${media.mobile(css`
    display: flex;
    flex-direction: column;
  `)}
`;

const LeftCarContainer = styled.div`
  position: absolute;
  left: 0;

  ${media.mobile(css`
    position: inherit;
    margin-right: auto;
  `)}
`;

const RightCarContainer = styled(LeftCarContainer)`
  right: 0;
  top: 0;
  left: inherit;

  ${media.mobile(css`
    margin-left: auto;
    margin-right: 0;
  `)}
`;

const Car = styled(Picture)`
  img {
    @media (max-width: 1350px) {
      height: 20.5vw;
    }

    ${media.mobile(css`
      width: 410px;
      height: 220px;
    `)};

    @media (max-width: 500px) {
      width: 320px;
      height: 180px;
    }
  }
`;

const TextContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  height: 314px;
  max-width: 560px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1350px) {
    max-width: 45vw;
    height: 22vw;
  }

  ${media.mobile(css`
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
    padding: 30px 20px;
  `)}
`;

const Text = styled.span`
  font-weight: 500;
  font-size: 26px;
  line-height: 48px;
  color: ${(props) => props.theme.blue200};

  ${media.mobile(css`
    line-height: 42px;
  `)};
`;
