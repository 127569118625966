import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import {
  ContactFormOfflineValuationModel,
  OfflineCheckedValuesModel,
} from '@/modules/ValueCar/components/OfflineValuation/components/ContactFormOfflineValuation/interfaces';
import RateCarSuccessPage from '@/components/RateCarSuccessPage';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import { selectRateCarsData } from '@/store/reducers/rate-car-data';
import { handleValidateContactForm } from '@/modules/ValueCar/utils';
import { ButtonPrimary } from '@/components/CarCard/components/Button';
import { media } from '@/utils/mixin';
import {
  checkIsFillOfflineValues,
  getClearedOfflineData,
} from '@/modules/ValueCar/components/OfflineValuation/components/ContactFormOfflineValuation/helpers';
import {
  selectOfflineValuationUserChoice,
  setOfflineUserChoice,
} from '@/store/reducers/offline-valuation-user-data';
import FormPageHeader from '@/modules/ValueCar/components/FormPageHeader';
import { setSellCarLoading, setSellCarView } from '@/store/reducers/sell-car';
import useCurrentPage from '@/hooks/useCurrentPage';
import { Page } from '@/typings/page';
import { ValueCar } from '@/modules/ValueCar/ValueCar.types';
import ContactForm from '@/modules/ValueCar/components/ContactForm';
import LocationsMap from '@/components/LocationsMap';
import { scrollTop } from '@/utils/scroll';
import { googleEvent } from '@/utils/events';
import { submitRateCarOffline } from '@/services/leads/leads-service';

import DateAndTime from './components/DateAndTime';

function OfflineValuation() {
  const pageFields = useCurrentPage<Page<ValueCar>>().templateFields;
  const data = useTypedSelector(selectRateCarsData);
  const dispatch = useTypedDispatch();

  const [dealerId, setDealerId] = useState<number | null>(null);
  const citiesList = data.dealers;

  const formRef = useRef<HTMLDivElement | null>(null);
  const dateRef = useRef<HTMLDivElement | null>(null);
  const timeRef = useRef<HTMLDivElement | null>(null);
  const [isDataSanded, setIsDataSanded] = useState(false);

  const offlineCarData = useTypedSelector(selectOfflineValuationUserChoice);

  const [checkedValues, setCheckedValues] = useState<OfflineCheckedValuesModel>(
    {
      carDataCheck: true,
      dateCheck: true,
      timeCheck: true,
      dealerCheck: true,
    }
  );

  if (!citiesList) {
    return null;
  }

  async function onSubmit(values: ContactFormOfflineValuationModel) {
    const isFillValues = checkIsFillOfflineValues(checkedValues);

    if (!isFillValues || !dealerId) {
      return;
    }

    const foundCity = citiesList?.find((city) =>
      city.locations.find((location) => location.id === dealerId)
    );

    if (!foundCity) {
      return;
    }

    const foundLocation = foundCity.locations.filter(
      (location) => location.id === dealerId
    )[0];

    if (!foundLocation) {
      return;
    }

    try {
      dispatch(setSellCarLoading(true));

      await submitRateCarOffline({
        address: `г.${foundCity.city.name}, ${foundLocation.address}`,
        brand: offlineCarData.brand,
        model: offlineCarData.model,
        year: Number(offlineCarData.year),
        datetime: offlineCarData.date + ' ' + offlineCarData.time,
        name: values.name,
        phone: values.phone,
        email: values.email,
      }).then(() => {
        if (pageFields.offlineGoogleEvent) {
          googleEvent(pageFields.offlineGoogleEvent);
        }
      });

      setIsDataSanded(true);
      dispatch(setSellCarLoading(false));
      dispatch(setOfflineUserChoice(getClearedOfflineData()));

      scrollTop();
    } catch (error: any) {
      console.log(error);
      toast.error(error?.message);
      dispatch(setSellCarLoading(false));
    }
  }

  const citiesListFormatted = citiesList
    ? citiesList.map((item) => {
        return {
          id: item.city.id,
          name: item.city.name,
          items: item.locations.map((item) => {
            return {
              id: item.id,
              name: item.address,
              workTime: item.workTime,
              phone: null,
              image: null,
              latitude: item.latitude,
              longitude: item.longitude,
            };
          }),
        };
      })
    : [];

  return (
    <Component>
      {isDataSanded ? (
        <RateCarSuccessPage
          title={pageFields.offlineSuccessTitle}
          text={pageFields.offlineSuccessText}
        />
      ) : (
        <>
          <Container>
            <FormPageHeader
              title={pageFields.offlineTitle || ''}
              formRef={formRef}
              isChecked={checkedValues.carDataCheck}
              switchTitle={pageFields.offlineSwitchText || ''}
              switchLabel={pageFields.offlineSwitchButton || ''}
              onSwitch={() => dispatch(setSellCarView('ONLINE'))}
            />
          </Container>

          <MapContainer>
            <LocationsMap
              data={citiesListFormatted}
              selectedId={dealerId}
              onSelect={setDealerId}
              isForm={true}
            />
          </MapContainer>

          <Container>
            <Formik<ContactFormOfflineValuationModel>
              initialValues={{ name: '', phone: '', email: '' }}
              onSubmit={onSubmit}
              validate={handleValidateContactForm}
              validateOnChange
            >
              {({
                handleSubmit,
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
              }) => {
                const submitDisabled =
                  !values.name ||
                  Boolean(errors.phone) ||
                  Boolean(errors.email) ||
                  !dealerId ||
                  !offlineCarData.date ||
                  !offlineCarData.time;

                return (
                  <form onSubmit={handleSubmit}>
                    <DateAndTime
                      dateRef={dateRef}
                      timeRef={timeRef}
                      setCheckedValues={setCheckedValues}
                      checkedValues={checkedValues}
                    />
                    <ContactForm
                      values={values}
                      errors={errors}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                    />
                    <Button type="submit" disabled={submitDisabled}>
                      Отправить
                    </Button>
                  </form>
                );
              }}
            </Formik>
          </Container>
        </>
      )}
    </Component>
  );
}

export default OfflineValuation;

const Component = styled.div`
  margin-bottom: 80px;
`;

const Button = styled(ButtonPrimary)`
  padding: 10px 0;
  width: 384px;
  margin-top: 26px;
  font-size: 16px;
  border-radius: 8px;

  &[disabled] {
    opacity: 0.4;
  }

  ${media.mobile(css`
    width: 100%;
    margin-top: 32px;
  `)}
`;

const Container = styled.div`
  position: relative;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 768px) {
    padding: 0 20px;
    margin-top: 40px;
  }
`;

const MapContainer = styled.div`
  margin-top: 30px;
`;
