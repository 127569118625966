import React from 'react';
import styled from 'styled-components';

import { Nullable } from '@tager/web-core';

import CheckedIcon from '@/assets/svg/checked_icon-sell-car.svg';

interface Props {
  label: Nullable<string>;
}

function AdvantageItem({ label }: Props) {
  return (
    <Content>
      <IconContainer>
        <CheckedIcon />
      </IconContainer>
      <Text>{label ? label : ''}</Text>
    </Content>
  );
}

export default AdvantageItem;

const Content = styled.div`
  margin-bottom: 100px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 24px;
    }
  }
`;

const IconContainer = styled.div`
  margin-bottom: 11px;

  svg {
    width: 50px;
    height: 50px;

    polygon {
      fill: ${(props) => props.theme.black};
    }
  }
`;

const Text = styled.span`
  display: block;
  max-width: 280px;
  color: ${(props) => props.theme.black};
`;
